/**
 * @author Geider Arevalo
 * @version v0.0.1
 * @date  25/01/2022
 */
import React, {memo, useMemo} from 'react';
import PropTypes from 'prop-types';
import styles from './index.less';
import { Card, Row, Col} from 'antd';
import ViewEditor from '@/components/Editor/viewEditor';
import Incidents from '../../Incidents';
import BarChart from '@/modules/audits/components/WrapperRunAudit/components/ManageAuditContent/components/WrapperFieldType/components/Evolution/components/BarChart';
import BarChartScoring from '@/modules/audits/components/WrapperRunAudit/components/ManageAuditContent/components/WrapperFieldType/components/Evolution/components/BarChartScoring';
import {useTranslation} from 'react-i18next';
import BarChartIssue from '@/modules/audits/components/WrapperRunAudit/components/ManageAuditContent/components/WrapperFieldType/components/Evolution/components/BarChartIssue';
import BarChartScoringIssue from '@/modules/audits/components/WrapperRunAudit/components/ManageAuditContent/components/WrapperFieldType/components/Evolution/components/BarChartScoringIssue';
import {useUser} from '@dofleini/security';

const Evolution = ({question, entity}) => {
  const {t} = useTranslation('templates');
  const data = useMemo(() => entity?.coverFields?.find(f => f?._id === question?._id), [entity?.coverFields, question?._id]);
  const { user } = useUser();

  return (
    <Card
      className={styles.templateTextCard}
      bodyStyle={{padding: '6px'}}
      id={'question-view-' + question?._id}
    >
      <div className="mb-3">
        {t(data?.label) || question?.name || question?.label}
      </div>
      <div className="custom-form-big">
        {!data?.value ? 'N/A' : <ViewEditor value={data?.value} />}
      </div>
      {question?.issuesAudit?.length > 0 && <Incidents data={question?.issuesAudit}/>}

      <Row className="w-full">
        <Col xl={18} md={18} sm={24} xs={24} className="p-3">
          {user?.reportType === 'issues' ? <BarChartIssue/> : <BarChart/>}
        </Col>
        <Col xl={6} md={6} sm={24} xs={24} className="p-3">
          {user?.reportType === 'issues' ? <BarChartScoringIssue/> : <BarChartScoring/>}
        </Col>
      </Row>
    </Card>
  );
};

Evolution.propTypes = {
  question: PropTypes.object,
  entity: PropTypes.object,
};

Evolution.defaultProps = {
  question: {},
};

export default memo(Evolution);
