import React from 'react';
import Email from '@/components/Email/index';
import {Avatar} from 'antd';

/**
 * This is not a components,
 * it is a function to render the Email components in a table cell
 * */
export const renderEmail = (email, item) => <Email item={item}>{email}</Email>;


/**
 * This is not a components,
 * it is a function to render the Avatar components in a table cell
 * */
export const renderAvatar = (avatar) => <Avatar src={avatar}/>;

/**
 * This is not a components,
 * it is a function to render the Squared Avatar components in a table cell
 * */
export const renderSquareAvatar = (avatar) => <Avatar shape="square" src={avatar}/>;
