import React, {memo} from 'react';
import ContentEditable from 'react-contenteditable';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import useContentEditable from '@/hooks/useContentEditable';
import styles from './index.less';

const ContentEditableComponent = ({id, initValue, onSave, placeholder, className, isLoading}) => {

  const {handleKeyDown, handleChange, handleClick, handleEdit, value, isEditing} = useContentEditable({
    initValue,
    id: `item-editable-${id}`,
    onSave
  });

  return <ContentEditable
    disabled={!isEditing || isLoading}
    id={`item-editable-${id}`}
    onKeyDown={handleKeyDown}
    className={classnames(styles.contentEditable, 'cursor-pointer', !isEditing ? 'text-gray-300' : 'text-black', className)}
    html={value || ''}
    onBlur={handleEdit}
    onChange={handleChange}
    placeholder={placeholder}
    onClick={handleClick}
  />;
};

ContentEditableComponent.propTypes = {
  id: PropTypes.string,
  initValue: PropTypes.string,
  onSave: PropTypes.func,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
};

ContentEditableComponent.defaultProps = {
  placeholder: 'Enter text'
};

export default memo(ContentEditableComponent);
