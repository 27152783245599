import React from 'react';
import ReactDOM from 'react-dom';
import '@/assets/tailwindcss/styles.css';
import '@/assets/less/index.less';
import App from './App';
import * as serviceWorker from './serviceWorker';
import MainProvider from '@/contexts/MainProvider';
import * as dotenv from 'dotenv';
import {RegisterRegisteredProvider} from '@/modules/clientRegisters/hooks/useRegisterRegistered';

dotenv.config();

ReactDOM.render(
  <MainProvider>
    <RegisterRegisteredProvider>
      <App/>
    </RegisterRegisteredProvider>
  </MainProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
