/**
 * @author Geider Arevalo
 * @since v0.0.1
 * @date 31/01/22
 */
import React, {memo, useMemo} from 'react';
import PropTypes from 'prop-types';
import {Checkbox, Col, Row} from 'antd';
import styles from '../../TemplateDetailsFooterActions/index.less';
import ContentEditableComponent from '@/components/ContentEditable';
// import TaskPriority from '@/modules/templates/components/IncidenceDetailTasks/TaskPriority';
import classNames from 'classnames';

const Tasks = ({key, item, issueData, issuesAudit, disabled, checked, onChange, onSaveTaskName, isLoading}) => {
  const dataTask = useMemo(() => {
    const issue = issuesAudit?.find(el => el?.templateIssue === issueData?._id);
    return issue?.tasksAudit?.find(el => el.templateTask === item?._id) || item;
  }, [issuesAudit, item, issueData?._id]);

  return (
    <div key={key} className={classNames(styles.previewTasksRowTasks, 'task-row flex')}>
      <span className={'flex flex-col justify-center px-0 w-full'}>
        <Col span={24} className={'flex flex-col'} >
          <Row className={styles.detailTasksItem} gutter={16}>
            <Col span={24} className="flex m-auto items-center justify-start">
              <Checkbox
                disabled={disabled || isLoading}
                checked={checked}
                onChange={onChange}
                className="p-2"
              />
              <ContentEditableComponent
                id={item?._id}
                initValue={dataTask?.name}
                onSave={(value) => onSaveTaskName(dataTask, value?.trim())}
                isLoading={isLoading}
              />
            </Col>

            {/*<Col span={6} className={'flex flex-col justify-center items-end'}>*/}
            {/*  <span className={'font-medium flex-col justify-center p-2'}>*/}
            {/*    <TaskPriority value={item?.priority}/>*/}
            {/*  </span>*/}
            {/*</Col>*/}
          </Row>
        </Col>
      </span>
    </div>
  );
};

Tasks.propTypes = {
  key: PropTypes.string,
  item: PropTypes.object,
  issueData: PropTypes.object,
  issuesAudit: PropTypes.array,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  onSaveTaskName: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default memo(Tasks);
