/**
 * @author Geider Arevalo
 * @version v0.0.1
 * @date  15/11/2021
 */
import React, {memo} from 'react';
import PropTypes from 'prop-types';
import styles from './index.less';
import {useTranslation} from 'react-i18next';
import { Card } from 'antd';
import {useGetOneUser} from '@/modules/admin/hooks/useUser';
import {getUserFullName} from '@/utils/userFullName';
import {useAuditData} from '@/modules/auditorAudits/hooks/useAuditData';

const Auditor = ({question}) => {
  const {t} = useTranslation('templates');
  const {currentAudit} = useAuditData();
  const {data} = useGetOneUser({id: currentAudit?.auditor});

  return (
    <Card
      className={styles.templateAuditorCard}
    >
      <Card.Meta title={t('Auditor')} description={question?.value ? question?.value : getUserFullName(data?.data)}/>
    </Card>
  );
};

Auditor.propTypes = {
  entity: PropTypes.object,
  question: PropTypes.object,
};
Auditor.defaultProps = {
  entity: {},
};

export default memo(Auditor);


/**
 * cellRender
 * */
/**
 * This is not a components,
 * it is a function to render the Detail Audit Card components in a detail drawer
 * @param {String} value this is the value of the dataIndex of the table (we will ignored in this case)
 * @param {Object} entity this is the row instance
 * */
export const renderTemplateDetailAuditor = (value, entity) => {
  return (
    <Auditor entity={entity}/>
  );
};
