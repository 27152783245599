/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 23/9/21
 */
import React, {memo, useEffect, useCallback, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {Button} from 'antd';
import {useTableGrid} from '@/contexts/tableGridContact';
import classNames from 'classnames';
import {InfoCircleFilled} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';
import map from 'lodash/map';
import filter from 'lodash/filter';
import List from './List';
import style from './index.less';
import {getloadingArray} from '@/utils/loadingData';
import Preloader from '@/components/Preloader';
import {useInfinite} from '@/utils/createInfiniteHook';
import {useFilter} from '@/contexts/FilterContext';
import { useInfiniteScroll } from '@dofleini/use-scroll';

const LOADING_DATA = getloadingArray(10);
const SERVER_PAGE = 25;

const DirectoriesData = ({
  canSelect,
  selectedActions,
  onMountData,
  rowKey,
  showLatest,
  notForceMinHeight,
  countLatest,
  translation,
  mapResponse,
  pageConfig,
  service,
  module,
  ...props
}) => {
  const {search, id, filter: filters} = useFilter();

  const {
    data,
    // total,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isLoading,
    isSuccess,
    onLoadCompleted
  } = useInfinite({
    key: [module, search, id, filters],
    service,
    search,
    filters,
    queryConfig: { size: Math.max(pageConfig?.size, SERVER_PAGE) },
  });

  useInfiniteScroll({
    fromWindow: true,
    hasNext: hasNextPage && !(isFetching || isLoading),
    onLoadMore: fetchNextPage
  });

  useEffect(() => {
    if (isSuccess)
      onLoadCompleted && onLoadCompleted(data);
  }, [data, isSuccess, onLoadCompleted]);

  const [selectedRowsData, setSelectedRows] = useState([]);
  const {t} = useTranslation('table');

  const syncSelectedData = useCallback(() => {
    const validIds = map(data, ({_id}) => _id);

    const [selectedIds, selectedRows] = selectedRowsData;

    setSelectedRows([
      filter(selectedIds, _id => validIds.includes(_id)),
      filter(selectedRows, ({_id}) => validIds.includes(_id)),
    ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    onMountData(data);
    syncSelectedData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {visibleColumns, size} = useTableGrid();

  /**
     * @param {Array} list of selected rows key
     * @param {Array} list of selected full entity items
     * */
  const onChangeRowsSelected = useCallback((...args) => setSelectedRows(args), []);

  const selectedRowKeys = useMemo(() => selectedRowsData[0] || [], [selectedRowsData]);
  const selectedRows = useMemo(() => selectedRowsData[1] || [], [selectedRowsData]);

  const rowSelection = useMemo(() => ({
    selectedRowKeys,
    onChange: onChangeRowsSelected
  }), [onChangeRowsSelected, selectedRowKeys]);

  return (
    <div>
      {
        !!selectedRowKeys.length &&
            <div className={classNames(['hidden md:flex items-center justify-between mb-3', style.selectionActions])}>
              <div className={'selected'}>
                <InfoCircleFilled/>
                {selectedRowKeys.length}
                <span className={'lg:hidden'}>{t('selectedElementsXs')}</span>
                <span className={'hidden lg:inline-block'}>{t('selectedElements')}</span>
              </div>
              <div className={'actions flex'}>
                <Button type="text" onClick={() => setSelectedRows([])}>{t('deselect')}</Button>
                {selectedActions({selectedRows, selectedRowKeys})}
              </div>
            </div>
      }
      <div
        id="scrollableDiv"
        style={{
          minHeight: showLatest || notForceMinHeight ? 0 : 500,
        }}
      >
        <List
          {...props}
          size={size}
          translation={translation}
          rowSelection={canSelect ? rowSelection : false}
          columns={visibleColumns}
          rowKey={rowKey}
          dataSource={!data && isFetching ? LOADING_DATA : showLatest ? data?.slice(0, countLatest || 8): mapResponse(data)}
          showLatest={showLatest}
          notForceMinHeight={notForceMinHeight}
          isLoadingOrPending={!data || isLoading}
        />
        {!showLatest && (isFetching || isLoading) && <div className={style.wrapperLoader}><Preloader bg="#fff" /></div>}
      </div>
    </div>
  );
};

DirectoriesData.propTypes = {
  tableHook: PropTypes.func,
  canSelect: PropTypes.bool,
  selectedActions: PropTypes.func,
  onMountData: PropTypes.func,
  rowKey: PropTypes.string,
  showLatest: PropTypes.bool,
  notForceMinHeight: PropTypes.bool,
  countLatest: PropTypes.number,
  translation: PropTypes.string,
  mapResponse: PropTypes.func,
  pageConfig: PropTypes.object,
  module: PropTypes.string,
  service: PropTypes.any,
  search: PropTypes.string,
  filters: PropTypes.object,
};

DirectoriesData.defaultProps = {
  mapResponse: v => v,
  onMountData: v => v,
  rowKey: '_id'
};

export default memo(DirectoriesData);
