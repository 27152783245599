import {useCallback, useRef, useState} from 'react';

const useContentEditable = ({initValue, id, onSave, validators}) => {
  const [isEditing, setIsEditing] = useState(false);
  const text = useRef(initValue);
  const [errorMsg, setErrorMsg] = useState();

  const handleClick = useCallback(() => {
    setIsEditing(true);
    setTimeout(() => {
      document.getElementById(id).focus();
    }, 100);
  }, [id]);

  const handleEdit = useCallback(() => {
    const value = text.current.replace('&nbsp;', '\u00a0');
    if(value !== initValue){
      let isInvalid = false;
      if(Array.isArray(validators)){
        isInvalid = validators.some(validationFunc => {
          const msg = validationFunc(value);
          if(msg){
            setErrorMsg(msg);
            return true;
          }
        });
      }
      if(!isInvalid){
        onSave(value);
        setErrorMsg('');
      }
    }
    setIsEditing(false);
  }, [initValue, onSave, validators]);

  const handleChange = useCallback(evt => {
    setErrorMsg('');
    text.current = evt.currentTarget.textContent;
  }, []);

  const handleKeyDown = useCallback(({key}) => {
    if (key === 'Enter') {
      handleEdit();
    }
  }, [handleEdit]);

  return {isEditing, handleKeyDown, handleEdit, handleChange, handleClick, value: text?.current || '', errorMsg};
};

export default useContentEditable;
