export const mappedAuditData = (auditData) => {
  return auditData?.reduce((acc, curr) => {

    // transform question indexes
    const questions = curr?.questions?.reduce((accQ, currQ) => {

      // transform issues indexes
      const issues = currQ?.issues?.reduce((accI, currI) => {

        // transform tasks indexes
        const tasks = currI?.tasks?.reduce((accT, currT) => {
          accT[currT._id] = currT; // Task indexed
          return accT;
        }, []);

        // transform evidences indexes
        const evidences = currI?.evidences?.reduce((accE, currE) => {
          accE[currE._id] = currE; // Evidence indexed
          return accE;
        }, []);

        accI[currI._id] = {...currI, tasks: tasks || [], evidences: evidences || []}; // Incident indexed
        return accI;
      }, []);

      accQ[currQ._id] = {...currQ, issues}; // Question indexed
      return accQ;
    }, []);

    acc[curr._id] = {...curr, questions: questions || []};
    return acc; // Section indexed
  }, []);
};

export const mappedAuditDataIssue = (dataSections, sectionId, questionId, issueId, issueData) => {
  return dataSections?.map(s => {
    if (sectionId === s?._id) {
      return {...s, questions: s?.questions?.map(q => {
        if (q?._id === questionId) {
          return {...q, issues: q?.issues?.map(i => {
            if (i?._id === issueId) return {...i, issueData};
            return i;
          })};
        }
        return q;
      })};
    }
    return {...s};
  })?.filter(x => x);
};
