/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 21/3/22
 */
import React, {memo, useState, useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Avatar, Collapse, Empty, Row, Col, Checkbox} from 'antd';
import classNames from 'classnames';
import styles from '../TemplateDetailsFooterActions/index.less';
import incidentStyles from '../WrapperManageTemplateSections/components/ManageTemplateSections/components/QuestionOptions/AddIncidentOrIncidentList/index.less';
import FlagLineIcon from 'remixicon-react/FlagLineIcon';
import Paragraph from 'antd/es/typography/Paragraph';
import {useTranslation} from 'react-i18next';
import PicturesWall from '@/modules/templates/components/TemplateDetailsPicturesWall';

const { Panel } = Collapse;

const CheckIssuesFromRunAudit = ({item}) => {

  const {t} = useTranslation(('templates'));

  const [fileList, setFileList] = useState([]);
  const [issuesChecked, setIssuesChecked] = useState([]);
  const [activeKey, setActiveKey] = useState([]);

  const onChangeIssues = useCallback((evt, id) => {
    evt.stopPropagation();
    let checked = evt?.target?.checked;

    if (checked) setActiveKey([item._id]);
    else setActiveKey([]);

    if (issuesChecked?.some(x => x?.issue === id)) setIssuesChecked(issuesChecked?.filter(x => x?.issue !== id));
    else {
      let tasks = [];
      if (item?._id === id && item?.tasks?.length === 1) {
        tasks = [item?.tasks?.[0]?._id];
      }
      setIssuesChecked([...issuesChecked, {issue: id, tasks}]);
    }
  }, [issuesChecked, item?._id, item?.tasks]);

  const onChange = useCallback((issueId, taskId) => {
    const update = [...issuesChecked];
    update?.map((issue) => {
      if (issue?.tasks?.some(x => x === taskId)) {
        issue.tasks = issue.tasks?.filter(x => x !== taskId) || [];
      } else {
        issue.tasks = [...issue.tasks, taskId];
      }
      return issue;
    });

    setIssuesChecked(update);
  }, [issuesChecked]);

  useEffect(() => {
    if (item?.tasks?.length === 1 && issuesChecked?.some(i => i?.issue === item?._id)) {
      setIssuesChecked([{issue: item?._id, tasks: [item?.tasks?.[0]?._id]}]);
    }
    // eslint-disable-next-line
    }, []);

  const isTaskChecked = useCallback((issueId, taskId) => {
    const findParentTasks = issuesChecked?.find(x => x?.issue === issueId);
    return findParentTasks?.tasks?.some(t => t === taskId) || false;
  }, [issuesChecked]);

  return (
    <Collapse
      bordered={true}
      key={item._id}
      forceRender
      className={styles.headerCollapse}
      activeKey={activeKey}
      onChange={setActiveKey}
    >
      <Panel header={
        <div className="h-full w-full flex items-center justify-start min-h-6">
          <Checkbox
            checked={issuesChecked?.some(x => x?.issue === item?._id) ? 'checked' : ''}
            onChange={(e) => onChangeIssues(e, item?._id)}  className="mr-3" />
          <Avatar icon={<FlagLineIcon size={17} color="#707070" />} />
          <div className="ml-2">{item.name}</div>
        </div>
      }
      key={item._id}
      >
        <div className={classNames('w-full h-full', item?.deductionPercentage ? 'mb-2' : 'mb-4')}>
          <Paragraph className={incidentStyles.detailTemplateParagraph}>
            {item.description || t('noDescription')}
          </Paragraph>
        </div>

        {item?.deductionPercentage && <div className="w-full flex items-center justify-start mb-4">
          <div className="font-bold">{t('deductionPercentage')}:</div>
          <div className="ml-2">{item?.deductionPercentage}%</div>
        </div>}

        {item?.tasks?.length > 0 ? item?.tasks?.map((task, index) => (
          <div key={index} className={classNames(styles.previewTasksRowTasks, 'task-row flex')}>
            <span className={'flex flex-col justify-center px-0 w-full'}>
              <Col span={24} className={'flex flex-col'} >
                <Row className={styles.detailTasksItem} gutter={16}>
                  <Col span={18} className={'flex flex-col justify-center'}>
                    <Checkbox
                      disabled={!issuesChecked?.some(x => x?.issue === item?._id)}
                      checked={isTaskChecked(item?._id, task?._id)}
                      onChange={() => onChange(item?._id, task?._id)} className="w-full p-2">
                      <div className={'font-medium text-black w-full'}>
                        {task?.name}
                      </div>
                    </Checkbox>
                  </Col>

                </Row>
              </Col>
            </span>
          </div>
        )) :
          <div className="flex w-full h-full items-center justify-center">
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
          </div>
        }

        <div className="mt-6">
          <PicturesWall translation={'templates'} fileList={fileList} setFileList={setFileList}/>
        </div>
      </Panel>
    </Collapse>
  );
};

CheckIssuesFromRunAudit.propTypes = {
  item: PropTypes.object,
  i: PropTypes.number,
  handleOnClickTask: PropTypes.func,
  selectedTask: PropTypes.number,
};

export default memo(CheckIssuesFromRunAudit);
