/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 22/11/21
 */
import React, {memo, useState} from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'antd';
import SectionTitle from '@/modules/templates/components/TemplateSectionsDrawer/components/SectionTitle';
import WrapperFieldType from '@/modules/templates/components/TemplateSectionsDrawer/components/WrapperFieldType';
import {useTranslation} from 'react-i18next';
import styles from './index.less';

const { Panel } = Collapse;

const SectionBox = ({section, id, runAudit = false, isCover}) => {
  const {t} = useTranslation('templates');

  const [activeKey, setActiveKey] = useState(['1']);

  if (runAudit) {
    return (
      <div id={id} className="wrapper-section-panel">
        {isCover
          ? <Collapse defaultActiveKey={activeKey} bordered={false} onChange={setActiveKey} className={styles.wrapperPanel}>
            <Panel className="wrapper-panel-audit" header={<SectionTitle section={section} runAudit={runAudit} activeKey={activeKey} isCover={isCover} />} key="1" showArrow={false}>
              {section?.metadata?.coverFields?.length === 0
                ?
                <div className="w-full flex items-center justify-center min-h-8">{t('noQuestionsForThisSection')}</div>
                : section?.metadata?.coverFields?.map(item => {
                  return <WrapperFieldType key={item?._id} type={item?.type} question={item} isCover runAudit={runAudit} section={section} />;
                })}
            </Panel>
          </Collapse>

          : <Collapse defaultActiveKey={activeKey} bordered={false} onChange={setActiveKey} className={styles.wrapperPanel}>
            <Panel className="wrapper-panel-audit" header={<SectionTitle section={section} runAudit={runAudit} activeKey={activeKey} />} key="1" showArrow={false}>
              {section?.questions?.length === 0 && !section?.metadata
                ? <div className="w-full flex items-center justify-center min-h-8">{t('noQuestionsForThisSection')}</div>
                : section?.questions?.map(item => {
                  return <WrapperFieldType key={item?._id} type={item?.type} question={item} sectionId={section?._id} runAudit={runAudit} section={section}/>;
                })}
            </Panel>
          </Collapse>}
      </div>
    );
  }

  return (
    <div id={id}>
      <SectionTitle section={section} />
      {section?.metadata?.coverFields?.length === 0
        ? <div className="w-full flex items-center justify-center min-h-8">{t('noQuestionsForThisSection')}</div>
        : section?.metadata?.coverFields?.map(item => {
          return <WrapperFieldType key={item?._id} type={item?.type} question={item} isCover section={section} />;
        })}
      {section?.questions?.length === 0 && !section?.metadata
        ? <div className="w-full flex items-center justify-center min-h-8">{t('noQuestionsForThisSection')}</div>
        : section?.questions?.map(item => {
          return <WrapperFieldType key={item?._id} type={item?.type} question={item} sectionId={section?._id} section={section}/>;
        })}
    </div>
  );
};

SectionBox.propTypes = {
  section: PropTypes.object,
  id: PropTypes.string,
  runAudit: PropTypes.bool,
  isCover: PropTypes.bool,
};

export default memo(SectionBox);
