/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 28/11/21
 */
import {useMutation, useQueryClient} from 'react-query';
import useAlerts from '@/hooks/useAlerts';
import SectionsApiService from '@/modules/templates/services/SectionsApiService';
import {CACHE_KEY_LIST_SECTIONS} from '@/modules/templates/contants/sections';
import {useAuditData} from '@/modules/auditorAudits/hooks/useAuditData';
import {useEffect} from 'react';
import {CACHE_KEY_LIST_AUDITOR_AUDITS_SECTIONS} from '@/modules/auditorAudits/contants/auditorAudits';
import {useParams} from 'react-router-dom';

export const useCrudSections = () => {
  const queryClient = useQueryClient();
  const {id} = useParams();
  const {putErrorCode} = useAlerts();

  const {mutateAsync: create, isLoading: isCreating} = useMutation(SectionsApiService.save,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(CACHE_KEY_LIST_SECTIONS);
        queryClient.invalidateQueries([CACHE_KEY_LIST_AUDITOR_AUDITS_SECTIONS, id]);
      },
      onError: (e) => {
        putErrorCode(e);
      }
    });

  const {mutateAsync: update, isLoading: isUpdating} = useMutation(SectionsApiService.update,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(CACHE_KEY_LIST_SECTIONS);
        queryClient.invalidateQueries([CACHE_KEY_LIST_AUDITOR_AUDITS_SECTIONS, id]);
      },
      onError: (e) => {
        putErrorCode(e);
      }
    });

  const {mutateAsync: remove, isLoading: isRemoving} = useMutation(SectionsApiService.delete,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(CACHE_KEY_LIST_SECTIONS);
        queryClient.invalidateQueries([CACHE_KEY_LIST_AUDITOR_AUDITS_SECTIONS, id]);
      },
      throwOnError: true
    }
  );

  const {mutateAsync: changeOrder, isLoading: isChanging} = useMutation(SectionsApiService.changeSectionOrder,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(CACHE_KEY_LIST_SECTIONS);
      },
      onError: (e) => {
        putErrorCode(e);
      }
    }
  );

  return {
    create,
    isCreating,
    update,
    isUpdating,
    remove,
    isRemoving,
    changeOrder,
    isChanging,
    isLoading: isCreating || isUpdating || isRemoving || isChanging
  };
};

export const useCrudQuestions = (sectionId, questionId) => {
  const queryClient = useQueryClient();
  const {/*dataSections, setDataSections,*/ setSaving} = useAuditData();
  const {putErrorCode} = useAlerts();
  const {id} = useParams();

  const {mutateAsync: create, isLoading: isCreating} = useMutation(SectionsApiService.addQuestion,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([CACHE_KEY_LIST_SECTIONS, sectionId, questionId]);
        queryClient.invalidateQueries([CACHE_KEY_LIST_AUDITOR_AUDITS_SECTIONS, id]);
      },
      onError: (e) => {
        putErrorCode(e);
      }
    });

  const {mutateAsync: update, isLoading: isUpdating} = useMutation(SectionsApiService.updateQuestion,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([CACHE_KEY_LIST_AUDITOR_AUDITS_SECTIONS, id]);
        // setDataSections(transformBeforeSend({sectionId, questionId, dataSections, dataQuestion: data}));
      },
      onError: (e) => {
        putErrorCode(e);
      }
    });

  const {mutateAsync: remove, isLoading: isRemoving} = useMutation(SectionsApiService.deleteQuestion,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([CACHE_KEY_LIST_SECTIONS, sectionId, questionId]);
        queryClient.invalidateQueries([CACHE_KEY_LIST_AUDITOR_AUDITS_SECTIONS, id]);
      },
      throwOnError: true
    }
  );

  const {mutateAsync: changeOrder, isLoading: isChanging} = useMutation(SectionsApiService.changeQuestionOrder,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([CACHE_KEY_LIST_SECTIONS, sectionId, questionId]);
      },
      onError: (e) => {
        putErrorCode(e);
      }
    }
  );

  useEffect(() => setSaving(isCreating || isUpdating || isRemoving || isChanging), [isChanging, isCreating, isRemoving, isUpdating, setSaving]);

  return {
    create,
    isCreating,
    update,
    isUpdating,
    remove,
    isRemoving,
    changeOrder,
    isChanging,
    isLoading: isCreating || isUpdating || isRemoving || isChanging
  };
};

export const useCrudCoverFields = () => {
  const queryClient = useQueryClient();

  const {putErrorCode} = useAlerts();

  const {mutateAsync: create, isLoading: isCreating} = useMutation(SectionsApiService.addField,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(CACHE_KEY_LIST_SECTIONS);
      },
      onError: (e) => {
        putErrorCode(e);
      }
    });

  const {mutateAsync: update, isLoading: isUpdating} = useMutation(SectionsApiService.updateField,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(CACHE_KEY_LIST_SECTIONS);
      },
      onError: (e) => {
        putErrorCode(e);
      }
    });

  const {mutateAsync: remove, isLoading: isRemoving} = useMutation(SectionsApiService.deleteField,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(CACHE_KEY_LIST_SECTIONS);
      },
      throwOnError: true
    }
  );

  const {mutateAsync: changeOrder, isLoading: isChanging} = useMutation(SectionsApiService.changeFieldOrder,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(CACHE_KEY_LIST_SECTIONS);
      },
      onError: (e) => {
        putErrorCode(e);
      }
    }
  );

  return {
    create,
    isCreating,
    update,
    isUpdating,
    remove,
    isRemoving,
    changeOrder,
    isChanging,
    isLoading: isCreating || isUpdating || isRemoving || isChanging
  };
};
