import {ApiClientService, EntityApiService} from '@dofleini/security';

class TasksApiService extends EntityApiService {
    searchTasksClients = (params) => {
      return ApiClientService.post(this.getPath('/search-tasks-clients'), params);
    }
    searchTasksServices = (params) => {
      return ApiClientService.post(this.getPath('/search-tasks-services'), params);
    }
    searchTasksIssues = (params) => {
      return ApiClientService.post(this.getPath('/search-tasks-issues'), params);
    }

    dashboardSearch = (params) => {
      return ApiClientService.post(this.getPath('/search-tasks-dashboard'), params);
    }
}

export default new TasksApiService('/ms-core/api/tasks');
