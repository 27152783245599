/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 23/2/22
 */
import React, {memo, useMemo} from 'react';
import {useAuditData} from '@/modules/auditorAudits/hooks/useAuditData';
import { Column } from '@ant-design/plots';
import { each, groupBy } from '@antv/util';
import {useTranslation} from 'react-i18next';
import styles from './index.less';

const BarChartScoring = () => {

  const {currentAudit} = useAuditData();
  const {t} = useTranslation('audits');

  const data = useMemo(() => {
    return [{
      type: t('finalScore'),
      value: (currentAudit?.finalScore * 100 / currentAudit?.totalScore  || 0) - (currentAudit?.audit?.deductions?.[0]?.percentage || 0)
    },
    {
      type: t('valueGoal'),
      value: currentAudit?.valueGoal
    }];
  }, [currentAudit?.audit?.deductions, currentAudit?.finalScore, currentAudit?.totalScore, currentAudit?.valueGoal, t]);

  const paletteSemanticRed = '#454B50';
  const brandColor = '#4db7f9';
  const annotations = [];
  each(groupBy(data, 'type'), (values, k) => {
    const value = values.reduce((a, b) => a + b.value < 100 ? Number(a + b.value).toFixed(1) : a + b.value, 0);
    annotations.push({
      type: 'text',
      position: [k, value],
      content: `${value}%`,
      style: {
        textAlign: 'center',
        fontSize: 12,
        // fill: 'rgba(0,0,0,0.85)',
      },
      offsetY: -10,
    });
  });
  const config = {
    data,
    xField: 'type',
    yField: 'value',
    seriesField: '',
    padding: [20, 0],
    maxBarWidth: 5,
    tooltip: {
      formatter: (datum) => {
        return { name: t('scoring'), value: Number(datum.value).toFixed(1) + '%' };
      },
    },
    color: ({ type }) => {
      if (type === t('finalScore')) {
        return paletteSemanticRed;
      }

      return brandColor;
    },
    label: {
      content: (originData) => {
        const val = parseFloat(originData.value);

        if (val < 0.05) {
          return (val * 100).toFixed(1) + '%';
        }
      },
      offset: 10,
    },
    annotations,
  };

  return (
    <div className={styles.wrapperChart}>
      <Column {...config} />
    </div>
  );
};

BarChartScoring.propTypes = {};

export default memo(BarChartScoring);
