import React, {memo, useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {useTranslation} from 'react-i18next';
import {PlusOutlined, DeleteOutlined} from '@ant-design/icons';
import {List, Input, Card, Row, Col} from 'antd';
import styles from './index.less';
import UploadImage from './UploadImage';
// import {useCrudQuestions} from '@/modules/templates/hooks/useSections';
import {useCrudEvidencesRunAudit} from '@/modules/auditorAudits/hooks/useAuditorAudits';
import {useParams} from 'react-router-dom';
const {TextArea} = Input;

const PicturesWall = ({
  translation,
  runAudit,
  question,
  sectionId,
  incidence,
  setCheckedIssue,
  disabled
}) => {
  const {id} = useParams();
  const {t} = useTranslation(translation);

  const [fileList, setFileList] = useState(runAudit ? question?.issues?.find((el) => el?._id === incidence?._id)?.evidences : []);
  // const {update} = useCrudQuestions(question?._id, sectionId);
  const {create} = useCrudEvidencesRunAudit();

  /** On Submit form */
  const onSubmit = useCallback(async (newEvidences) => {
    const issues = await question?.issues?.map((issue) => {
      if (issue?._id === incidence?._id) {
        return {...issue, evidences: newEvidences};
      }
      return issue;
    });
    setFileList(newEvidences);
    setCheckedIssue(issues);

    if(runAudit) {
      const values = {
        auditId: id,
        sectionId,
        questionId: question?._id,
        issue: incidence?._id,
        evidences: newEvidences
      };
      // await update({questionId: question?._id, sectionId, ...question, issues});
      await create(values);
    }
  }, [create, id, incidence?._id, question?._id, question?.issues, runAudit, sectionId, setCheckedIssue]);

  /** On remove evidence */
  const onRemove = useCallback(async (idx) => {
    const newEvidences = [...fileList];
    newEvidences?.splice(idx, 1);
    await onSubmit(newEvidences);
  },[fileList, onSubmit]);

  const onRemoveImage= useCallback(async (file) => {
    const newEvidences = fileList?.map(el => el?._id === file?._id ? {...file, url: null} : el);
    await onSubmit(newEvidences);
  },[fileList, onSubmit]);

  /** Add new evidence to the array */
  const onAddEvidence = useCallback(async (e) => {
    e.stopPropagation();
    const newEvidences = fileList?.length ?
      [...fileList, { type: 'photo' }] :
      [{ type: 'photo' }];
    setFileList(newEvidences);
  }, [fileList]);

  /** On change observation name */
  const onChangeObservation = useCallback(async (note, file, idx) => {
    const newEvidences = [...fileList];
    newEvidences[idx] = {...file, observation: note};
    setFileList(newEvidences);
  }, [fileList]);

  /** On save observation name */
  const onBlurObservation = useCallback(async (note, file, idx) => {
    const newEvidences = [...fileList];
    newEvidences[idx] = {...file, observation: note};
    await onSubmit(newEvidences);
  }, [fileList, onSubmit]);

  /** On Upload image */
  const onUpload = useCallback(async (data, idx) => {
    const newFileList = fileList;
    newFileList[idx] = {...newFileList[idx], url: data?.image, pdfThumb: data?.pdfThumb};
    await onSubmit(newFileList);
  }, [fileList, onSubmit]);

  return (
    <div className={styles.wrapperPictures}>
      <Card
        className={'mb-2 rounded'}
        bodyStyle={{padding: '6px', background: '#FAFAFA'}}
      >
        <div className="font-bold my-2">{t('details.evidences')}:</div>
        {fileList?.length > 0 && <List itemLayout="vertical"
          size="large"
          pagination={false}
          dataSource={fileList}
          actions={[<div key="1">{'action'}</div>,]}
          renderItem={(file, idx) => (
            <Row key={file?._id} gutter={8}>
              <Col span={22}>
                <Card
                  className={'mb-2 rounded'}
                  bodyStyle={{padding: '6px'}}
                >
                  <Row gutter={8}>
                    <Col flex="auto">
                      <TextArea
                        rows={3}
                        className={'mt-1'}
                        allowClear
                        value={file?.observation}
                        placeholder={`${t('templates.leaveCommentAssociatedWithTheEvidence')}...`}
                        onChange={(e) => onChangeObservation(e?.target?.value, file, idx)}
                        onBlur={(e) => onBlurObservation(e?.target?.value, file)}
                        disabled={disabled}
                      />
                    </Col>
                    <Col flex="130px">
                      <UploadImage
                        value={file}
                        onChange={(data) => onUpload(data, idx)}
                        onRemove={() => onRemoveImage(file)}
                        disabled={disabled}
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col span={2} className={'m-auto'}>
                <DeleteOutlined style={{color: 'red'}} onClick={() => onRemove(idx)}/>
              </Col>
            </Row>
          )}
        />}
      </Card>
      <div
        onClick={(e) => !disabled && onAddEvidence(e)}
        className={classNames(styles.uploadPictureButton, disabled && 'opacity-50 cursor-not-allowed')}
      >
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>{t('addEvidence')}</div>
      </div>
    </div>
  );
};

PicturesWall.propTypes = {
  translation: PropTypes.string,
  runAudit: PropTypes.bool,
  sectionId: PropTypes.string,
  question: PropTypes.object,
  incidence: PropTypes.object,
  setCheckedIssue: PropTypes.func,
  disabled: PropTypes.bool
};

export default memo(PicturesWall);
