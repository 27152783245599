/**
 * @author Ricardo Comendador Escalona
 * @version v0.0.1
 * @date  15/11/2021
 */
import React, {memo, useEffect, useState, useRef, useContext, useMemo, useCallback} from 'react';
import PropTypes from 'prop-types';
import styles from './index.less';
import { Card, Table, Input, Form, Typography, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import TemplateDetailsFooterActions from '@/modules/templates/components/TemplateDetailsFooterActions';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {useCrudRunAudit} from '@/modules/auditorAudits/hooks/useAuditorAudits';
import {useCrudQuestions} from '@/modules/templates/hooks/useSections';
import {useResponsive} from '@/contexts/ResponsiveContext';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const { Text } = Typography;

const EditableContext = React.createContext(null);

const EditableRow = ({...props }) => {
  const [form] = Form.useForm();

  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props}/>
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  // title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  question,
  sectionId,
  runAudit,
  placeholder,
  keyPos,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  const {id} = useParams();
  const {create, isLoading: loading} = useCrudRunAudit();
  const {update, isLoading} = useCrudQuestions();

  const {t} = useTranslation('templates');

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = useCallback(() => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record?.[dataIndex],
    });
  }, [dataIndex, editing, form, record]);

  const initialValues = useMemo(() => {
    if (dataIndex === 'place') {
      return children?.[1] ? children : [children?.[0], t('place')];
    }
    return children;
  }, [children, dataIndex, t]);

  const onUpdatePlace = useCallback(async (place) => {
    const idx = keyPos?.[0];
    const readingRegistry = question?.readingRegistry?.placesMeasure;

    readingRegistry[idx] = {...readingRegistry[idx], place, label: place};
    await update({sectionId, questionId: question?._id,...question, readingRegistry: {...question?.readingRegistry, placesMeasure: readingRegistry}});
  }, [keyPos, question, sectionId, update]);

  const save = useCallback(async(evt) => {
    try {
      const values = await form.validateFields();
      if (runAudit) {
        if (dataIndex === 'place') {
          form.setFieldsValue({
            [dataIndex]: values?.[dataIndex],
          });
          if (evt.target?.value !== record?.[dataIndex]) {
            await onUpdatePlace(values?.[dataIndex]);
            setEditing(!editing);
            handleSave({...record, ...values});
            return;
          }
          setEditing(!editing);
          return;
        }

        const templateAnswer = {
          type: 'readingRegistry',
          readingRegistry: {
            placesMeasure: record?._id,
            optionKey: dataIndex,
            value: values?.[dataIndex]// || record?.[dataIndex]
          }
        };
        if (Number(evt.target?.value) !== Number(record?.[dataIndex])) {
          await create({id, section: sectionId, question: question?._id, templateAnswer});
        }
      }

      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  }, [create, dataIndex, editing, form, handleSave, id, onUpdatePlace, question?._id, record, runAudit, sectionId, toggleEdit]);

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Spin indicator={antIcon} spinning={loading || isLoading}>
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
        >
          <Input
            ref={inputRef}
            type={dataIndex === 'place' ? 'text' : 'number'}
            onPressEnter={save}
            onBlur={save}
            placeholder={placeholder}
            disabled={loading || isLoading}
          />
        </Form.Item>
      </Spin>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
          color: dataIndex === 'place' ? record?.[dataIndex] ? '#000' : '#C9C7C1' : '#000'
        }}
        onClick={toggleEdit}
      >
        {initialValues}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

EditableCell.propTypes = {
  title: PropTypes.string,
  editable: PropTypes.bool,
  children: PropTypes.any,
  dataIndex: PropTypes.string,
  record: PropTypes.object,
  handleSave: PropTypes.func,
  runAudit: PropTypes.bool,
  sectionId: PropTypes.string,
  question: PropTypes.object,
  placeholder: PropTypes.string,
  keyPos: PropTypes.array,
};

// const scroll = { x: 500, y: 250 };

const getKeyToTranslate = (key) => {
  switch (key) {
    case 'noFormat': return 'ruleNone';
    case 'greaterThan': return 'ruleGreaterThan';
    case 'lessThan': return 'ruleLessThan';
    case 'equal': return 'ruleEqual';
    default: return 'ruleBetween';
  }
};

const getMeasure = (value) => {
  switch (value) {
    case 'celsius': return '°C';
    case 'fahrenheit': return '°F';
    case 'others': return 'others';
    case 'chlorine': return 'chlorine';
    case 'co2': return 'co2';
    case 'atp': return 'atp';
    case 'mm': return 'mm';
    case 'cm': return 'cm';
    case 'm': return 'm';
    case 'm2': return 'm2';
    case 'm3': return 'm3';
    case 'ph': return 'Ph';
    case 'ppm': return 'ppm';
      // case 'kelvin': return 'Kelvin';
      // case 'rankine': return 'Rankine';
      // case 'réaumur': return 'Réaumur';
      // case 'rømer': return 'Rømer';
      // case 'delisle': return 'Delisle';
    default: return 'none';
  }
};

const TemplateDetailsInSituMeasure = ({question, sectionId, runAudit}) => {

  const {t} = useTranslation('templates');
  const  {isMobile, isTablet} = useResponsive();

  const cols = useMemo(() =>
    question?.readingRegistry?.options?.map(item =>
      ({...item, title: item?.label, fixed: item?.key === '_id' && !isMobile && !isTablet ? 'left' : null, editable: true, dataIndex: item?.key})
    ) || [],[isMobile, isTablet, question?.readingRegistry?.options]);

  const readingRegistryResponses = useMemo(() => {
    return question?.answer?.templateAnswer?.readingRegistry || [];
  }, [question?.answer?.templateAnswer]);

  const placesMeasure = useMemo(() => {
    return question?.readingRegistry?.placesMeasure || [];
  }, [question?.readingRegistry]);

  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    const populateData = readingRegistryResponses?.length ? readingRegistryResponses?.reduce((result, item) => {
      const placeKey = placesMeasure.find((x) => x._id === item.placesMeasure)?._id;
      const placeObj = result.find((x) => x._id === placeKey);

      if (!placeObj) {
        result.push({
          _id: placeKey,
          [item.optionKey]: item.value,
        });
      } else {
        placeObj[item.optionKey] = item.value;
      }

      return result;
    }, placesMeasure) : placesMeasure;
    setDataSource(populateData);
  }, [placesMeasure, readingRegistryResponses]);

  const handleSave = useCallback((row) => {
    setDataSource(prev => prev);
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    setDataSource(newData?.map(x => x));
  }, [dataSource]);

  const columns = useMemo(() => cols.map((col) => {
    if (col.key === 'place') {
      return {
        ...col,
        dataIndex: col.key,
        fixed: isMobile || isTablet ? null : 'left',
        width: 200,
        onCell: (record, ...props) => {
          return ({
            record,
            editable: true,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave,
            question,
            sectionId,
            runAudit,
            ...col,
            keyPos: props,
            placeholder: t('place')
          });
        },
      };
    }

    return {
      ...col,
      width: 160,
      dataIndex: col.key,
      editable: true,
      title: <div className="w-full">
        <div className="w-full">{col.title}</div>

        <div className="w-full flex items-start justify-center flex-col" style={{color: 'var(--primary-color)'}}>
          <div>
            {t(getMeasure(col?.measure)) || 'N/A'}
          </div>

          <div className="w-full flex items-center justify-start">
            <div className="mr-2">{t(getKeyToTranslate(col?.operation))}</div>
            {col?.operation === 'noFormat'
              ? col?.minValue
              : col?.operation === 'between'
                ? <Text
                  style={{ width: 100, color: 'var(--primary-color)' }}
                  ellipsis={{ tooltip: `${col?.minValue} ${t('and')} ${col?.maxValue}` }}
                >
                  {`(${col?.minValue} ${t('and')} ${col?.maxValue})`}
                </Text>
                : `(${col?.minValue || 0})`}
          </div>
        </div>
      </div>,
      onCell: (record) => {
        return ({
          record,
          editable: true,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave,
          question,
          sectionId,
          runAudit
        });
      },
    };
  }), [cols, handleSave, isMobile, isTablet, question, runAudit, sectionId, t]);

  return (
    <Card
      className={styles.templateTextCard}
      id={'question-view-'+question?._id}
    >
      {/*<div style={{color: 'rgb(235, 0, 39)'}}>*/}
      {/*  {question?.controlParameter ? '*' : null}*/}
      {/*</div>*/}
      <div className="mb-3">
        {question?.name || question?.label}
      </div>
      <Form layout="vertical">
        <Form.Item name="nameInSituMeasure">
          <Table
            components={{
              body: {
                row: EditableRow,
                cell: EditableCell,
              },
            }}
            pagination={false}
            rowClassName={() => 'editable-row'}
            className={styles.inSituMeasureTable}
            dataSource={dataSource}
            columns={columns}
            scroll={{ x: 350, y: 350 }}
          />
        </Form.Item>
      </Form>

      <TemplateDetailsFooterActions question={question} sectionId={sectionId} runAudit={runAudit} />
    </Card>
  );
};

TemplateDetailsInSituMeasure.propTypes = {
  question: PropTypes.object,
  isCover: PropTypes.bool,
  runAudit: PropTypes.bool,
  sectionId: PropTypes.string,
};
TemplateDetailsInSituMeasure.defaultProps = {
  question: {},
};

export default memo(TemplateDetailsInSituMeasure);


/**
 * cellRender
 * */
/**
 * This is not a components,
 * it is a function to render the Detail Audit Card components in a detail drawer
 * @param {String} value this is the value of the dataIndex of the table (we will ignored in this case)
 * @param {Object} entity this is the row instance
 * */
export const renderTemplateDetailsInSituMeasure = (value, entity) => {
  return (
    <TemplateDetailsInSituMeasure entity={entity}/>
  );
};
