import { useMemo } from 'react';
import { ApiClientService } from '@dofleini/security';
import { useQuery } from 'react-query';

const SPACE_PROFILE = '__space_data__';

export const useGetSpace = () => {
  const path = '/ms-core/api/spaces/current';

  const space = useMemo(() => {
    const space = localStorage.getItem(SPACE_PROFILE);
    if (space)
      try {
        return JSON.parse(space);
      } catch (e) {
        // ignoring
      }
  }, []);

  const { isLoading, error, data, refetch, isStale } = useQuery(
    'space-me',
    async () => {
      const { data } = await ApiClientService.get(path);
      localStorage.setItem(SPACE_PROFILE, JSON.stringify(data));
      return data;
    },
    {
      retry: 2, // Will retry failed requests 10 times before displaying an error
      refetchOnWindowFocus: false
    }
  );
  return {
    data: data || space,
    isLoading,
    error,
    refetch,
    isStale
  };
};
