/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 9/12/21
 */
import React, {memo, useState, useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Button, Input, List, Upload} from 'antd';
import {
  FlagOutlined,
  EditOutlined,
  PaperClipOutlined
  // PictureOutlined
} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';
import classNames from 'classnames';
import styles from './index.less';
import incidentStyles from '../WrapperManageTemplateSections/components/ManageTemplateSections/components/QuestionOptions/AddIncidentOrIncidentList/index.less';
import {Scrollbars} from 'react-custom-scrollbars';
import TemplateDetailsIssuesHeader from '@/modules/templates/components/TemplateDetailsIssuesHeader';
import GlobalNote from '@/modules/templates/components/TemplateDetailsFooterActions/components/GlobalNote';
import GlobalImages from '@/modules/templates/components/TemplateDetailsFooterActions/components/GlobalImages';
import useUploadImage from '@/components/UploadImage/useUploadImage';
import { v4 as uuidv4 } from 'uuid';
import {useCrudImage, useCrudNote} from '@/modules/auditorAudits/hooks/useAuditorAudits';
import {useParams} from 'react-router-dom';
import {useShowIncidences} from '@/modules/auditorAudits/hooks/useShowIncidences';

const { TextArea } = Input;

const Item = ({name, icon}) => {

  return (
    <div className={classNames(styles.incidentItem, 'mb-3 flex items-center justify-start')}>
      {icon} <div className="ml-2">{name}</div>
    </div>
  );
};

Item.propTypes = {
  name: PropTypes.string,
  icon: PropTypes.any,
};

const TemplateDetailsFooterActions = ({question, isCover, runAudit, sectionId}) => {
  const {t} = useTranslation('templates');
  const {id} = useParams();
  const { showAllIncidences } = useShowIncidences();

  const [addNote, setAddNote] = useState({
    visible: false,
    note: ''
  });
  const [globalNote, setGlobalNote] = useState({
    visible: false,
    note: runAudit ? question?.note || '' : ''
  });
  const [globalImages, setGlobalImages] = useState(runAudit ? question?.noteImages || [] : []);
  const [showIncidents, setShowIncidents] = useState(false);
  const [selectedTask, setSelectedTask] = useState([]);
  const {upload} = useCrudImage();
  const {createNote} = useCrudNote();

  /** On Upload image */
  const onUpload = useCallback(async (data) => {
    const file ={
      uid: uuidv4(),
      name: 'Image.png',
      status: 'done',
      url: data?.image,
    };
    setGlobalImages(prev => ([...prev, file]));
    if (runAudit) await upload({auditId: id, sectionId, questionId: question?._id, noteImages: [...globalImages, file]});
  }, [globalImages, id, question?._id, runAudit, sectionId, upload]);

  const {props: uploadImagesProps, handleChange, loading} = useUploadImage('', onUpload);

  const locale = {
    emptyText: t('noIncidencesForThisQuestion'),
  };

  /** Show global notes */
  const handleCreateOrUpdateGlobalNote = useCallback( () => {
    setGlobalNote(prev => ({...prev, visible: !prev?.visible}));
  }, []);

  /** Save global notes */
  const onSaveGlobalNote = useCallback(async (note) => {
    setGlobalNote(prev => ({note, visible: !prev?.visible}));
    if (runAudit) await createNote({auditId: id, sectionId, questionId: question?._id, note});
  }, [createNote, id, question?._id, runAudit, sectionId]);

  /** Save global notes */
  const onRemoveGlobalNote = useCallback(async () => {
    setGlobalNote(prev => ({note: '', visible: !prev?.visible}));
    if (runAudit) await createNote({auditId: id, sectionId, questionId: question?._id, note: ''});
  }, [createNote, id, question?._id, runAudit, sectionId]);

  const handleOnClickTask = useCallback((index) => {
    if(selectedTask.some(x => x === index)) setSelectedTask(selectedTask?.filter(x => x !== index));
    else setSelectedTask([...selectedTask, index]);
  }, [selectedTask]);

  useEffect(() => {
    if (showAllIncidences) {
      setShowIncidents(true);
    } else {
      setShowIncidents(false);
    }
  }, [showAllIncidences]);

  const onAddIncidents = useCallback(() => {
    setShowIncidents(!showIncidents);
  }, [showIncidents]);

  if (isCover) {
    return null;
  }

  return (
    <div className="mt-3">
      {!addNote?.visible && addNote?.note && <div className="w-full flex items-center justify-center mb-3"><div style={{ width: '80%' }}>{addNote?.note}</div></div>}
      {addNote?.visible && <div className="w-full flex items-center justify-center mb-3">
        <div style={{ width: '80%' }}>
          <TextArea className="mb-3" placeholder={t('details.noteName')} value={addNote?.note} onChange={e => setAddNote(prev => ({...prev, note: e.target.value}))}/>
          <div className="flex">
            <Button disabled={!addNote?.note} onClick={() => {
              setAddNote(prev => ({...prev, visible: false}));
            }} type="primary" size="large" className="mr-4">{t('details.btnCreate')}</Button>
            <Button size="large" onClick={() => setAddNote(prev => ({...prev, visible: false}))}>{t('details.btnCancel')}</Button>
          </div>
        </div>
      </div>}

      {
        showIncidents && <div className="h-full">
          <div className="font-medium text-black mb-3 font-bold">
            {t('tabs.incidents')}
          </div>
          <Scrollbars
            renderTrackHorizontal={props => <div {...props} style={{display: 'none'}} className="track-horizontal"/>}
            disableHorizontalScrolling
            autoHide
            autoHideDuration={200}
            autoHeight
            autoHeightMin={100}
            autoHeightMax={350}
          >
            <List
              itemLayout="vertical"
              size="large"
              locale={locale}
              dataSource={question?.issues}
              className={classNames(incidentStyles.viewList)}
              renderItem={(item, i) => (
                <TemplateDetailsIssuesHeader
                  i={i}
                  item={item}
                  handleOnClickTask={handleOnClickTask}
                  selectedTask={selectedTask}
                  runAudit={runAudit}
                  sectionId={sectionId}
                  question={question}
                />
              )}
            />
          </Scrollbars>
        </div>
      }

      {globalImages?.length > 0 &&
          <div className="w-full flex items-center justify-start mb-3">
            <GlobalImages
              images={globalImages}
              onUpdate={setGlobalImages}
              sectionId={sectionId}
              questionId={question?._id}
              runAudit={runAudit}
            />
          </div>
      }

      {!globalNote?.visible
        ?
        <div className="w-full flex items-center justify-start mb-3">
          <div className="cursor-pointer" onClick={handleCreateOrUpdateGlobalNote}>{globalNote?.note}</div>
        </div>
        :
        <div className="w-full flex items-center justify-start mb-3">
          <GlobalNote
            note={globalNote?.note}
            onSave={onSaveGlobalNote}
            onCancel={handleCreateOrUpdateGlobalNote}
            onRemove={onRemoveGlobalNote}
          />
        </div>
      }

      <div className="flex items-center justify-between w-full flex-wrap gap-2">
        {question?.issues?.length > 0
          ? <div className="items-center justify-start mt-2">
            <Button type="text" icon={<FlagOutlined/>} onClick={onAddIncidents}
              className={styles.btnAddIncident}>{t('details.incidence')}</Button>
          </div>
          : <div />
        }

        <div className="flex gap-2 flex-wrap">
          <Button
            type="text"
            icon={<EditOutlined />}
            onClick={handleCreateOrUpdateGlobalNote}
            className={styles.btnAddIncident}
            disabled={globalNote?.note && globalNote?.visible}
          >
            {!globalNote?.note ? t('globalNote.create') : t('globalNote.edit')}
          </Button>

          <div className={styles.wrapperUploadImages}>
            <Upload
              {...uploadImagesProps}
              onChange={handleChange}
            >
              <Button
                type="text"
                icon={<PaperClipOutlined />}
                disabled={loading}
                loading={loading}
                className={styles.btnAddIncident}>
                {t('globalMedia.attach')}
              </Button>
            </Upload>
          </div>
        </div>

      </div>
    </div>
  );
};

TemplateDetailsFooterActions.propTypes = {
  question: PropTypes.object,
  isCover: PropTypes.bool,
  runAudit: PropTypes.bool,
  sectionId: PropTypes.string,
};

export default memo(TemplateDetailsFooterActions);
