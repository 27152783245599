import numeral from 'numeral';
import _intersection from 'lodash/intersection';

Object.defineProperty(String.prototype, 'capitalize', {
  value: function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
  },
  enumerable: false
});

export const formatNumber = (price) => {
  return numeral(price).format('0,0.00');
};

export const showPrice = (price, currency='$') => {
  return price ? `${currency}${formatNumber(price)}` : '-';
};

export const scrollToPosition = (value = 0) => {
  document.body.scrollTop = value; // For Safari
  document.documentElement.scrollTop = value;
};

export const getFileName = (value) => {
  if (!value) return '';
  const paths = value.split('/');
  return paths[paths.length - 1];
};

export const showCard = (value) => {
  return '**** **** **** ' + value.substr(-4);
};

/** Custom hasPermissions */
export const hasPermissions = (userPermissions, permissions) => {

  if(!permissions || userPermissions?.includes('ADMIN'))
    return true;

  if (!Array.isArray(permissions)) {
    permissions = [permissions];
  }

  let newPermission = _intersection(permissions, userPermissions);
  return newPermission?.length > 0;
};

export const getActiveColor = (viewParam, view) => {
  if (viewParam === view) return 'var(--primary-color)';
  return '#666666';
};

String.prototype.replaceLast = function (search, replace) {
  return this.replace(new RegExp(search+'([^'+search+']*)$'), replace+'$1');
};

export const getNameInitial = (name) => {
  let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');
  if(!name || name === '') return '';

  let initials = [...name?.matchAll(rgx)] || [];

  initials = (
    (initials?.shift()?.[1] || '') + (initials?.pop()?.[1] || '')
  ).toUpperCase();

  return initials;
};

const initialSpace = {
  modules: {},
  theme: {}
};

export const getModule = (data = initialSpace, module) => {
  return data.modules[module];
};

export const hasModule = (data = initialSpace, module) => {
  return getModule(data, module)?.enabled;
};

export const getAllModule = (data = initialSpace) => {
  return Object.keys(data.modules)
    .filter((key) => data.modules[key].enabled)
    .map((key) => data.modules[key]);
};

export const getTheme = ({ theme } = initialSpace) => {
  return theme;
};

const defaultCbk = () => {};

export const buildCallbacks = (space) => {
  if (!space) {
    return {
      getModule: defaultCbk,
      hasModule: defaultCbk,
      getAllModule: defaultCbk,
      getTheme: defaultCbk
    };
  }
  return {
    getModule: (module) => getModule(space, module),
    hasModule: (module) => hasModule(space, module),
    getAllModule: () => getAllModule(space),
    getTheme: () => getTheme(space)
  };
};

export const isSpaceOwner = (roles = []) => {
  return roles?.some(r => r?.spaceOwner);
};

export const isSpaceAdmin = (roles = []) => {
  return roles?.some(r => r?.adminSpace);
};

export const stringToHslColor = (str='User' , s, l) => {
  let hash = 0;
  for (let i = 0; i < str?.length; i++) {
    hash = str?.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;
  return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
};

export const stringToRGBColor = (str='User' , s, l) => {
  let hash = 0;
  for (let i = 0; i < str?.length; i++) {
    hash = str?.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;
  return h + ', ' + s + ', ' + l;
};

export const hslToRgb = (h, s, l) => {
  // Must be fractions of 1
  s /= 100;
  l /= 100;

  let c = (1 - Math.abs(2 * l - 1)) * s,
    x = c * (1 - Math.abs((h / 60) % 2 - 1)),
    m = l - c/2,
    r = 0,
    g = 0,
    b = 0;

  if (0 <= h && h < 60) {
    r = c; g = x; b = 0;
  } else if (60 <= h && h < 120) {
    r = x; g = c; b = 0;
  } else if (120 <= h && h < 180) {
    r = 0; g = c; b = x;
  } else if (180 <= h && h < 240) {
    r = 0; g = x; b = c;
  } else if (240 <= h && h < 300) {
    r = x; g = 0; b = c;
  } else if (300 <= h && h < 360) {
    r = c; g = 0; b = x;
  }
  r = Math.round((r + m) * 255);
  g = Math.round((g + m) * 255);
  b = Math.round((b + m) * 255);

  return 'rgb(' + r + ',' + g + ',' + b + ')';
};
