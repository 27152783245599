import React, {memo} from 'react';
import MainRouter from './MainRouter';
import MainLayout from '@/layout/MainLayout';
import 'antd/es/slider/style';
import NewEmailDialog from '@/components/NewEmailDialog';
import {RenderViewTypeProvider} from '@/hooks/useRenderViewType';
import {CurrentClientProvider} from '@/hooks/useCurrentClient';
import {AsyncBreadcrumbsProvider} from '@/hooks/useAsyncBreadcrumbs';
import {AuditDataProvider} from '@/modules/auditorAudits/hooks/useAuditData';
import useIndexDbDexie from '@/hooks/useIndexDbDexie';
import {ShareAuditFromCalendarProvider} from '@/hooks/useShareAuditFromCalendar';
import {ShowIncidencesProvider} from '@/modules/auditorAudits/hooks/useShowIncidences';

const MainApp = () => {

  const {db} = useIndexDbDexie();

  db?.open().catch((err) => {
    console.log(err.stack || err);
  });

  return (
    <AsyncBreadcrumbsProvider>
      <AuditDataProvider>
        <ShowIncidencesProvider>
          <CurrentClientProvider>
            <RenderViewTypeProvider>
              <MainLayout>
                <ShareAuditFromCalendarProvider>
                  <MainRouter/>
                  <NewEmailDialog/>
                </ShareAuditFromCalendarProvider>
              </MainLayout>
            </RenderViewTypeProvider>
          </CurrentClientProvider>
        </ShowIncidencesProvider>
      </AuditDataProvider>
    </AsyncBreadcrumbsProvider>
  );
};

export default memo(MainApp);
