/**
 * @author Geider Arevalo
 * @since v0.0.1
 * @date 22/11/21
 */
import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import classNames from 'classnames';
import {List, Card, Image} from 'antd';
import DetailsIssueHeader from './DetailsIssueHeader';
import incidentStyles from '@/modules/templates/components/WrapperManageTemplateSections/components/ManageTemplateSections/components/QuestionOptions/AddIncidentOrIncidentList/index.less';

const Incidents = ({data, question, hasIssues}) => {
  const {t} = useTranslation('templates');

  return (
    <div>
      {hasIssues && <>
        <div className="font-medium text-black my-3 font-bold">
          {t('tabs.incidents')}
        </div>
        <List
          itemLayout="vertical"
          size="large"
          dataSource={data}
          className={classNames(incidentStyles.viewList)}
          renderItem={(item, idx) => <DetailsIssueHeader key={item?._id} idx={idx} data={item}/>}
        />
      </>}

      {(question?.note || question?.noteImages?.length > 0) && <Card
        className={'mb-2 rounded'}
        bodyStyle={{padding: '6px', background: '#FAFAFA'}}
      >
        <div className="font-bold mt-2 mb-3">{t('details.observations')}</div>

        <div className="flex items-center justify-start flex-wrap gap-2">
          {question?.noteImages?.map((item) => {
            return (
              <Image
                key={item?._id}
                alt="evidence"
                height={100}
                style={{width: '100%'}}
                preview={{
                  src: item?.url,
                  mask: <span>{t('preview')}</span>
                }}
                src={item?.url}
              />
            );
          })}
        </div>

        <div className="my-3">
          {question?.note}
        </div>

      </Card>}
    </div>
  );
};

Incidents.propTypes = {
  data: PropTypes.array,
  question: PropTypes.object,
  hasIssues: PropTypes.bool,
};

export default memo(Incidents);
