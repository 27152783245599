/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 9/6/22
 */
import React, {memo, useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import {Input, Button} from 'antd';
import {DeleteOutlined} from '@ant-design/icons';
import classNames from 'classnames';
import {useTranslation} from 'react-i18next';
import styles from './index.less';

const GlobalNote = ({note, onSave, onCancel, onRemove}) => {
  const [value, setValue] = useState(note);

  const {t} = useTranslation('templates');

  const onChange = useCallback((e) => {
    setValue(e?.target?.value);
  }, []);

  const onSubmit = useCallback(() => {
    onSave(value);
  }, [onSave, value]);

  return (
    <div className="w-full">
      <div className={classNames('custom-form-big', styles.wrapperInput)}>
        <Input size="large" value={value} onChange={onChange} />
      </div>

      <div className={classNames('flex gap-2 py-2 flex items-center justify-between', styles.wrapperButtons)}>
        <div className="flex gap-2">
          <Button size="large" type="primary" className="custom-btn border-rounded-5" onClick={onSubmit}>{t('globalNote.save')}</Button>
          <Button size="large" className="custom-btn" onClick={onCancel}>{t('globalNote.cancel')}</Button>
        </div>

        <div className="flex gap-2">
          <Button
            onClick={onRemove}
            type="dashed"
            shape="circle"
            icon={<DeleteOutlined />}
            danger
            style={{ borderRadius: '50%' }}
          />
        </div>
      </div>
    </div>
  );
};

GlobalNote.propTypes = {
  note: PropTypes.string,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  onRemove: PropTypes.func,
};

export default memo(GlobalNote);
