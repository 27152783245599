import registerTraces from './registerTraces';
import clientRegisters from './clientRegisters';
import audits from './audits';
import tasks from './tasks';
import incidents from './incidents';
import calendar from './calendar';
import auditorAudits from './auditorAudits';
import clientMain from './clientMain.json';
import main from './main';
import services from './services';
import socialReason from './socialReason';
import employees from './employees';
import templates from './templates';
import users from './users';
import rolesAndPermissions from './rolesAndPermissions.json';
import analytics from './analytics';
import pendingAudits from './pendingAudits.json';
import contracts from './contracts';
import clients from './clients';
import common from './common';
import authentication from './authentication';
import permissions from './permissions';
import forms from './forms';
import table from './table';
import navigation from './navigation';
import admin from './admin';
import profile from './profile';
import notifications from './notifications';
import alert from './alert';
import nomenclatures from './nomenclatures';
import nomenclaturesEmployeeProfile from './nomenclaturesEmployeeProfile';
import nomenclaturesEmployeeType from './nomenclaturesEmployeeType';
import nomenclaturesPaymentType from './nomenclaturesPaymentType';
import nomenclaturesActivities from './nomenclaturesActivities';
import nomenclaturesServicesCategory from './nomenclaturesServicesCategory';
import nomenclaturesTemplatesCategory from './nomenclaturesTemplatesCategory';
import nomenclaturesAreaType from './nomenclaturesAreaType';
import nomenclaturesBillingMethod from './nomenclaturesBillingMethod';
import nomenclaturesCompanies from './nomenclaturesCompanies';
import invitations from './invitations';
import accessRequests from './accessRequests';
import miscellaneous from './miscellaneous';
import clientsArea from './clientsArea';
import incidences from './incidences';
import dashboard from './dashboard.json';
import registers from './registers.json';

export {
  registerTraces,
  clientRegisters,
  registers,
  audits,
  tasks,
  incidents,
  calendar,
  auditorAudits,
  clientMain,
  main,
  services,
  socialReason,
  employees,
  templates,
  users,
  rolesAndPermissions,
  analytics,
  pendingAudits,
  contracts,
  clients,
  profile,
  alert,
  common,
  authentication,
  permissions,
  forms,
  table,
  navigation,
  admin,
  notifications,
  nomenclatures,
  nomenclaturesEmployeeProfile,
  nomenclaturesEmployeeType,
  nomenclaturesPaymentType,
  nomenclaturesActivities,
  nomenclaturesServicesCategory,
  nomenclaturesTemplatesCategory,
  nomenclaturesAreaType,
  nomenclaturesBillingMethod,
  nomenclaturesCompanies,
  invitations,
  accessRequests,
  miscellaneous,
  clientsArea,
  incidences,
  dashboard,
};
