/* eslint-disable react-hooks/rules-of-hooks */
import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { Button, Form, Popconfirm, Divider } from 'antd';
import Tooltip from '@/components/Tooltip';
import { RouteLoader } from '@dofleini/security';
import {useTranslation} from 'react-i18next';
import FloatContainer from '@/components/FloatContainer';
import useCrud from '@/hooks/useCrud';
import {useHistory} from 'react-router-dom';
import FormSkeleton from '@/components/FormGenerator/FormSkeleton';
import {useFormTitle} from '@/hooks/useFormTitle';
import OpenDetail from '@/components/OpenDialog';
import {EntityDetailContent} from '@/components/EntityDetailContent';
import isEmpty from 'lodash/isEmpty';
import FormBuilder from 'antd-form-builder';
import useDeepCompareEffect from 'use-deep-compare-effect';
import {DetailsContextProvider, useDetailsContext} from '@/contexts/DetailsContext';
import {createReferenceWarning} from '@/utils/createReferenceWarning';
import classNames from 'classnames';
import EditLineIcon from 'remixicon-react/EditLineIcon';
import FileCopyLineIcon from 'remixicon-react/FileCopyLineIcon';
import DeleteBinLineIcon from 'remixicon-react/DeleteBinLineIcon';
import CloseLineIcon from 'remixicon-react/CloseLineIcon';

export const headerActions = ({
  data,
  t,
  viewMode,
  onSubmit,
  toggleView,
  handleClose,
  onDelete,
  isLoading,
  headerProps = {},
  type
}) => {
  const {extras, canRemove, canEdit, canDuplicate = false, extrasEdit, hideSaveEdit, hideCancelEdit} = headerProps;
  const ExtraOptions = extras;
  const ExtraOptionsEdit = extrasEdit;
  if (viewMode)
    return [
      ExtraOptions && <ExtraOptions key={'detail-extra-actions'} data={data}/>,
      canEdit && <Tooltip parentContainer title={t('edit')} key={'edit'}>
        <Button type="text" icon={<EditLineIcon color="#7F7F7F"/>} onClick={toggleView} disabled={isLoading}/>
      </Tooltip>,
      canDuplicate && <Tooltip parentContainer title={t('edit')} key={'duplicate'}>
        <Button type="text" icon={<FileCopyLineIcon color="#7F7F7F"/>} onClick={toggleView} disabled={isLoading}/>
      </Tooltip>,
      canRemove && <Popconfirm
        key={'remove'}
        placement="bottomRight"
        title={t('deleteConfirmation')}
        onConfirm={onDelete}
        okText={t('delete')}
        cancelText={t('cancel')}
      >
        <Tooltip parentContainer title={t('delete')}>
          <Button type="text" icon={<DeleteBinLineIcon color="#DB0707"/>} loading={isLoading}/>
        </Tooltip>
      </Popconfirm>,
      type === 'DRAWER' && <Divider key="divider" type="vertical" style={{height: '1.3rem', backgroundColor: '#E8E8E8'}}/>,
      type === 'DRAWER' && <Tooltip parentContainer title={t('close')} key={'close'}>
        <Button type="text" icon={<CloseLineIcon color="#7F7F7F"/>} onClick={handleClose} disabled={isLoading}/>
      </Tooltip>
    ];
  return (
    [
      !hideCancelEdit && <Button className="custom-footer-btn mr-2" key="back" onClick={handleClose} disabled={isLoading}>
        {t('cancel')}
      </Button>,
      ExtraOptionsEdit && <ExtraOptionsEdit
        data={data}
        onSubmit={onSubmit}
        isLoading={isLoading}
        onCancel={handleClose} className="custom-footer-btn"/>,

      !hideSaveEdit && <Button
        key="submit"
        type="primary"
        onClick={onSubmit}
        loading={isLoading}
        disabled={isLoading}
        className="custom-footer-btn"
      >
        {t('save')}
      </Button>
    ]
  );
};

export const createEntityDetail = (
  {
    route, service, name, module, translation, translationPrefix, useFields, useGetOne,
    options = {
      create: true,
      edit: true,
      duplicate: false,
      details: true
    },
    willForceUpdate,
    getOneKey = 'getOne',
    mapPayload = v => v,
    mapResponse = v => v,
    messageReferenceWarningDialog,
    columns,
    entityName,
    isSubmodule = false,
    parentPath = '',
    afterSubmit,
    type,
    customHeaderProps,
    ...props
  }) => {
  const dataMock = {};
  const {
    useErrorMiddleware,
    ReferenceWarningDialog,
  } = createReferenceWarning({
    translation,
    service,
    module,
    message: messageReferenceWarningDialog,
  });

  // eslint-disable-next-line react/display-name
  const FormComponent = memo(({ headerProps, onMountData, handleCustomClose, width }) => {
    const {t} = useTranslation(translation);
    const {push} = useHistory();
    const { create, update, remove, isLoading: isCrudLoading } = useCrud(service, module, getOneKey);
    const {isOpen, closeDialog, payload} = useDetailsContext(name);
    const errorHandler = useErrorMiddleware();
    const {isLoading, data: res} = useGetOne(payload);
    const [form] = Form.useForm();
    const {title, viewMode, toggleView, backToView} = useFormTitle();
    const fields = useFields(viewMode, form);
    const forceUpdate = FormBuilder.useForceUpdate();

    const data = useMemo(() => mapResponse(res?.data), [res]);

    const initValues = data || dataMock;

    const id = initValues?._id;

    useEffect(() => {
      (data && onMountData) && onMountData([data]);
    }, [data, onMountData]);

    useEffect(() => {
      setTimeout(() => forceUpdate(), 100);
    }, [forceUpdate, id]);

    const close = useCallback(() => {
      closeDialog();
      handleCustomClose && handleCustomClose();
      setTimeout(() => {
        if (isSubmodule) {
          push(parentPath);
          return;
        }
        route && push(route);
        form.resetFields();
      }, 500);
    }, [closeDialog, form, handleCustomClose, push]);

    const onClose = useCallback(() => {
      if (backToView) {
        return toggleView();
      }
      close();
    }, [backToView, close, toggleView]);

    const handleFinish = useCallback(async (values) => {
      if (id) {
        const {data} = await update({_id: id, ...values});
        form.setFieldsValue(mapResponse(data));
        if (afterSubmit) {
          afterSubmit(data?._id);
        } else onClose();
      } else {
        const res = await create(values);
        if (afterSubmit) {
          afterSubmit(res?.data?._id);
        } else onClose();
      }
    }, [id, create, onClose, update, form]);

    const onFinish = useCallback((values) => {
      const mappedValues = mapPayload(values);

      handleFinish(mappedValues).catch(
        errorHandler({
          onForce: () => handleFinish({force: true, ...mappedValues}),
          onRecovery: () => close()
        })
      );

    }, [close, errorHandler, handleFinish]);

    const handleDelete = useCallback(async () => {
      if (initValues) {
        await remove(initValues?._id);
        onClose();
      }
    }, [onClose, initValues, remove]);

    const handleClose = useCallback(() => {
      form.setFieldsValue(initValues);
      onClose();
    }, [form, initValues, onClose]);

    useDeepCompareEffect(() => {
      if (!isEmpty(initValues)) {
        form.setFieldsValue(initValues);
      }
    }, [initValues]);

    return (
      <FloatContainer
        {...props}
        /* eslint-disable-next-line react/prop-types */
        width={width || props?.width}
        visible={isOpen}
        title={t(`${translationPrefix}.${title}`)}
        onClose={onClose}
        type={type}
        footer={headerActions({
          headerProps,
          isLoading: isLoading || isCrudLoading,
          handleClose,
          data: initValues,
          onDelete: handleDelete,
          onSubmit: () => form.submit(),
          entityName: entityName,
          t,
          toggleView,
          viewMode,
          type
        })}>
        <Form
          form={form} onFinish={onFinish} layout={!viewMode ? 'vertical' : 'horizontal'}
          onValuesChange={willForceUpdate ? forceUpdate : false}
          scrollToFirstError
          /* eslint-disable-next-line react/prop-types */
          className={classNames(props?.className, viewMode ? 'form-is-in-view-mode' : 'form-is-in-edit-mode')}
        >
          {isLoading ?
            <FormSkeleton/> :
            <EntityDetailContent viewMode={viewMode} form={form} data={initValues} fields={fields} columns={columns}/>
          }
        </Form>
      </FloatContainer>
    );
  });

  const pathRoute = [];
  if (options?.create) {
    route && pathRoute.push(`${route}/create`);
  }
  if (options?.details) {
    route && pathRoute.push(`${route}/:id`);
  }
  if (options?.edit) {
    route && pathRoute.push(`${route}/:id/edit`);
  }

  const routes = {
    NestedView: {
      path: pathRoute,
      exact: true,
      data: {
        dialog: name
      },
      component: OpenDetail
    }
  };

  // eslint-disable-next-line react/display-name
  const Detail = memo((props) => {
    return <DetailsContextProvider>
      {/* eslint-disable-next-line react/prop-types */}
      <FormComponent {...props} type={type} headerProps={customHeaderProps ? customHeaderProps : props?.headerProps}/>
      <RouteLoader routes={routes} notfoundRedirect={route}/>
      <ReferenceWarningDialog/>
    </DetailsContextProvider>;
  });
  FormComponent.propTypes = {
    headerProps: PropTypes.object,
    onMountData: PropTypes.func,
    handleCustomClose: PropTypes.func,
    width: PropTypes.string,
  };
  FormComponent.defaultProps = {
    headerProps: {canRemove: true, canEdit: true}
  };
  return {FormComponent, Detail};
};


createEntityDetail.propTypes = {
  module: PropTypes.any,
  entityName: PropTypes.any,
  name: PropTypes.any,
  options: PropTypes.object,
  route: PropTypes.any,
  service: PropTypes.any,
  translation: PropTypes.any,
  translationPrefix: PropTypes.any,
  useFields: PropTypes.any,
  useGetOne: PropTypes.any,
  willForceUpdate: PropTypes.bool,
  mapPayload: PropTypes.func,
  className: PropTypes.string,
};
