/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 21/11/21
 */
import React, {memo, useState, useCallback, useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import { Button, Alert } from 'antd';
import StackFillIcon from 'remixicon-react/StackFillIcon';
import classNames from 'classnames';
import styles from './index.less';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import SectionBox from '@/modules/templates/components/TemplateSectionsDrawer/components/SectionBox';
import BookmarksPreview from '@/modules/templates/components/TemplatePreview/components/BookmarksPreview';
import {TYPE_SECTION} from '@/modules/templates/contants/sections';
import PlayLineIcon from 'remixicon-react/PlayLineIcon';
import {useDetailsContext} from '@/contexts/DetailsContext';
import {useAuditData} from '@/modules/auditorAudits/hooks/useAuditData';
// import useIndexDbDexie from '@/hooks/useIndexDbDexie';
import {useGetOneAudits} from '@/modules/audits/hooks/useAudits';
import {
  AUDITOR_AUDITS_DIALOG_NAME_SPACE,
} from '@/modules/auditorAudits/contants/auditorAudits';
import {useExecuteAudit, useGetAllSections} from '@/modules/auditorAudits/hooks/useAuditorAudits';
import {useShareAuditFromCalendar} from '@/hooks/useShareAuditFromCalendar';

const AuditSections = ({entity}) => {
  const {t} = useTranslation('templates');
  const [visible, setVisible] = useState(false);
  const [dataIntro, setDataIntro] = useState([]);
  const [dataSections, setDataSections] = useState([]);
  const {push} = useHistory();
  const {closeDialog} = useDetailsContext(AUDITOR_AUDITS_DIALOG_NAME_SPACE);
  const {setAudit} = useShareAuditFromCalendar();

  const {onExecute, isLoading: isExecuting} = useExecuteAudit();

  const {data: sectionData, isLoading} = useGetAllSections(entity?._id);

  const {setDataIntro: setDataIntroCtx, setDataSections: setDataSectionsCtx, setCurrentAudit} = useAuditData();
  const {data: audit} = useGetOneAudits({id: entity?.audit});

  // const {db} = useIndexDbDexie();

  const showDrawer = useCallback(() => {
    setVisible(!visible);
  }, [visible]);

  const closeDrawer = useCallback(() => {
    setVisible(false);
  }, []);


  // const handleEditTemplate = useCallback(() => {
  //   push(`/templates/manage/${entity?._id}`);
  // }, [entity?._id, push]);

  const handleRunAudit = useCallback(async (val) => {
    closeDialog();
    setAudit(''); // Clean share audit context
    setCurrentAudit({audit: audit?.data, establishment: audit?.data?.establishment?.name, service: audit?.data?.service?.name, ...entity});
    setDataIntroCtx(dataIntro);
    setDataSectionsCtx(dataSections);
    // const data = {
    //   _id: entity?._id,
    //   currentAudit: {audit: audit?.data, establishment: audit?.data?.establishment?.name, service: audit?.data?.service?.name, ...entity},
    //   dataIntro,
    //   dataSections
    // };

    // Update data
    // db.table('audits').get(entity?._id).then((res) => {
    //   if (!res?._id) {
    //     db?.audits?.add(data).then(() => {
    //       // retrieve all posts inside the database
    //       db?.audits?.toArray();
    //     });
    //   }
    // });
    // Update current audit
    // db?.currentAudit.clear(); // Clear data
    // db.table('currentAudit').get(entity?._id).then((res) => {
    //   if (!res?._id) {
    //     db?.currentAudit?.add({_id: entity?._id}).then(() => {
    //       // retrieve all posts inside the database
    //       db?.currentAudit?.toArray();
    //     });
    //   }
    // });

    if (entity.status === 'draft') {
      await onExecute({auditId: entity?.audit || entity?._id,  use_previous: val}).then(() => {
        push(`/auditor/audits/run/${entity?.audit || entity?._id}`);
      });
    } else {
      push(`/auditor/audits/run/${entity?.audit || entity?._id}`);
    }
  }, [audit?.data, closeDialog, dataIntro, dataSections, entity, onExecute, push, setAudit, setCurrentAudit, setDataIntroCtx, setDataSectionsCtx]);

  useEffect(() => {
    if (!isLoading) {
      setDataIntro(sectionData?.filter((x) => x.type === TYPE_SECTION.DATA));
      setDataSections(sectionData?.filter((x) => x.type !== TYPE_SECTION.DATA));
    }
  }, [sectionData, isLoading]);

  const [activeSection, setActiveSection] = useState(dataSections?.[0]);

  useEffect(() => {
    if (document.querySelector(`#section-${activeSection?._id}`)) {
      document.querySelector(`#section-${activeSection?._id}`).scrollIntoView({
        behavior: 'smooth'
      });
    }
  }, [activeSection?._id]);

  const showActions = useMemo(() => {
    if (entity?.status === 'draft') {
      return (
        <div className="flex">
          {entity?.hasPreviousAudit && <Button disabled={isExecuting} loading={isExecuting}
            className={classNames('flex mr-4', styles.btnStartAuditFromLast)} type="primary" size="large"
            icon={<PlayLineIcon/>} onClick={() => handleRunAudit(true)}>
            <span className="ml-2">{entity?.status === 'running' ? t('continueAudit') : t('startAuditFromLast')}</span>
          </Button>}

          <Button disabled={isExecuting} loading={isExecuting} className="flex" type="primary" size="large" icon={<PlayLineIcon/>} onClick={() => handleRunAudit(false)}>
            <span className="ml-2">{entity?.status === 'running' ? t('continueAudit') : t('startAudit')}</span>
          </Button>
        </div>
      );
    }

    return (
      <Button disabled={isExecuting} loading={isExecuting} className="flex" type="primary" size="large" icon={<PlayLineIcon/>} onClick={() => handleRunAudit(false)}>
        <span className="ml-2">{entity?.status === 'running' ? t('continueAudit') : t('startAudit')}</span>
      </Button>
    );
  }, [entity?.hasPreviousAudit, entity?.status, handleRunAudit, isExecuting, t]);

  return (
    <div className="w-full">
      <div className="section-play-and-edit" onClick={closeDrawer}>
        {!entity?.isIncomplete ?
          showActions
          : <Alert className="rounded" message={t('incompleteInfo')} type="warning" />}
      </div>

      <div className={classNames(styles.wrapperSidebarBookMarkDrawer, 'w-full h-full')}>
        <div className="sidebar-drawer" onBlur={() => {
          if (visible) {
            closeDrawer();
          }
        }}>
          <div className={styles.bookMark}>
            <div className={styles.bookMarkBar} onClick={showDrawer}>
              {t('audits.navigation')}
              <StackFillIcon style={{transform: 'rotate(180deg)', margin: '10px 0 0 0', padding: '2px'}} />
            </div>
          </div>
          <div className={classNames(styles.sidebarBookMarkDrawer, visible ? styles.animateSectionsDrawer : styles.hiddenSectionsDrawer)} id="sidebarBookMarkDrawer">
            <BookmarksPreview
              currentTemplate={entity}
              dataCover={dataIntro}
              data={dataIntro?.concat(dataSections)}
              activeSection={activeSection}
              setActiveSection={setActiveSection}
              // onScroll={onScroll}
            />
          </div>
        </div>

        <div className={classNames('p-4 ml-10', styles.sectionsDrawerContent)} onClick={() => setVisible(false)}>
          {
            dataIntro?.map((item) => {
              return (
                <SectionBox key={item?._id} section={item} id={'section-'+item?._id}/>
              );
            })
          }
          {
            dataSections?.map((item) => {
              return (
                <SectionBox key={item?._id} section={item} id={'section-'+item?._id}/>
              );
            })
          }
        </div>
      </div>
    </div>
  );
};

AuditSections.propTypes = {
  entity: PropTypes.object,
};

export default memo(AuditSections);

/**
 * cellRender
 * */
/**
 * This is not a components,
 * it is a function to render the Code components in a table cell
 * @param {String} text this is the value of the dataIndex of the table
 * @param {Object} form
 * @param {Object} initialValues
 * */
export const renderAuditSections = (text, form, initialValues) => {

  return (
    <AuditSections entity={initialValues}/>
  );
};
