import {ApiClientService, EntityApiService} from '@dofleini/security';
import {authentication} from '@/settings';

class SpaceApiService extends EntityApiService {
    existIdentifier = (params) => {
      return ApiClientService.post(this.getPath('/exist-identifier'), params);
    };

    mySpaces = () => {
      return ApiClientService.get(this.getPath('/my-spaces'), {});
    }
}

export default new SpaceApiService(`${authentication.path}/api/spaces`);
