import {DoubleLeftOutlined, PauseOutlined} from '@ant-design/icons';

export const CACHE_KEY_LIST_INCIDENTS = 'incidents';
export const CACHE_KEY_INCIDENTS = 'incidents-one';
export const ROUTE_PATH_INCIDENTS = '/incidents/list/audits';
export const TRANSLATION_PREFIX_INCIDENTS = 'incidences';

export const CACHE_KEY_LIST_HISTORY_INCIDENCE = 'incidenceHistory';
export const CACHE_KEY_LIST_HISTORY_TASK = 'taskHistory';

export const STATUS_ISSUE = {
  draft: 'draft',
  running: 'running',
  resolved: 'resolved',
};

export const STATUS_ISSUE_APPEARANCE = {
  draft: {color: '#DB6307'},
  running: {color: '#096dd9'},
  resolved: {color: '#389e0d'},
};

export  const PRIORITY_INCIDENT_APPEARANCE = {
  toDo: {color: '#DB6307'},
  running: {color: '#096dd9'},
  complete: {color: '#389e0d'},
};

export const PRIORITY_APPEARANCE = {
  High: {color: '#EF0000', icon: <DoubleLeftOutlined rotate={90}/>},
  Medium: {color: '#DB6307', icon: <PauseOutlined rotate={90}/>},
  Low: {color: '#0083D7', icon: <DoubleLeftOutlined rotate={270}/>},
  high: {color: '#EF0000', icon: <DoubleLeftOutlined rotate={90}/>},
  medium: {color: '#DB6307', icon: <PauseOutlined rotate={90}/>},
  low: {color: '#0083D7', icon: <DoubleLeftOutlined rotate={270}/>},
};

export const options = [
  {
    title: 'options',
    action: 'options',
    style: {minWidth: 120},
    disabled: true
  },
  {
    title: 'details',
    action: 'details',
    style: {minWidth: 120},
    permissions:['USER:READ']
  }
];

