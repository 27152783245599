import {useCallback, useMemo} from 'react';
import {createGetOneHook} from '@/utils/createGetOneHook';
import {createFieldsHook} from '@/utils/createFieldsHook';
import AuditsApiService from '@/modules/audits/services/AuditsApiService';
import {CACHE_KEY_LIST_AUDITS, CACHE_KEY_AUDITS} from '@/modules/audits/contants/audits';
import {fields} from '@/modules/audits/contants/audits.form';
import AuditorAuditsApiService from '@/modules/auditorAudits/services/AuditorAuditsApiService';
import ReportsApiService from '@/services/ReportsApiService';
import {useQuery, useMutation, useQueryClient} from 'react-query';
import moment from 'moment';
import useAlerts from '@/hooks/useAlerts';

export const useGetOneAudits = createGetOneHook(AuditsApiService, 'id', CACHE_KEY_AUDITS);
export const useFields = createFieldsHook('audits', fields);

export const useGetOneAudit = (auditId, launched) => {
  const queryConfig = useMemo(() => {
    return {enabled: !!(auditId && launched)};
  }, [auditId, launched]);

  const {isLoading, isError, error, data} = useQuery([CACHE_KEY_AUDITS, auditId], async () => {
    const {data} = await AuditorAuditsApiService.getOneAudit({auditId});
    return data;
  }, queryConfig);

  return {isLoading, isError, error, data};
};

export const useGetOneAuditForPreview = (auditId, preview) => {
  const queryConfig = useMemo(() => {
    return {enabled: !!(auditId)};
  }, [auditId]);

  const {isLoading, isError, error, data} = useQuery([CACHE_KEY_AUDITS, auditId], async () => {
    const {data} = await AuditorAuditsApiService.getOneAuditForPreview({auditId, preview});
    return data;
  }, queryConfig);

  return {isLoading, isError, error, data};
};

export const useReopen = () => {
  const queryClient = useQueryClient();
  const { putErrorCode } = useAlerts();

  const {mutateAsync: onReopen, isLoading} = useMutation(AuditorAuditsApiService.reOpen,
    {
      onSuccess: ({data}) => {
        queryClient.invalidateQueries(CACHE_KEY_LIST_AUDITS);
        queryClient.invalidateQueries([CACHE_KEY_AUDITS, data?._id]);
        queryClient.invalidateQueries(data?._id);
      },
      onError: (e) => {
        putErrorCode(e);
      }
    });

  return {onReopen, isLoading};
};

export const useDownloadPDF = () => {
  const { putErrorCode } = useAlerts();
  const {mutateAsync: download, isLoading} = useMutation(ReportsApiService.downloadPDFAudit);

  const onDownload = useCallback(async (audit, isPreview) => {
    let date;
    if (audit?.finishedDate) {
      date = moment(audit?.finishedDate).format('DD-MM-YYYY').toString();
    } else if (audit.date?.[1]) {
      date = moment(audit?.date?.[1]).format('DD-MM-YYYY').toString();
    } else {
      date = moment().format('DD-MM-YYYY').toString();
    }
    try {
      await download({
        _id: audit?._id,
        pdfName: `${audit?.establishment?.name}-${audit?.service?.name}-${date}.pdf`,
        isPreview,
      });
    } catch (e) {
      putErrorCode(e);
    }
  }, [download, putErrorCode]);
  return {onDownload, isLoading};
};
