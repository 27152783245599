import { useCallback, useEffect, useState } from 'react';
import {useMutation} from 'react-query';
import {ApiClientService} from '@dofleini/security';
import useAlerts from '@/hooks/useAlerts';
import {authentication} from '@/settings';
import {message} from 'antd';
import UploadApiService from '@/modules/admin/services/UploadApiService';

function getBase64(img, callback) {
  // eslint-disable-next-line no-undef
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

export function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}


const useUploadImage = (value, onChange) => {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImage] = useState(value);

  useEffect(() => {
    setImage(value);
  }, [value]);

  const handleChange = useCallback((info, callBack) => {
    if (info?.file?.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info?.file?.status === 'done') {
      // Get this url from response in real world.
      getBase64(info?.file?.originFileObj, imageUrl => {
        setImage(imageUrl);
        onChange && onChange(info?.file?.response, info?.file);
        setLoading(false);
        if(callBack)
          return callBack(info, imageUrl);
      });
    }
    if (info?.file?.status === 'removed') {
      setLoading(false);
      if(onChange)
        return onChange({status: 'removed', fileList: info?.fileList});
    }
  }, [onChange]);


  const props = {
    action: `${ApiClientService.API_URL.replace(/\/$/, '')}${authentication.path}/api/files/image`,
    headers: {
      Authorization: `Bearer ${ApiClientService.getToken()}`,
      ...ApiClientService.defaultHeaders
    },
    beforeUpload(file, maxWidth = 800, maxHeight = 800) {
      if (!file?.type?.includes('image')) return Promise.resolve(true);
      else {
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            const img = document.createElement('img');
            img.src = reader.result;
            img.onload = () => {
              // Calculate scaled dimensions while maintaining aspect ratio
              let width = img.naturalWidth;
              let height = img.naturalHeight;
              if (maxWidth && width > maxWidth) {
                height *= maxWidth / width;
                width = maxWidth;
              }
              if (maxHeight && height > maxHeight) {
                width *= maxHeight / height;
                height = maxHeight;
              }
              // Create canvas with scaled dimensions
              const canvas = document.createElement('canvas');
              canvas.width = width;
              canvas.height = height;
              const ctx = canvas.getContext('2d');
              ctx.drawImage(img, 0, 0, width, height);
              // Convert canvas to blob and resolve the promise
              canvas.toBlob((result) => resolve(result), 'image/jpeg', 0.8);
            };
          };
        });
      }
    }
  };

  return {
    props,
    handleChange,
    imageUrl,
    loading
  };

};

export const useUploadFile = () => {
  const {putErrorCode} = useAlerts();

  const {mutateAsync: upload, isLoading} = useMutation(UploadApiService.uploadFile,
    {
      onSuccess: () => {
      },
      onError: (e) => {
        putErrorCode(e);
      }
    });

  const onUpload = useCallback((file) => {
    if (file) {
      const fd = new FormData();
      fd.append('files', file);
      return upload(fd);
    }
  }, [upload]);

  const props = {
    action: `${ApiClientService.API_URL.replace(/\/$/, '')}${authentication.path}/api/files/image`,
    headers: {
      Authorization: `Bearer ${ApiClientService.getToken()}`,
      ...ApiClientService.defaultHeaders
    },
  };

  return {props, onUpload, isLoading};
};

export default useUploadImage;
