import {useCallback, useEffect, useMemo, useState} from 'react';
import {useQuery, useQueryClient} from 'react-query';
import {ApiClientService} from '@dofleini/security';
import {useHistory, useLocation} from 'react-router-dom';
import {isEqual} from 'lodash';

const isSameFilter = (filters) => {
  const lastFilters = window.localStorage.getItem('incidence-filters');
  if (!filters && lastFilters === 'undefined') {
    return true;
  }
  if (lastFilters !== 'undefined') {
    return isEqual(JSON.parse(lastFilters), filters);
  }
  return false;
};
export const createPaginationHook = (name, service) => {
  let asyncFn;

  switch (typeof service) {
    case 'function':
      asyncFn = service;
      break;
    case 'string':
      asyncFn = params => ApiClientService.post(service, params);
      break;
    default:
      throw new TypeError('Invalid type of service');
  }

  return (search, filters, config = {}, queryConfig = {}) => {
    const queryClient = useQueryClient();
    const history = useHistory();
    const {pathname, search: locSearch} = useLocation();
    const [size, setSize] = useState(config.size || 10);
    const [sort, setSort] = useState(config.sort);
    // eslint-disable-next-line no-unused-vars
    const [first, setFirst] = useState(false);

    const searchParams = useMemo(() => {
      return new URLSearchParams(locSearch);
    }, [locSearch]);

    // eslint-disable-next-line no-unused-vars
    const setFirstPageForIncidences = useMemo(() => {
      if (filters?.type === 'AND') return true;
      return (filters?.type === 'TERM' && filters?.field !== 'type');
    }, [filters?.field, filters?.type]);

    const pageFromUrl = parseInt(searchParams.get('page')) || 1;
    const [page, setPage] = useState(pageFromUrl || 1);

    const isIncidenceAudit = useMemo(() => {
      return pathname?.includes('/incidents/list/audits');
    }, [pathname]);

    const isIncidence = useMemo(() => {
      return pathname?.includes('/incidents/list');
    }, [pathname]);

    const isIncidenceMeasureOther = useMemo(() => {
      return pathname?.includes('/incidents/list/measurements') || pathname?.includes('/incidents/list/others');
    }, [pathname]);

    useEffect(() => {
      if (pageFromUrl !== page && isIncidence) {
        searchParams.set('page', page);
        history.push({ search: searchParams.toString() });
        // if (page !== 1)
        setFirst(false);
      }
    }, [history, isIncidence, page, pageFromUrl, searchParams]);

    useEffect(() => {
      const lastFilters = window.localStorage.getItem('incidence-filters');
      if (!isIncidence) {
        setPage(1);
      }
      if (isIncidenceAudit && (!isSameFilter(filters)) && !first) {
        if (lastFilters !== 'undefined') {
          if (!isEqual(JSON.parse(lastFilters), filters)) {
            window.localStorage.setItem('incidence-filters', JSON.stringify(filters));
          }
        }
        setPage(1);
        setFirst(true);
        return;
      }
      if (isIncidenceMeasureOther && !isSameFilter(filters) && filters && !first) {
        if (lastFilters !== 'undefined') {
          if (!isEqual(JSON.parse(lastFilters), filters)) {
            window.localStorage.setItem('incidence-filters', JSON.stringify(filters));
          }
        }
        setPage(1);
        setFirst(true);
        return;
      }
      if (isIncidence && lastFilters === 'undefined' && filters) {
        window.localStorage.setItem('incidence-filters', JSON.stringify(filters));
      }
    }, [filters, first, isIncidence, isIncidenceAudit, isIncidenceMeasureOther, setFirstPageForIncidences]);

    /*    useEffect(() => {
      setRenderCount(prevRenderCount => prevRenderCount + 1);
      const lastFilters = window.localStorage.getItem('incidence-filters');
      if (isIncidenceMeasureOther && (!isSameFilter(filters)) && !first) {
        if (lastFilters !== 'undefined') {
          if (!isEqual(JSON.parse(lastFilters), filters)) {
            window.localStorage.setItem('incidence-filters', JSON.stringify(filters));
          }
        }
        setPage(1);
        setFirst(true);
        return;
      }
      if (isIncidenceMeasureOther && lastFilters === 'undefined' && filters) {
        window.localStorage.setItem('incidence-filters', JSON.stringify(filters));
      }
    }, [filters, first, isIncidenceMeasureOther]);*/

    const fetchData = useCallback(async (page = 1) => {
      const {data, headers} = await asyncFn({page, size, search, filters, sort});
      const totalPages = Number(headers['x-total-count']);
      const hasMore = data.length === size;
      return {data, totalPages, hasMore};
    }, [size, search, filters, sort]);

    const {
      status,
      error,
      isFetching,
      isLoading,
      isError,
      refetch,
      data = {},
    } = useQuery([name, page, search, size, sort, filters], () => fetchData(page), {
      ...queryConfig,
      keepPreviousData: true
    });

    useEffect(() => {
      if (data?.hasMore) {
        queryClient.prefetchQuery([name, page + 1, search, size, filters], () => fetchData(page + 1));
      }
      if (!isIncidence && !data?.data?.length && page > 1) {
        setPage(page - 1);
      }
    }, [page, size, search, filters, fetchData, queryClient, data, isIncidence]);

    return {
      page,
      status,
      ...data,
      error,
      isFetching,
      isLoading,
      isError,
      refetch,
      setPage,
      setSize,
      setSort,
      size,
      activeKey: [name, page, search, size, filters]
    };
    // Prefetch the next page!
  };
};
