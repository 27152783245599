/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 9/12/21
 */
import React, {memo} from 'react';
import PropTypes from 'prop-types';
import CheckIssuesFromRunAudit from '@/modules/templates/components/TemplateDetailsIssuesHeader/CheckIssuesFromRunAudit';
import CheckIssuesFromDetails from '@/modules/templates/components/TemplateDetailsIssuesHeader/CheckIssuesFromDetails';

const TemplateDetailsIssuesHeader = ({item, runAudit, sectionId, question}) => {

  if (runAudit) return <CheckIssuesFromRunAudit item={item} runAudit={runAudit} sectionId={sectionId} question={question} />;

  return <CheckIssuesFromDetails item={item} />;
};

TemplateDetailsIssuesHeader.propTypes = {
  item: PropTypes.object,
  i: PropTypes.number,
  handleOnClickTask: PropTypes.func,
  selectedTask: PropTypes.number,
  runAudit: PropTypes.bool,
  sectionId: PropTypes.string,
  question: PropTypes.object,
};

export default memo(TemplateDetailsIssuesHeader);
