import {EntityApiService, ApiClientService} from '@dofleini/security';

class AuditorAuditsApiService extends EntityApiService {

    getOneAudit = ({auditId}) => ApiClientService.get(this.getPath(`/${auditId}`));

    getOneAuditForPreview = ({auditId, preview}) => {
      if (preview) {
        return ApiClientService.get(this.getPath(`/${auditId}/get-preview`));
      }
      return ApiClientService.get(this.getPath(`/${auditId}`));
    }

    addAnswer = (params) => ApiClientService.post(this.getPath(`/${params?.id}/answer/add`), params);

    updateAnswer = (params) => {
      const {answerId} = params;
      const {id: auditId} = params;

      delete params?.answerId;
      params._id = answerId;

      return ApiClientService.patch(this.getPath(`/${auditId}/answer/${answerId}/update`), params);
    }

    removeAnswer = (params) => ApiClientService.delete(this.getPath(`/${params?.id}/answer/${params?.answerId}/remove`));

    getAllSections = ({auditId, preview}) => {
      if (preview) {
        return ApiClientService.post(this.getPath(`/${auditId}/preview`));
      }
      return ApiClientService.post(this.getPath(`/${auditId}/questions-answers`));
    };

    addIssues = ({auditId, questionId, ...params}) => ApiClientService.post(this.getPath(`/${auditId}/questions/${questionId}/issues-tasks-all`), params);

    addAndRemoveTasks = ({auditId, questionId, sectionId,...params}) => ApiClientService.patch(this.getPath(`/${auditId}/sections/${sectionId}/questions/${questionId}/issues-tasks`), params);

    updateName = ({auditId, questionId, sectionId,...params}) => ApiClientService.patch(this.getPath(`/${auditId}/sections/${sectionId}/questions/${questionId}/update-issue-name`), params);

    updateTaskName = ({auditId, questionId, sectionId,...params}) => ApiClientService.patch(this.getPath(`/${auditId}/sections/${sectionId}/questions/${questionId}/update-task-name`), params);

    updateDescription = ({auditId, questionId, sectionId,...params}) => ApiClientService.patch(this.getPath(`/${auditId}/sections/${sectionId}/questions/${questionId}/update-issue-description`), params);

    manageEvidence = ({auditId, questionId, sectionId,...params}) => ApiClientService.patch(this.getPath(`/${auditId}/sections/${sectionId}/questions/${questionId}/evidences`), params);

    manageNote = ({auditId, questionId, sectionId,...params}) => ApiClientService.patch(this.getPath(`/${auditId}/sections/${sectionId}/questions/${questionId}/note`), params);

    manageImages = ({auditId, questionId, sectionId,...params}) => ApiClientService.patch(this.getPath(`/${auditId}/sections/${sectionId}/questions/${questionId}/note-images`), params);

    finishAudit = ({auditId, ...params}) => ApiClientService.patch(this.getPath(`/${auditId}/finished`), params);

    executeAudit = ({auditId, use_previous = false,...params}) => ApiClientService.patch(this.getPath(`/${auditId}/execute?use_previous=${use_previous}`), params);

    addMetadataValue = ({auditId, ...params}) => ApiClientService.post(this.getPath(`/${auditId}/cover/update`), params);

    reOpen = ({auditId, ...params}) => ApiClientService.patch(this.getPath(`/${auditId}/reopen`), params);
}

export default new AuditorAuditsApiService('/ms-core/api/audits');
