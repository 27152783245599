/**
 * @author Geider Arevalo
 * @version v0.0.1
 * @date  15/11/2021
 */
import React, {memo, useMemo} from 'react';
import PropTypes from 'prop-types';
import styles from './index.less';
import { Card, Tag} from 'antd';
import {useTranslation} from 'react-i18next';
import classNames from 'classnames';
import Incidents from '@/modules/audits/components/WrapperRunAudit/components/ManageAuditContent/components/WrapperFieldType/Incidents';

const Select = ({question, isIncidence, title, idx}) => {
  const {t} = useTranslation('templates');

  const data = useMemo(() => {
    let value = question?.answer?.templateAnswer?.select;
    const options = question?.select?.options;
    if(value && options) {
      if (typeof value === 'string') {
        value = [question?.answer?.templateAnswer?.select];
      }
      return options?.filter((el) => value.some((x) => x === el?._id));
    }
    return [];
  }, [question?.answer?.templateAnswer?.select, question?.select?.options]);

  if(isIncidence){
    return <Card
      key={idx}
      className={classNames(styles.templateSelectCard, idx !==0 && 'mt-2')}
      bodyStyle={{padding: '6px'}}
    >
      <div>
        {title}
      </div>
      <div className={'font-bold'}>{question?.name || question?.label}</div>
      <div className="flex w-full mt-2">
        {data?.map(item => <Tag style={{ minWidth: '34px' }} className={'leading-8 rounded flex items-center justify-center'} key={item?._id} color={item?.color}>{t(item?.label)}</Tag>)}
      </div>
    </Card>;
  }

  return (
    <Card
      className={styles.templateSelectCard}
      bodyStyle={{padding: '6px'}}
      id={'question-view-' + question?._id}
    >
      <div className="flex items-center justify-between">
        <div className="mb-3">
          {question?.name}
        </div>
      </div>

      <div className="flex w-full">
        {!data || data?.length === 0 ?
          'N/A' :
          data?.map(item =>
            <Tag
              className={'leading-8 rounded flex items-center justify-center'}
              key={item?._id}
              color={item?.color}
              style={{ minWidth: '100px' }}
            >
              {t(item?.label)}
            </Tag>)}
      </div>
      <Incidents data={question?.issuesAudit} question={question} hasIssues={question?.issuesAudit?.length}/>
    </Card>
  );
};

Select.propTypes = {
  question: PropTypes.object,
  title: PropTypes.string,
  idx: PropTypes.number,
  isIncidence: PropTypes.bool,
};
Select.defaultProps = {
  question: {},
};

export default memo(Select);
