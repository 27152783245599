import React, {useEffect, useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {Menu, Dropdown, /*Divider*/} from 'antd';
import {CaretRightOutlined} from '@ant-design/icons';
import classNames from 'classnames';
import style from './index.less';
import SpaceAvatar from '@/components/SpaceAvatar';
import {useMutation} from 'react-query';
import Authorization from '@/modules/authentication/services/Auth.api.service';
import {useSpaceList} from '@/hooks/useSpace';
import {useHistory} from 'react-router-dom';
import {useSpace} from '@/contexts/SpaceContext';
import { Scrollbars } from 'react-custom-scrollbars';

const HeaderMenu = ({mini}) => {
  const { t } = useTranslation('common');
  const {space, refetch} = useSpace();
  const {data, isLoading} = useSpaceList();
  const [spaceList, setSpaceList] = useState([]);
  const {mutateAsync} = useMutation(Authorization.spaceAccess);
  const {push} = useHistory();

  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    !isLoading && data && setSpaceList(data);
  }, [data, isLoading]);

  const handleClickItem = useCallback( async (space) =>{
    const data = await mutateAsync(space?.id);
    push({pathname: '/impersonate', state: {token: data?.accessToken, space: space?.id}});
  }, [mutateAsync, push]);

  const menu = (
    <div className={classNames('ml-4 min-w-64 justify-center fixed', style.wrapperMenu)}>
      <Menu className={classNames('min-w-64', style.shadowBox, style.noPaddingBottom, 'customMenu')}>
        {spaceList && spaceList?.length > 0 && <Menu.ItemGroup className={'w-full h-full justify-items-center items-center'} title={!mini && t('spaces.changeSpace')}>
          <Scrollbars
            renderTrackHorizontal={props => <div {...props} style={{display: 'none'}} className="track-horizontal"/>}
            disableHorizontalScrolling
            autoHide
            autoHeight
            autoHeightMax={600}
          >
            <div className={'max-h-48 w-full custom-wrapper-scroll'}>
              {spaceList.map((el, i) =>
                <Menu.Item
                  className={classNames('mb-2 m-0 w-full h-auto flex items-center max-w-64 whitespace-normal custom-item-li', style.menuNotCollapsed)}
                  onClick={() => handleClickItem(el)} key={`space-${i}`}>
                  <SpaceAvatar space={el} className={'mr-2'} shape="circle" size={32}/>
                  <div className="items-center justify-items-center block leading-5 space-item-content">
                    <h3 className="m-0 p-0 leading-none block text-xs space-item-text">{el?.name}</h3>
                    <span className="leading-none text-xs opacity-75 space-item-text">{el?.email}</span>
                  </div>
                </Menu.Item>)}
            </div>
          </Scrollbars>
        </Menu.ItemGroup>}
      </Menu>
    </div>
  );

  const content = (
    <div className={classNames('p-2 flex justify-items-center items-center text-black', style.dropdownColor)}>
      <SpaceAvatar space={space} className="mr-3" size={32} />
      <div className="text-xs justify-items-center items-center">
        <h3 className="m-0 leading-none text-black text-sm">{space?.name}</h3>
        <span className="leading-none text-xs opacity-75">{space?.email}</span>
      </div>
      <CaretRightOutlined className={'mr-2 ml-auto text-xs'} hidden={spaceList?.length < 2}/>
    </div>
  );

  const contentMini = (
    <div className={'ml-2 m-2 text-white flex items-center justify-center'}>
      <SpaceAvatar space={space} className={'p-auto'} size="default"/>
    </div>
  );

  if (spaceList?.length === 0) {
    return (
      <div className="cursor-default">
        {mini ? contentMini : space?.name === 'ROOT_SPACE' ? null : content}
      </div>
    );
  }

  return (
    <Dropdown
      overlayClassName={classNames('justify-items-center', !mini ? style.leftPosition : style.leftPositionMini)}
      overlay={menu}
      getPopupContainer={() => document.getElementById('scroll-bars')}
      placement={mini ? 'topCenter' : 'topRight'}
      trigger={['click']}
    >
      <a className="ant-dropdown-link">
        {mini ? contentMini : content}
        {/*<Divider className="m-0 bg-primary-1"/>*/}
      </a>
    </Dropdown>);
};

HeaderMenu.propTypes = {
  mini: PropTypes.any,
  mode: PropTypes.string,
  theme: PropTypes.string,
};

export default HeaderMenu;
