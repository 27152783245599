import {DoubleLeftOutlined, PauseOutlined} from '@ant-design/icons';
import {i18n} from '@/settings';

export const CACHE_KEY_LIST_TASKS = 'tasks';
export const CACHE_KEY_TASKS = 'tasks-one';
export const ROUTE_PATH_TASKS = '/tasks';
export const TRANSLATION_PREFIX_TASKS = 'tasks';

export const options = [
  {
    title: 'options',
    action: 'options',
    style: {minWidth: 120},
    disabled: true
  },
  {
    title: 'details',
    action: 'details',
    style: {minWidth: 120},
    permissions:['USER:READ']
  }
];

export const STATUS_TASK = {
  toDo: 'toDo',
  running: 'running',
  complete: 'complete',
};

export  const STATUS_TASK_APPEARANCE = {
  toDo: {color: '#DB6307'},
  running: {color: '#096dd9'},
  complete: {color: '#389e0d'},
};

export const PRIORITY_APPEARANCE = {
  High: {color: '#EF0000', icon: <DoubleLeftOutlined rotate={90}/>},
  Medium: {color: '#DB6307', icon: <PauseOutlined rotate={90}/>},
  Low: {color: '#0083D7', icon: <DoubleLeftOutlined rotate={270}/>},
  high: {color: '#EF0000', icon: <DoubleLeftOutlined rotate={90}/>},
  medium: {color: '#DB6307', icon: <PauseOutlined rotate={90}/>},
  low: {color: '#0083D7', icon: <DoubleLeftOutlined rotate={270}/>},
};

export const OPTIONS_STATUS = [
  {
    title: i18n.t('tasks:status.toDo'),
    action: STATUS_TASK.toDo,
    style: {minWidth: 150}
  },
  {
    title: i18n.t('tasks:status.inProgress'),
    action: STATUS_TASK.inProgress,
  },
  {
    title: i18n.t('tasks:status.complete'),
    action: STATUS_TASK.complete,
  },
  {
    title: i18n.t('tasks:status.notComplete'),
    action: STATUS_TASK.notComplete,
  },
  {
    title: i18n.t('tasks:status.notDone'),
    action: STATUS_TASK.notDone,
  },
];
