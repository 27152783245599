/**
 * @author Ricardo Comendador Escalona
 * @version v0.0.1
 * @date  15/11/2021
 */
import React, {memo, useCallback, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import styles from './index.less';
import { Card, Input/*, Button, Divider*/ } from 'antd';
import TemplateDetailsFooterActions from '@/modules/templates/components/TemplateDetailsFooterActions';
import {useCrudRunAudit} from '@/modules/auditorAudits/hooks/useAuditorAudits';
import {useParams} from 'react-router-dom';
import Editor from '@/components/Editor';
import {useAuditData} from '@/modules/auditorAudits/hooks/useAuditData';

const TemplateDetailText = ({sectionId, question, isCover, runAudit}) => {
  const {currentAudit} = useAuditData();

  const currentValue = useMemo(() =>
    currentAudit?.coverFields?.find(x => x?._id === question?._id)?.value,
  [currentAudit?.coverFields, question?._id]);

  const {createMetadataValue} = useCrudRunAudit();
  const {create, update} = useCrudRunAudit();
  const {id} = useParams();

  const [text, setText] = useState((runAudit && !isCover) ? question?.answer?.templateAnswer?.text || '' : isCover && currentValue || '');
  const [paragraph, setParagraph] = useState((runAudit && !isCover) ? question?.answer?.templateAnswer?.paragraph || '' : isCover && currentValue || '');

  const onSave = useCallback(async () => {
    if (runAudit && !isCover) {
      const templateAnswer = {
        type: question?.text?.answerType === 'PARAGRAPH' ? 'paragraph' : 'text',
        text,
        paragraph,
      };
      if (!question?.answer?._id) {
        await create({id, section: sectionId, question: question?._id, templateAnswer});
      } else await update({
        answerId: question?.answer?._id,
        id,
        section: sectionId,
        question: question?._id,
        templateAnswer
      });
    }
    if (isCover) {
      const coverFields = {
        _id: question?._id,
        value: text
      };

      await createMetadataValue({auditId: id, coverFields});
    }
  }, [create, createMetadataValue, id, isCover, paragraph, question?._id, question?.answer?._id, question?.text?.answerType, runAudit, sectionId, text, update]);

  return (
    <Card
      className={styles.templateTextCard}
      id={'question-view-'+question?._id}
    >
      {/*<div style={{color: 'rgb(235, 0, 39)'}}>*/}
      {/*  {question?.controlParameter ? '*' : null}*/}
      {/*</div>*/}
      <div className="mb-3">
        {question?.name || question?.label}
      </div>
      <div className="custom-form-big">

        {question?.text?.answerType === 'PARAGRAPH'
          ? <Editor value={paragraph} onChange={e => setParagraph(e?.target?.value)} onPressEnter={e => {
            e.preventDefault();
            e.stopPropagation();
            if (runAudit) {
              onSave().then();
            }
          }}
          onBlur={onSave}/>
          : <Input className="mb-3" value={text} onChange={e => setText(e?.target?.value)} onPressEnter={e => {
            e.preventDefault();
            e.stopPropagation();
            if (runAudit) {
              onSave().then();
            }
          }}
          onBlur={onSave}
          />}

      </div>

      <TemplateDetailsFooterActions question={question} isCover={isCover} sectionId={sectionId} runAudit={runAudit} />
    </Card>
  );
};

TemplateDetailText.propTypes = {
  runAudit: PropTypes.bool,
  sectionId: PropTypes.string,
  question: PropTypes.object,
  isCover: PropTypes.bool,
};
TemplateDetailText.defaultProps = {
  question: {},
};

export default memo(TemplateDetailText);


/**
 * cellRender
 * */
/**
 * This is not a components,
 * it is a function to render the Detail Audit Card components in a detail drawer
 * @param {String} value this is the value of the dataIndex of the table (we will ignored in this case)
 * @param {Object} entity this is the row instance
 * */
export const renderTemplateDetailText = (value, entity) => {
  return (
    <TemplateDetailText entity={entity}/>
  );
};
