/**
 * @author Geider Arevalo
 * @since v0.0.1
 * @date 22/11/21
 */
import React, {memo, useMemo} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {useTranslation} from 'react-i18next';
import { Card, Statistic, Row, Col, Typography } from 'antd';
import { CaretUpOutlined } from '@ant-design/icons';
// import bg from '@/assets/images/bg-sidebar.svg';
import styles from './index.less';
import {useAuditData} from '@/modules/auditorAudits/hooks/useAuditData';
import {useUser} from '@dofleini/security';

const HeaderCover = ({countData}) => {
  const {t} = useTranslation('audits');
  const {currentAudit} = useAuditData();
  const { user } = useUser();

  const percentCompleted = useMemo(() => Number((currentAudit?.finalScore * 100 / currentAudit?.totalScore  || 0) - (currentAudit?.audit?.deductions?.[0]?.percentage || 0)).toFixed(1),
    [currentAudit?.audit?.deductions, currentAudit?.finalScore, currentAudit?.totalScore]);

  return (
    <Card
      cover={<div
        className={'w-full h-full relative'}
        style={{
          // backgroundImage: `url('${bg}')`,
          backgroundSize: 'cover',
          borderRadius: '4px 4px 0 0',
          // backgroundColor: '#D9D9D9',
          backgroundImage: 'linear-gradient(to right, #fff, #4db7f9)',

        }}>
        <div className={'h-full p-4'}>
          <span style={{bottom: '2rem'}} className={'absolute top-auto'}>
            <Typography.Title level={5}>{currentAudit?.template?.name}</Typography.Title>
            <div className={'inline-flex'}>
              <span
                className={'mr-2'}
                style={{ color: '#4db7f9' }}
              >{currentAudit?.client?.name}</span>
              {`> ${currentAudit?.establishment?.name}`}
            </div>
          </span>
        </div>
      </div>}
      className={classNames('bg-no-repeat bg-center', styles.cardStyle)}
      bodyStyle={{padding: 0}}
    >
      <Row className={'text-black flex-wrap'}>
        {user?.reportType !== 'issues' && <Col lg={8} md={12} sm={24} className={'p-4 w-full'}
          style={{backgroundColor: '#4db7f9'}}
        >
          <span>{t('InspectionScore')}</span>
          <div className={'flex justify-between mt-2 flex-wrap'}>
            <div className="flex items-center justify-center">
              <span className="mr-2">{t('score')}:</span>
              <Statistic className={'font-bold'} valueStyle={{fontSize: '22px'}} value={percentCompleted} prefix={
                <CaretUpOutlined rotate={currentAudit?.valueGoal > percentCompleted ? 180 : 0}/>
              } suffix={'%'}/>
              {currentAudit?.deductions?.length > 0 &&
                  <Statistic className="ml-2" valueStyle={{fontSize: '14px', color: '#C60022'}}
                    value={`(-${currentAudit?.deductions?.[0]?.percentage || 0}%)`}/>}
            </div>
            <div className="flex items-center justify-center">
              <span className="mr-2">{t('valueGoal')}:</span>
              <Statistic className={'font-bold'} valueStyle={{fontSize: '22px'}} value={currentAudit?.valueGoal || 0}
                suffix={'%'}/>
            </div>
          </div>
        </Col>}
        <Col lg={user?.reportType !== 'issues' ? 8 : 6} md={12} sm={24} className={'p-4 w-full'}>
          <span>{t('IncidentsFound')}</span>
          <Statistic
            className={'mt-2 font-bold'}
            valueStyle={{fontSize: '18px', color: 'black'}}
            value={countData?.countIncidences || currentAudit?.totalIssuesCount}
          />
        </Col>
        {user?.reportType === 'issues' && <Col lg={6} md={12} sm={24} className={'p-4 w-full'}>
          <span>{t('IncidentsHighRisk')}</span>
          <Statistic
            className={'mt-2 font-bold'}
            valueStyle={{fontSize: '18px', color: '#EF0000'}}
            value={currentAudit?.highRiskIssuesCount}
          />
        </Col>}
        {user?.reportType === 'issues' && <Col lg={6} md={12} sm={24} className={'p-4 w-full'}>
          <span>{t('IncidentsMedium')}</span>
          <Statistic
            className={'mt-2 font-bold'}
            valueStyle={{fontSize: '18px', color: '#DB6307'}}
            value={currentAudit?.mediumIssuesCount}
          />
        </Col>}
        {user?.reportType === 'issues' && <Col lg={6} md={12} sm={24} className={'p-4 w-full'}>
          <span>{t('IncidentsLow')}</span>
          <Statistic
            className={'mt-2 font-bold'}
            valueStyle={{fontSize: '18px', color: '#dbaa07'}}
            value={currentAudit?.lowIssuesCount}
          />
        </Col>}
        {/*<Col lg={8} md={24} sm={24} className={'p-4 w-full'}>*/}
        {/*  <span>{t('createdTasks')}</span>*/}
        {/*  <Statistic*/}
        {/*    className={'mt-2 font-bold'}*/}
        {/*    valueStyle={{fontSize: '18px'}}*/}
        {/*    value={countData?.countTasks}*/}
        {/*  />*/}
        {/*</Col>*/}
      </Row>
    </Card>
  );
};

HeaderCover.propTypes = {
  countData: PropTypes.object,
};

export default memo(HeaderCover);
