import React, {createContext, useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {enquireScreen} from 'enquire-js';

const ResponsiveContext = createContext();


let _isMobile;
let _isTablet;
let _isTSmallLaptop;

const mobileQuery = 'only screen and (max-width: 992px)';
const smallLaptopQuery = 'only screen and (max-width: 1366px) and (orientation: landscape)';

enquireScreen((b) => {
  _isTablet = b;
}, mobileQuery);


function ResponsiveProvider({children}) {
  const [isMobile, setMobile] = useState(_isMobile);
  const [isTablet, setTablet] = useState(_isTablet);
  const [isSmallLaptop, setSmallLaptop] = useState(_isTSmallLaptop);

  useEffect(() => {
    enquireScreen((b) => {
      setMobile(!!b);
    });
  }, []);

  useEffect(() => {
    enquireScreen((b) => {
      setTablet(!!b);
    }, mobileQuery);
  }, []);

  useEffect(() => {
    enquireScreen((b) => {
      setSmallLaptop(!!b);
    }, smallLaptopQuery);
  }, []);

  return (
    <ResponsiveContext.Provider value={{
      isMobile: isMobile,
      isTablet: isTablet,
      isSmallLaptop
    }}>
      {children}
    </ResponsiveContext.Provider>
  );
}

function useResponsive() {
  const context = useContext(ResponsiveContext);
  if (context === undefined) {
    throw new Error('useResponsive must be used within a ResponsiveProvider');
  }
  const {
    isMobile,
    isTablet,
    isSmallLaptop
  } = context;

  return {
    isMobile,
    isTablet,
    isSmallLaptop
  };
}

ResponsiveProvider.propTypes = {
  isMobile: PropTypes.bool,
  children: PropTypes.any
};


export {ResponsiveProvider, useResponsive};
