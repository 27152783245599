/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 16/1/22
 */
import React, {memo} from 'react';
import PropTypes from 'prop-types';
import styles from './index.less';

const AuditNameCell = ({value, initialValues}) => {

  return (
    <div className="flex items-center justify-start">
      <div className={'row-flex'}>
        <span className={styles.detailTemplateTitle}> {value?.name}</span>
        <p className={'font-normal italic text-primary mb-1 lg:mb-0'}>
          {initialValues?.service?.name}
        </p>
      </div>
    </div>
  );
};

AuditNameCell.propTypes = {
  value: PropTypes.object,
  initialValues: PropTypes.object,
};
AuditNameCell.defaultProps = {
  entity: {},
};

export default memo(AuditNameCell);


/**
 * cellRender
 * */
/**
 * This is not a components,
 * it is a function to render the AuditNameCell components in a table cell
 * @param {string} value this is the row instance
 * @param {object} form this is the row instance
 * @param {object} initialValues this is the row instance
 * */
export const renderAuditName = (value, form, initialValues) => {

  return (
    <AuditNameCell value={value} initialValues={initialValues}/>
  );
};

