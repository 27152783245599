import React, { memo } from 'react';
import Loading from '@/components/Loading';

const PageLoader = () => {
  return (
    <div  className="flex w-full h-full items-center justify-center">
      <Loading/>
    </div>
  );
};

export default memo(PageLoader);
