import {renderAuditSections} from '@/modules/auditorAudits/components/AuditorAuditSectionsDrawer';
import {renderAuditName} from '@/modules/auditorAudits/components/AuditNameCell';
import {renderAuditEstablishment} from '@/modules/auditorAudits/components/AuditEstablishmentCell';

export const fields = [
  {
    key: 'client',
    onlyView: true,
    renderView: renderAuditName
  },
  {
    key: 'establishment',
    onlyView: true,
    renderView: renderAuditEstablishment
  },
  {
    key: 'answers',
    className: 'mix-sections-cmp',
    onlyView: true,
    renderView: renderAuditSections,
  },
];
