/**
 * @author Ricardo Comendador Escalona
 * @version v0.0.1
 * @date  15/11/2021
 */
import React, {memo, useState, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import styles from './../TemplateDetailsAuditor/index.less';
import { Card, DatePicker } from 'antd';
import moment from 'moment';
import TemplateDetailsFooterActions from '@/modules/templates/components/TemplateDetailsFooterActions';
import {useUpdateAudit} from '@/modules/auditorAudits/hooks/useAuditorAudits';
import {useAuditData} from '@/modules/auditorAudits/hooks/useAuditData';
import {useParams} from 'react-router-dom';

const { RangePicker } = DatePicker;

const TemplateDetailsAuditDateRange = ({question, runAudit, isCover}) => {

  const {onUpdate} = useUpdateAudit();
  const {id} = useParams();

  const {currentAudit} = useAuditData();

  const [value, setValue] = useState([moment(), moment()]);

  useEffect(() => {
    if (currentAudit?.date?.length)
      setValue(currentAudit?.date?.map(d => moment(d)));
  }, [currentAudit?.date]);

  const onSave = useCallback(async (value) => {
    if (runAudit) {
      await onUpdate({_id: id, date: value});
    }
  }, [onUpdate, id, runAudit]);

  return (
    <Card
      className={styles.templateAuditorCard}
    >
      <div className="mb-3">
        {question?.name || question?.label}
      </div>
      <div className="custom-form-big">
        {runAudit
          ? <RangePicker
            defaultValue={value}
            value={value}
            dateFormat={'DD-MM-YYYY'}
            // disabledDate={disabledDate}
            onChange={val => {
              setValue(val);

              if (runAudit) {
                onSave(val).then();
              }
            }}
            format="DD-MM-YYYY"
          />
          : <RangePicker
            value={value}
            onChange={val => {
              setValue(val);
            }}
            format="DD-MM-YYYY"
            // disabledDate={disabledDate}
          />
        }
      </div>

      <TemplateDetailsFooterActions question={question} isCover={isCover} />
    </Card>
  );
};

TemplateDetailsAuditDateRange.propTypes = {
  question: PropTypes.object,
  isCover: PropTypes.bool,
  runAudit: PropTypes.bool,
};
TemplateDetailsAuditDateRange.defaultProps = {
  question: {},
};

export default memo(TemplateDetailsAuditDateRange);


/**
 * cellRender
 * */
/**
 * This is not a components,
 * it is a function to render the Detail Audit Card components in a detail drawer
 * @param {String} value this is the value of the dataIndex of the table (we will ignored in this case)
 * @param {Object} entity this is the row instance
 * */
export const renderTemplateDetailsAuditDateRange = (value, entity) => {
  return (
    <TemplateDetailsAuditDateRange entity={entity}/>
  );
};
