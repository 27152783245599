import {useEffect, useState, useMemo} from 'react';
import {ApiClientService} from '@dofleini/security';
import {USER_PROFILE} from '@/modules/authentication/hooks/useUsers';
import {useLocation} from 'react-router-dom';

const removeUser = () => {
  window.sessionStorage.removeItem(ApiClientService.ACCESS_TOKKEN_KEY);
  window.localStorage.removeItem(ApiClientService.ACCESS_TOKKEN_KEY);
  window.localStorage.removeItem(USER_PROFILE);
};

export const useImpersonateFlow = () => {
  const [redirected, setRedirected] = useState(false);
  const queryParams = useLocation();
  const redirect = useMemo(() => queryParams?.state?.redirect || '/change-space', [queryParams]);

  useEffect(() => {
    if (!redirected) {
      if (queryParams?.state?.space) {
        if (ApiClientService.getSpace() !== queryParams?.state?.space) {
          removeUser();
          ApiClientService.setSpace(queryParams?.state?.space);
        }
      }
      if (queryParams?.state?.token) {
        removeUser();
        ApiClientService.setToken(queryParams?.state?.token);
      }
      setRedirected(true);
      window.location.href = redirect;
    }
  }, [queryParams, redirect, redirected]);
};
