/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 22/11/21
 */
import React, {memo, useMemo} from 'react';
import PropTypes from 'prop-types';
import {CaretRightOutlined} from '@ant-design/icons';
import styles from './index.less';
import classNames from 'classnames';
import {useTranslation} from 'react-i18next';

const SectionTitle = ({section, runAudit, activeKey, isCover}) => {
  const {t} = useTranslation('templates');

  const sectionTitle = useMemo(() => {
    if (section?.title === 'Introduction') {
      return t(section?.title);
    }
    return section?.title;
  }, [section?.title, t]);

  return (
    <div className={runAudit ? isCover ? styles.sectionHeader : styles.sectionPanelHeader : styles.sectionHeader}>
      <div className="flex items-center justify-start">
        {runAudit && <>{!activeKey?.length ? <CaretRightOutlined /> : <CaretRightOutlined rotate={90} />}</>}
        <div className={classNames(styles.title, runAudit ? 'ml-3' : '')}>{sectionTitle}</div>
      </div>
      {runAudit && !isCover && (
        <div
          className="font-bold header-score"
          style={{fontSize: '1.2rem'}}
        >
          {`${section?.finalScore || 0} / ${section?.totalScore || 0} (${Number(section?.finalScore * 100 / section?.totalScore || 0).toFixed(1)}%)`}
        </div>
      )}
    </div>
  );
};

SectionTitle.propTypes = {
  section: PropTypes.object,
  runAudit: PropTypes.bool,
  isCover: PropTypes.bool,
  activeKey: PropTypes.array,
};

export default memo(SectionTitle);
