/**
 * @author Ricardo Comendador Escalona
 * @version v0.0.1
 * @date  15/11/2021
 */
import React, {memo, useMemo} from 'react';
import PropTypes from 'prop-types';
import styles from './../Auditor/index.less';
import classNames from 'classnames';
import { Card} from 'antd';
import {useTranslation} from 'react-i18next';

const Location = ({question, entity}) => {
  const {t} = useTranslation('templates');
  const data = useMemo(() => entity?.coverFields?.find(f => f?._id === question?._id), [entity?.coverFields, question?._id]);

  return (
    <Card
      className={classNames(styles.templateAuditorCard)}
    >
      <div>{t(data?.label) || question?.name || question?.label}</div>
      <span>{data?.value || question?.value || 'N/A'}</span>
    </Card>
  );
};

Location.propTypes = {
  entity: PropTypes.object,
  question: PropTypes.object,
};
Location.defaultProps = {
  question: {},
};

export default memo(Location);


/**
 * cellRender
 * */
/**
 * This is not a components,
 * it is a function to render the Detail Audit Card components in a detail drawer
 * @param {String} value this is the value of the dataIndex of the table (we will ignored in this case)
 * @param {Object} entity this is the row instance
 * */
export const renderTemplateDetailsLocation = (value, entity) => {
  return (
    <Location entity={entity}/>
  );
};
