/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 23/3/22
 */
import React, {memo, Suspense} from 'react';
import {RouteLoader} from '@dofleini/security';
import PageLoader from '@/components/PageLoader';
import WrapperRunAudit from '@/modules/audits/components/WrapperRunAudit';
import {AuditDataProvider} from '@/modules/auditorAudits/hooks/useAuditData';

export const routes = {
  ShareAuditReportRoutes: {
    exact: false,
    path: '/share-audit-report/:id',
    component: WrapperRunAudit
  }
};

const ShareAuditReport = () => {

  return (
    <AuditDataProvider>
      <Suspense fallback={<PageLoader/>}>
        <RouteLoader routes={routes} notfoundRedirect={'/'}/>
      </Suspense>
    </AuditDataProvider>
  );
};

ShareAuditReport.propTypes = {};

export default memo(ShareAuditReport);
