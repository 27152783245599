/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 7/1/22
 */
import React, {createContext, useContext, useState, useEffect} from 'react';

const AuditDataContext = createContext({
  currentAudit: null,
  setCurrentAudit: val => val,
  dataIntro: [],
  setDataIntro: val => val,
  dataIntroIndexed: [],
  setDataIntroIndexed: val => val,
  dataSections: [],
  setDataSections: val => val,
  dataSectionsIndexed: [],
  setDataSectionsIndexed: val => val,
  saving: false,
  setSaving: val => val
});

const useAuditData = () => {
  const context = useContext(AuditDataContext);
  if (context === undefined) {
    throw new Error('useAuditData must be used within a AuditDataProvider');
  }

  return context;
};

const URL = 'https://ip.nf/me.json';

const AuditDataProvider = ({ ...props }) => {

  const [currentAudit, setCurrentAudit] = useState(null);
  const [dataIntro, setDataIntro] = useState([]);
  const [dataIntroIndexed, setDataIntroIndexed] = useState([]);
  const [dataSections, setDataSections] = useState([]);
  const [dataSectionsIndexed, setDataSectionsIndexed] = useState([]);
  const [locationData, setLocationData] = useState('');
  const [saving, setSaving] = useState(false);

  const fetchData = async () => {
    try {
      // eslint-disable-next-line no-undef
      await fetch(URL)
        .then((res) => res?.json())
        .then(result => setLocationData(`${result?.ip?.city}, ${result?.ip?.country}`))
        .catch();
    } catch (err) {
      console.log(err);
    }

  };

  useEffect(() => {
    fetchData()?.then();
  }, []);

  return (
    <AuditDataContext.Provider
      value={{
        currentAudit,
        setCurrentAudit,
        dataIntro,
        setDataIntro,
        dataIntroIndexed,
        setDataIntroIndexed,
        dataSections,
        setDataSections,
        dataSectionsIndexed,
        setDataSectionsIndexed,
        locationData,
        setLocationData,
        saving,
        setSaving
      }}
      {...props}
    />
  );
};

export { useAuditData, AuditDataProvider };

