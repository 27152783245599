/**
 * @author Angel Labrada Massó
 * @version v0.0.1
 * @date  03/02/2022
 */
import React, {memo, useCallback, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import styles from './index.less';
import { Card } from 'antd';
import {useCrudRunAudit} from '@/modules/auditorAudits/hooks/useAuditorAudits';
import {useParams} from 'react-router-dom';
import Editor from '@/components/Editor';
import {useAuditData} from '@/modules/auditorAudits/hooks/useAuditData';

const TemplateDetailsRichText = ({question, runAudit}) => {

  const {createMetadataValue} = useCrudRunAudit();
  const {id} = useParams();

  const {currentAudit} = useAuditData();

  const [value, setValue] = useState(currentAudit?.coverFields?.find(x => x?._id === question?._id)?.value);

  useEffect(() => {
    if (currentAudit?.coverFields?.find(x => x?._id === question?._id)?.value) {
      setValue(currentAudit?.coverFields?.find(x => x?._id === question?._id)?.value);
    }
  }, [currentAudit?.coverFields, question?._id]);

  const onSave = useCallback(async () => {
    if (runAudit) {
      const coverFields = {
        _id: question?._id,
        value
      };

      await createMetadataValue({auditId: id, coverFields});
    }
  }, [createMetadataValue, id, question?._id, runAudit, value]);

  return (
    <Card
      className={styles.templateTextCard}
      id={'question-view-'+question?._id}
    >
      <div className="mb-3">
        {question?.name || question?.label}
      </div>
      <div className="custom-form-big">

        {runAudit
          ? <Editor key={question?._id} value={value} onChange={e => setValue(e?.target?.value)} onPressEnter={() => {
            if (runAudit) {
              onSave().then();
            }
          }}
          onBlur={onSave}/>
          : <Editor />
        }
      </div>
    </Card>
  );
};

TemplateDetailsRichText.propTypes = {
  runAudit: PropTypes.bool,
  sectionId: PropTypes.string,
  question: PropTypes.object,
  isCover: PropTypes.bool,
  section: PropTypes.object,
};
TemplateDetailsRichText.defaultProps = {
  question: {},
};

export default memo(TemplateDetailsRichText);


/**
 * cellRender
 * */
/**
 * This is not a components,
 * it is a function to render the Detail Audit Card components in a detail drawer
 * @param {String} value this is the value of the dataIndex of the table (we will ignored in this case)
 * @param {Object} entity this is the row instance
 * */
export const renderTemplateDetailSummary = (value, entity) => {
  return (
    <TemplateDetailsRichText entity={entity}/>
  );
};
