/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 21/10/21
 */
import React, {createContext, useContext, useState, useMemo, useCallback} from 'react';
import {useQuery} from 'react-query';
import _ from 'lodash';
import {useLocalStorage} from '@/hooks/useLocalStorage';

const sortItems = (items) => {
  return items.sort(function (a, b) {
    if (a.name > b.order) {
      return 1;
    }
    if (a.name < b.order) {
      return -1;
    }
    // a must be equal to b
    return 0;
  });
};

export const useCreateBreadcrumbs = ({service, queryKey, itemId, parentKey, isAsync}) => {

  const queryConfig = useMemo(() => {
    return {enabled: !!(service && queryKey && itemId && parentKey && isAsync === true)};
  }, [service, queryKey, itemId, parentKey, isAsync]);

  const [breadcrumbs, setBreadcrumbs] = useState([]);

  const findParents = useCallback(async (id) => {
    const {data} = await service.getOneClient({clientId: id});

    if (data?.[parentKey]) {
      setBreadcrumbs(prevState => ([...prevState, data]));
      return findParents(data?.[parentKey]);
    }
    else setBreadcrumbs(prevState => ([data, ...prevState]));
  }, [parentKey, service]);

  const {isLoading, isError, error} = useQuery([service, queryKey, itemId, parentKey, isAsync], async () => {
    if (!isAsync) {
      let set = new Set(breadcrumbs?.map(x => ({name: x?.name, _id: x?._id, id: x?._id, key: x?._id})).map( JSON.stringify ) );
      const data = Array.from( set ).map( JSON.parse );
      const index = data?.findIndex(x => x?._id === itemId);
      setBreadcrumbs(_.slice(data, 0, index + 1));
    } else {
      await findParents(itemId);
      setBreadcrumbs(prevState => prevState);
      return [];
    }
  }, queryConfig);

  const realData = useMemo(() => {
    let set = new Set(breadcrumbs?.map((x) => ({name: x?.name, _id: x?._id, id: x?._id, key: x?._id})).map( JSON.stringify ) );
    let arr = Array.from( set ).map( JSON.parse )?.map((x,idx) => ({...x, order: idx}));
    return sortItems(arr);
  }, [breadcrumbs]);

  return {isLoading, isError, error, data: isAsync ? realData : []};
};

const AsyncBreadcrumbsContext = createContext({
  breadcrumbs: {},
  setBreadcrumbs: val => val,
  createBreadcrumbs: val => val,
  isLoading: false
});

const useAsyncBreadcrumbs = () => {
  const context = useContext(AsyncBreadcrumbsContext);
  if (context === undefined) {
    throw new Error('useAsyncBreadcrumbs must be used within a AsyncBreadcrumbsProvider');
  }

  return context;
};

const AsyncBreadcrumbsProvider = ({ ...props }) => {
  const [breadcrumbs, setBreadcrumbs] = useLocalStorage('directory-breadcrumbs', []);

  return (
    <AsyncBreadcrumbsContext.Provider
      value={{
        breadcrumbs,
        setBreadcrumbs,
      }}
      {...props}
    />
  );
};

export { useAsyncBreadcrumbs, AsyncBreadcrumbsProvider };
