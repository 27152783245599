/**
 * @author Ricardo Comendador Escalona
 * @version v0.0.1
 * @date  15/11/2021
 */
import React, {memo, useState, useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import styles from './../TemplateDetailsAuditor/index.less';
import {useTranslation} from 'react-i18next';
import classNames from 'classnames';
import { Card, Input } from 'antd';
import MapPinLineIcon from 'remixicon-react/MapPinLineIcon';
import {useAuditData} from '@/modules/auditorAudits/hooks/useAuditData';
import {useCrudRunAudit} from '@/modules/auditorAudits/hooks/useAuditorAudits';
import {useParams} from 'react-router-dom';

const TemplateDetailsLocation = ({question, runAudit}) => {
  const {t} = useTranslation('templates');

  const {currentAudit} = useAuditData();

  const currentValue = useMemo(() =>
    currentAudit?.coverFields?.find(x => x?._id === question?._id)?.value || currentAudit?.establishment?.address,
  [currentAudit?.coverFields, currentAudit?.establishment?.address, question?._id]);

  const [data, setData] = useState(currentValue);

  const {createMetadataValue} = useCrudRunAudit();
  const {id} = useParams();

  const onSave = useCallback(async () => {
    if (runAudit) {
      const coverFields = {
        _id: question?._id,
        value: data
      };

      await createMetadataValue({auditId: id, coverFields});
    }
  }, [createMetadataValue, data, id, question?._id, runAudit]);

  return (
    <Card
      className={classNames(styles.templateAuditorCard, styles.templateLocationCard)}
    >
      <div className="mb-3">
        {question?.name || question?.label}
      </div>
      <div className="custom-form-big">

        {runAudit
          ?
          <Input
            key={currentValue}
            defaultValue={currentValue}
            value={data}
            onChange={e => setData && setData(e?.target?.value)} onPressEnter={e => {
              e.preventDefault();
              e.stopPropagation();
              if (runAudit) {
                onSave().then();
              }
            }}
            onBlur={onSave}
            placeholder={t('details.placeholderLocation')}
            suffix={
              <div className={'flex flex-row justify-center items-center'}>
                <MapPinLineIcon size="18px" color="rgba(0, 0, 0, 0.25)" />
              </div>
            }
          />
          :
          <Input defaultValue={currentValue || currentAudit?.establishment?.address} />
        }

      </div>
    </Card>
  );
};

TemplateDetailsLocation.propTypes = {
  question: PropTypes.object,
  isCover: PropTypes.bool,
  runAudit: PropTypes.bool,
};
TemplateDetailsLocation.defaultProps = {

};

export default memo(TemplateDetailsLocation);


/**
 * cellRender
 * */
/**
 * This is not a components,
 * it is a function to render the Detail Audit Card components in a detail drawer
 * @param {String} value this is the value of the dataIndex of the table (we will ignored in this case)
 * @param {Object} entity this is the row instance
 * */
export const renderTemplateDetailsLocation = (value, entity) => {
  return (
    <TemplateDetailsLocation entity={entity}/>
  );
};
