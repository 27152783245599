import React, {memo, useMemo} from 'react';
import {getNameInitial} from '@/utils/utils';
import {Avatar} from 'antd';
import PropTypes from 'prop-types';

export const TYPES = {
  STRUCTURE: 'STRUCTURE',
  ESTABLISHMENT: 'ESTABLISHMENT',
};

const SpaceAvatar = ({space, ...props}) => {
  const spaceAvatar = useMemo(() => space?.logo, [space]);

  return (
    <Avatar
      src={spaceAvatar}
      shape="circle"
      style={{backgroundColor: space?.typeStructure === TYPES.STRUCTURE ? '#0083D7' : '#D7CC00FF', minWidth: '32px', minHeight: '32px'}}
      {...props}
    >
      {!spaceAvatar && space?.name && getNameInitial(space?.name)}
    </Avatar>
  );
};

SpaceAvatar.propTypes = {
  space: PropTypes.object,

};

export default memo(SpaceAvatar);
