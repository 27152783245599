/**
 * @author Ricardo Comendador
 * @since v0.0.1
 * @date 28/11/21
 */
import React, {memo, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import classNames from 'classnames';
import {Collapse, Divider} from 'antd';
import {CheckOutlined, UpCircleFilled} from '@ant-design/icons';
import styles from './index.less';

const {Panel} = Collapse;

const BookmarksPreview = ({activeSection, setActiveSection, data, unansweredQuestions, failedQuestions}) => {
  const {t} = useTranslation('templates');

  const menuUnAnsweredQuestion = useMemo(() => {
    return <>
      <Divider />
      <Collapse
        defaultActiveKey={['1']}
        ghost
        expandIcon={({ isActive }) => <UpCircleFilled className="text-lg" rotate={isActive ? 180 : 0} />}
      >
        <Panel header={<span className="font-bold">{t('unansweredQuestions')}</span>} key="1">
          {unansweredQuestions?.map((item, idx) => {
            return (
              <li
                key={idx}
                className={classNames(styles.bookmarksItem, activeSection === 'question-view-'+item?._id ? styles.activeSection : '')}
                onClick={() => {
                  setTimeout(() => {
                    setActiveSection('question-view-'+item?._id);
                  }, 500);
                  if (document.querySelector('#question-view-'+item?._id)) {
                    document.querySelector('#question-view-'+item?._id).scrollIntoView({
                      behavior: 'smooth'
                    });
                  }
                }
                }
              >
                {activeSection?._id === item?._id
                  ? <CheckOutlined style={{ fontSize: '0.75rem' }}/>
                  : <div className={styles.dot}/>
                }
                <span className="ml-2 w-full">{t(item?.title.capitalize())}</span>
              </li>
            );
          }
          )}
        </Panel>
      </Collapse>
    </>;
  }, [activeSection, setActiveSection, t, unansweredQuestions]);

  const menuFailedQuestions = useMemo(() => {
    return <>
      <Divider />
      <Collapse
        defaultActiveKey={['1']}
        ghost
        expandIcon={({ isActive }) => <UpCircleFilled className="text-lg" rotate={isActive ? 180 : 0} />}
      >
        <Panel header={<span className="font-bold">{t('failedQuestions')}</span>} key="1">
          {failedQuestions?.map((item, idx) => {
            return (
              <li
                key={idx}
                className={classNames(styles.bookmarksItem, activeSection === 'question-view-'+item?._id ? styles.activeSection : '')}
                onClick={() => {
                  setTimeout(() => {
                    setActiveSection('question-view-'+item?._id);
                  }, 500);
                  if (document.querySelector('#question-view-'+item?._id)) {
                    document.querySelector('#question-view-'+item?._id).scrollIntoView({
                      behavior: 'smooth'
                    });
                  }
                }
                }
              >
                {activeSection?._id === item?._id
                  ? <CheckOutlined style={{ fontSize: '0.75rem' }}/>
                  : <div className={styles.dot}/>
                }
                <span className="ml-2 w-full">{t(item?.title?.capitalize())}</span>
              </li>
            );
          }
          )}
        </Panel>
      </Collapse>
    </>;
  }, [activeSection, failedQuestions, setActiveSection, t]);


  return (
    <div className="w-full overflow-x-auto">
      <ul className={styles.bookmarksList}>
        <Collapse
          defaultActiveKey={['1']}
          ghost
          expandIcon={({ isActive }) => <UpCircleFilled className="text-lg" rotate={isActive ? 180 : 0} />}
        >
          <Panel header={<span className="font-bold">{t('tableOfContents')}</span>} key="1">
            {data?.map((item, idx) => {
              return (
                <li
                  key={idx}
                  className={classNames(styles.bookmarksItem, activeSection === 'section-'+item?._id ? styles.activeSection : '')}
                  onClick={() => {
                    setTimeout(() => {
                      setActiveSection('section-'+item?._id);
                    }, 500);
                    if (document.querySelector('#section-'+item?._id)) {
                      document.querySelector('#section-'+item?._id).scrollIntoView({
                        behavior: 'smooth'
                      });
                    }
                  }
                  }
                >
                  {activeSection?._id === item?._id
                    ? <CheckOutlined style={{ fontSize: '0.75rem' }}/>
                    : <div className={styles.dot}/>
                  }
                  <span className="ml-2 w-full">{t(item?.title.capitalize())}</span>
                </li>
              );
            }
            )}
          </Panel>
        </Collapse>
        {unansweredQuestions?.length > 0 && menuUnAnsweredQuestion}
        {failedQuestions?.length > 0 && menuFailedQuestions}
      </ul>
    </div>
  );
};

BookmarksPreview.propTypes = {
  activeSection: PropTypes.object,
  setActiveSection: PropTypes.func,
  data: PropTypes.array,
  dataCover: PropTypes.array,
  unansweredQuestions: PropTypes.array,
  failedQuestions: PropTypes.array,
  onScroll: PropTypes.func,
};

export default memo(BookmarksPreview);
