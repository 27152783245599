/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 7/1/22
 */
import React, {memo, useEffect, useState} from 'react';
import classNames from 'classnames';
import {useLocation, useParams} from 'react-router-dom';
import styles from './index.less';
import ManageAuditHeader from '@/modules/audits/components/WrapperRunAudit/components/ManageAuditHeader';
import ManageAuditContent from '@/modules/audits/components/WrapperRunAudit/components/ManageAuditContent';
import {useGetAllSections} from '@/modules/auditorAudits/hooks/useAuditorAudits';
import {useAuditData} from '@/modules/auditorAudits/hooks/useAuditData';
import {useGetOneAuditForPreview} from '@/modules/audits/hooks/useAudits';
import {TYPE_SECTION} from '@/modules/templates/contants/sections';
import {  useAuditReportInternationalization } from '@/contexts/AuditReportInternationalizationContext';
import {i18nSinglePage as i18} from '@/settings';

const WrapperAudit = () => {
  const {id} = useParams();
  const { state} = useLocation();

  const {data: sectionData, isLoading} = useGetAllSections(id, state?.preview);
  const {locale, setLocale} = useAuditReportInternationalization();

  const [dataIntro, setDataIntro] = useState([]);
  const [dataSections, setDataSections] = useState([]);

  const {setDataIntro: setDataIntroCtx, setDataSections: setDataSectionsCtx, setCurrentAudit} = useAuditData();

  const audit = useGetOneAuditForPreview(id, state?.preview);

  useEffect(() => {
    setLocale(audit?.data?.template?.language || locale);
    i18.changeLanguage(audit?.data?.template?.language || locale);
  }, [audit?.data?.template?.language, locale, setLocale]);

  useEffect(() => {
    setCurrentAudit({audit: audit?.data, establishment: audit?.data?.establishment?.name, service: audit?.data?.service?.name, ...audit?.data});
    setDataIntroCtx(dataIntro);
    setDataSectionsCtx(dataSections);
  }, [audit?.data, dataIntro, dataSections, setCurrentAudit, setDataIntroCtx, setDataSectionsCtx]);

  useEffect(() => {
    if (!isLoading) {
      setDataIntro(sectionData?.filter((x) => x.type === TYPE_SECTION.DATA));
      setDataSections(sectionData?.filter((x) => x.type !== TYPE_SECTION.DATA));
    }
  }, [isLoading, sectionData]);

  return (
    <div>
      <div className={classNames('manage-audit-header')}>
        <ManageAuditHeader currentAudit={id} isLoading={isLoading} isPreview={state?.preview} />
      </div>

      <div className={styles.manageAuditContent}>
        <ManageAuditContent isLoading={isLoading} />
      </div>
    </div>
  );
};

WrapperAudit.propTypes = {};

export default memo(WrapperAudit);
