import React, {createContext, useContext, useState} from 'react';

const ShowIncidencesContext = createContext({
  showAllIncidences: false,
  setShowAllIncidences: (val) => val,
});


const useShowIncidences = () => {
  const context = useContext(ShowIncidencesContext);
  if (context === undefined) {
    throw new Error('useShowIncidences must be used within a SummaryFiltersProvider');
  }

  return context;
};

const ShowIncidencesProvider = ({ ...props }) => {
  const [showAllIncidences, setShowAllIncidences] = useState(false);

  return (
    <ShowIncidencesContext.Provider
      value={{
        showAllIncidences,
        setShowAllIncidences,
      }}
      {...props}
    />
  );
};

export { useShowIncidences, ShowIncidencesProvider };

