/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 22/11/21
 */
import React, {memo, useMemo} from 'react';
import PropTypes from 'prop-types';
import TemplateDetailAuditor from '@/modules/templates/components/TemplateDetailsAuditor';
import TemplateDetailsAuditionDate from '@/modules/templates/components/TemplateDetailsAuditionDate';
import TemplateDetailsLocation from '@/modules/templates/components/TemplateDetailsLocation';
import TemplateDetailsNumber from '@/modules/templates/components/TemplateDetailsNumber';
import TemplateDetailsSelect from '@/modules/templates/components/TemplateDetailsSelect';
import TemplateDetailsText from '@/modules/templates/components/TemplateDetailsText';
import TemplateDetailsInSituMeasure from '@/modules/templates/components/TemplateDetailsInSituMeasure';
import TemplateDetailObjective from '@/modules/templates/components/TemplateDetailsObjective';
import TemplateDetailSummary from '@/modules/templates/components/TemplateDetailsSummary';
import TemplateDetailEvolution from '@/modules/templates/components/TemplateDetailsEvolution';
import TemplateDetailsRichText from '@/modules/templates/components/TemplateDetailsRichText';
import TemplateDetailsAuditDateRange from '@/modules/templates/components/TemplateDetailsAuditDateRange';

const WrapperFieldType = ({type, question, sectionId, isCover, runAudit, section}) => {

  const render = useMemo(() => {
    switch (type) {
      case 'audit_date':
        return <TemplateDetailsAuditDateRange sectionId={sectionId} question={question} isCover={isCover} runAudit={runAudit} section={section} />;
      case 'date':
        return <TemplateDetailsAuditionDate sectionId={sectionId} question={question} isCover={isCover} runAudit={runAudit} section={section} />;
      case 'objective':
        return <TemplateDetailObjective sectionId={sectionId} question={question} isCover={isCover} runAudit={runAudit} section={section} />;
      case 'evolution':
        return <TemplateDetailEvolution sectionId={sectionId} question={question} isCover={isCover} runAudit={runAudit} section={section} />;
      case 'summary':
        return <TemplateDetailSummary sectionId={sectionId} question={question} isCover={isCover} runAudit={runAudit} section={section} />;
      case 'location':
        return <TemplateDetailsLocation sectionId={sectionId} question={question} isCover={isCover} runAudit={runAudit} section={section} />;
      case 'number':
        return <TemplateDetailsNumber sectionId={sectionId} question={question} isCover={isCover} runAudit={runAudit} section={section} />;
      case 'select':
        return <TemplateDetailsSelect sectionId={sectionId} question={question} isCover={isCover} runAudit={runAudit} section={section} />;
      case 'auditor':
        return <TemplateDetailAuditor sectionId={sectionId} question={question} isCover={isCover} runAudit={runAudit} section={section} />;
      case 'readingRegistry':
        return <TemplateDetailsInSituMeasure question={question} sectionId={sectionId} runAudit={runAudit} section={section} />;
      case 'paragraph':
        return <TemplateDetailsRichText question={question} sectionId={sectionId} runAudit={runAudit} section={section} />;
      default:
        return <TemplateDetailsText sectionId={sectionId} question={question} isCover={isCover} runAudit={runAudit} section={section} />;
    }
  }, [isCover, question, runAudit, section, sectionId, type]);

  return (
    <div className="py-2">{render}</div>
  );
};

WrapperFieldType.propTypes = {
  type: PropTypes.string,
  question: PropTypes.object,
  isCover: PropTypes.bool,
  sectionId: PropTypes.string,
  runAudit: PropTypes.string,
  section: PropTypes.object,
};

export default memo(WrapperFieldType);
