import React, {createContext, useContext, useState} from 'react';

const RegisterRegisteredContext = createContext({
  register: null,
  setRegister: val => val,
  isCheckingPending: false,
  setIsCheckingPending: val => val,
});

const useRegisterRegistered = () => {
  const context = useContext(RegisterRegisteredContext);
  if (context === undefined) {
    throw new Error('useRegisterRegistered must be used within a registerRegisteredContext');
  }
  return context;
};

const RegisterRegisteredProvider = ({ ...props }) => {
  const [register, setRegister] = useState(null);
  const [isCheckingPending, setIsCheckingPending] = useState(false);

  return (
    <RegisterRegisteredContext.Provider
      value={{
        register,
        setRegister,
        isCheckingPending,
        setIsCheckingPending,
      }}
      {...props}
    />
  );
};

export { useRegisterRegistered, RegisterRegisteredProvider };

