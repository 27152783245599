import React, {useEffect} from 'react';
import MainApp from '@/modules/MainApp';
import {useAuth, useUser} from '@dofleini/security';
import UnauthenticatedApp from '@/modules/UnauthenticatedApp';
import {authentication} from '@/settings/authentication';
import OnboardingModule from '@/modules/onboarding';
import {AuditReportInternationalizationProvider} from '@/contexts/AuditReportInternationalizationContext';
import {message} from 'antd';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';
import ShareAuditReport from '@/modules/ShareAuditReport';
import {i18nSinglePage as i18} from '@/settings';
import {ApiClientService} from '@dofleini/security';
import {useLocationChange} from '@/modules/clientRegisters/hooks/useLocation';
import {useRegisterRegistered} from '@/modules/clientRegisters/hooks/useRegisterRegistered';
import {useMutation} from 'react-query';
import RegistersApiService from '@/modules/registers/services/RegistersApiService';
import TokenService from '@/contexts/TokenService';

ApiClientService.setup({
  TokenService,
  loginPath: /(\/api\/signin)|(\/api\/refresh-token)|(\/api\/signin-admin)/
});

function App() {
  const {user} = useUser();
  const {t} = useTranslation('common');
  const {logout} = useAuth();
  const {pathname} = useLocation();
  const {setRegister, setIsCheckingPending} = useRegisterRegistered();

  // eslint-disable-next-line no-unused-vars
  const {mutate: checkIsPending} = useMutation(RegistersApiService.checkIsPending,
    {
      onSuccess: () => {
        setIsCheckingPending(false);
      }
    });

  useLocationChange(async (location, prevLocation) => {
    if (prevLocation?.pathname?.includes('/client-registers/run-register') && !location?.pathname?.includes('/client-registers/run-register')) {
      const splitDir = prevLocation?.pathname?.split('/');
      const register = splitDir[splitDir?.length - 1];
      setRegister(register);
      setIsCheckingPending(true);
      await checkIsPending({register});
    }
  });

  useEffect(() => {
    ApiClientService.interceptors.request.use(function (config) {
      config.headers['client-date'] = new Date().toISOString();
      return config;
    }, function (error) {
      return Promise.reject(error);
    });
  }, []);

  useEffect(() => {
    if (!pathname?.includes('/auditor/audits') || !pathname?.includes('/client-audits/')) {
      i18.changeLanguage('es');
    }
  }, [pathname]);

  if (pathname?.includes('share-audit-report')) return (
    <AuditReportInternationalizationProvider>
      <ShareAuditReport/>
    </AuditReportInternationalizationProvider>
  );

  if (user && authentication?.activeOnboarding && !user?.onboardingCompleted && user.isAuditor) {
    message.config({maxCount: 1});
    message.error(t('auditorOnBoardingError'));
    window.localStorage.clear();
    ApiClientService.setSpace('');
    logout();
    window.location.reload();
  }

  if (user && authentication?.activeOnboarding && !user?.onboardingCompleted && !user.isAuditor)
    return <OnboardingModule/>;

  if (user)
    return <>
      <MainApp/>
    </>;

  return <UnauthenticatedApp/>;
}

export default App;
