import {createGetOneHook} from '@/utils/createGetOneHook';
import {createFieldsHook} from '@/utils/createFieldsHook';
import TasksApiService from '@/modules/tasks/services/TasksApiService';
import {CACHE_KEY_LIST_TASKS, CACHE_KEY_TASKS} from '@/modules/tasks/contants/tasks';
import {fields} from '@/modules/tasks/contants/tasks.form';
import {useMutation, useQueryClient} from 'react-query';
import useAlerts from '@/hooks/useAlerts';
import {CACHE_KEY_INCIDENTS, CACHE_KEY_LIST_INCIDENTS} from '@/modules/incidents/contants/incidents';

export const useGetOneTasks = createGetOneHook(TasksApiService, 'id', CACHE_KEY_TASKS);
export const useFields = createFieldsHook('tasks', fields);

export const useUpdate = () => {
  const queryClient = useQueryClient();
  const {putErrorCode} = useAlerts();

  const {mutateAsync: update, isLoading} = useMutation(TasksApiService.update,
    {
      onSuccess: ({data}) => {
        queryClient.invalidateQueries(CACHE_KEY_LIST_INCIDENTS);
        queryClient.invalidateQueries(CACHE_KEY_LIST_TASKS);
        queryClient.invalidateQueries(CACHE_KEY_TASKS);
        queryClient.invalidateQueries(CACHE_KEY_INCIDENTS);
        queryClient.invalidateQueries(data?._id);
        queryClient.invalidateQueries(data?.issue?._id);
        queryClient.invalidateQueries([data?.issue?._id, CACHE_KEY_INCIDENTS]);
      },
      onError: (e) => {
        putErrorCode(e);
      }
    });

  return {
    update,
    isLoading
  };
};
