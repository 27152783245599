import React, {memo, Suspense, useMemo} from 'react';
import {RouteLoader, useUser} from '@dofleini/security';
import PageLoader from '@/components/PageLoader';
import routes from './routes';

const MainRouter = () => {
  const { user } = useUser();
  const isAuditor = useMemo(() => {
    return user?.isAuditor ? '/home/auditor' : '/home/client';
  }, [user?.isAuditor]);

  return (
    <Suspense fallback={<PageLoader/>}>
      <RouteLoader routes={routes} notfoundRedirect={isAuditor}/>
    </Suspense>
  );
};

export default memo(MainRouter);
