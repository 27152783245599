import {useEffect, useMemo} from 'react';
import {createGetOneHook} from '@/utils/createGetOneHook';
import {createFieldsHook} from '@/utils/createFieldsHook';
import AuditorAuditsApiService from '@/modules/auditorAudits/services/AuditorAuditsApiService';
import {
  CACHE_KEY_AUDITOR_AUDITS,
  CACHE_KEY_LIST_AUDITOR_AUDITS, CACHE_KEY_LIST_AUDITOR_AUDITS_SECTIONS
} from '@/modules/auditorAudits/contants/auditorAudits';
import {fields} from '@/modules/auditorAudits/contants/auditorAudits.form';
import {useMutation, useQueryClient, useQuery} from 'react-query';
import useAlerts from '@/hooks/useAlerts';
import {useHistory} from 'react-router-dom';
import {CACHE_KEY_AUDITS} from '@/modules/audits/contants/audits';
import {useParams} from 'react-router-dom';
import {useAuditData} from '@/modules/auditorAudits/hooks/useAuditData';

export const useGetOneAuditorAudits = createGetOneHook(AuditorAuditsApiService, 'id', CACHE_KEY_AUDITOR_AUDITS);
export const useFields = createFieldsHook('auditorAudits', fields);

const updateIssuesAndEvidences = ({dataSections, sectionId, questionId, issue, evidences}) => {
  return dataSections?.map(s => {
    if (sectionId === s?._id) {
      return {...s, questions: s?.questions?.map(q => {
        if (q?._id === questionId) {
          return {...q, issues: q?.issues?.map(i => {

            if (i?._id === issue) return {...i, evidences: evidences?.filter(x => x)};
            return {...i};
          })};
        }
        return {...q};
      })};
    }
    return {...s};
  })?.filter(x => x);
};

/*const updateSection = ({sectionId, dataSections, section}) => {
  return dataSections?.map(s => {
    if (sectionId === s?._id) {
      return {...section};
    }
    return {...s};
  })?.filter(x => x);
};*/

export const useGetAllSections = (auditId, preview) => {
  const queryConfig = useMemo(() => {
    return {enabled: !!(auditId)};
  }, [auditId]);

  const {isLoading, isError, error, data} = useQuery([CACHE_KEY_LIST_AUDITOR_AUDITS_SECTIONS, auditId], async () => {
    const {data} = await AuditorAuditsApiService.getAllSections({auditId, preview});
    return data;
  }, queryConfig);

  return {isLoading, isError, error, data};
};

export const useCrudRunAudit = () => {
  const queryClient = useQueryClient();
  const {setSaving} = useAuditData();
  const {putErrorCode} = useAlerts();
  const {id} = useParams();

  const {mutateAsync: create, isLoading: isCreating} = useMutation(AuditorAuditsApiService.addAnswer,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(CACHE_KEY_LIST_AUDITOR_AUDITS);
        queryClient.invalidateQueries(CACHE_KEY_AUDITS);
        queryClient.invalidateQueries([id,CACHE_KEY_AUDITS]);
        queryClient.invalidateQueries(['audit-sections',id]);
      },
      onError: (e) => {
        putErrorCode(e);
      }
    });

  const {mutateAsync: update, isLoading: isUpdating} = useMutation(AuditorAuditsApiService.updateAnswer,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(CACHE_KEY_LIST_AUDITOR_AUDITS);
        queryClient.invalidateQueries(CACHE_KEY_AUDITS);
        queryClient.invalidateQueries([id,CACHE_KEY_AUDITS]);
        queryClient.invalidateQueries(['audit-sections',id]);
      },
      onError: (e) => {
        putErrorCode(e);
      }
    });

  const {mutateAsync: remove, isLoading: isRemoving} = useMutation(AuditorAuditsApiService.removeAnswer,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(CACHE_KEY_LIST_AUDITOR_AUDITS);
        queryClient.invalidateQueries(CACHE_KEY_AUDITS);
        queryClient.invalidateQueries([id,CACHE_KEY_AUDITS]);
        queryClient.invalidateQueries(['audit-sections',id]);
      },
      throwOnError: true
    }
  );

  const {mutateAsync: createMetadataValue, isLoading: isMetadataValueCreating} = useMutation(AuditorAuditsApiService.addMetadataValue,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(CACHE_KEY_LIST_AUDITOR_AUDITS);
        queryClient.invalidateQueries(CACHE_KEY_AUDITS);
        queryClient.invalidateQueries([id,CACHE_KEY_AUDITS]);
      },
      onError: (e) => {
        putErrorCode(e);
      }
    });

  useEffect(() => setSaving(isCreating || isUpdating || isRemoving || isMetadataValueCreating), [isMetadataValueCreating, isCreating, isRemoving, isUpdating, setSaving]);

  return {
    create,
    isCreating,
    update,
    isUpdating,
    remove,
    isRemoving,
    createMetadataValue,
    isMetadataValueCreating,
    isLoading: isCreating || isUpdating || isRemoving || isMetadataValueCreating
  };
};

export const useCrudIncidentsRunAudit = () => {
  const queryClient = useQueryClient();

  const {putErrorCode} = useAlerts();
  const {setSaving} = useAuditData();
  const {id} = useParams();

  const {mutateAsync: create, isLoading: isCreating} = useMutation(AuditorAuditsApiService.addAndRemoveTasks,
    {
      onSuccess: ({data}) => {
        queryClient.invalidateQueries(CACHE_KEY_LIST_AUDITOR_AUDITS);
        queryClient.invalidateQueries(CACHE_KEY_AUDITS);
        // queryClient.invalidateQueries([id,CACHE_KEY_AUDITS]);
        queryClient.invalidateQueries(['audit-sections', data?._id]);
        queryClient.invalidateQueries([CACHE_KEY_LIST_AUDITOR_AUDITS_SECTIONS, id]);
        // setDataSections(updateSection({sectionId: data?._id, dataSections, section: data}));
      },
      onError: (e) => {
        putErrorCode(e);
      }
    });

  const {mutateAsync: updateName, isLoading: isUpdatingName} = useMutation(AuditorAuditsApiService.updateName,
    {
      onSuccess: ({data}) => {
        queryClient.invalidateQueries(CACHE_KEY_LIST_AUDITOR_AUDITS);
        queryClient.invalidateQueries(CACHE_KEY_AUDITS);
        //// queryClient.invalidateQueries([id,CACHE_KEY_AUDITS]);
        queryClient.invalidateQueries(['audit-sections', data?._id]);
        queryClient.invalidateQueries([CACHE_KEY_LIST_AUDITOR_AUDITS_SECTIONS, id]);
        // setDataSections(updateSection({sectionId: data?._id, dataSections, section: data}));
      },
      onError: (e) => {
        putErrorCode(e);
      }
    });

  const {mutateAsync: updateDescription, isLoading: isUpdatingDescription} = useMutation(AuditorAuditsApiService.updateDescription,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(CACHE_KEY_LIST_AUDITOR_AUDITS);
        queryClient.invalidateQueries(CACHE_KEY_AUDITS);
        // queryClient.invalidateQueries([id,CACHE_KEY_AUDITS]);
        // queryClient.invalidateQueries(['audit-sections', data?._id]);
        queryClient.invalidateQueries([CACHE_KEY_LIST_AUDITOR_AUDITS_SECTIONS, id]);
        // setDataSections(updateSection({sectionId: data?._id, dataSections, section: data}));
      },
      onError: (e) => {
        putErrorCode(e);
      }
    });

  const {mutateAsync: updateTaskName, isLoading: isUpdatingTaskName} = useMutation(AuditorAuditsApiService.updateTaskName,
    {
      onSuccess: ({data}) => {
        queryClient.invalidateQueries(CACHE_KEY_LIST_AUDITOR_AUDITS);
        queryClient.invalidateQueries(CACHE_KEY_AUDITS);
        //// queryClient.invalidateQueries([id,CACHE_KEY_AUDITS]);
        queryClient.invalidateQueries(['audit-sections', data?._id]);
        queryClient.invalidateQueries([CACHE_KEY_LIST_AUDITOR_AUDITS_SECTIONS, id]);
        // setDataSections(updateSection({sectionId: data?._id, dataSections, section: data}));
      },
      onError: (e) => {
        putErrorCode(e);
      }
    });


  useEffect(() => setSaving(isCreating), [isCreating, setSaving]);

  return {
    create,
    updateName,
    updateDescription,
    isCreating,
    isUpdatingName,
    isUpdatingDescription,
    updateTaskName,
    isUpdatingTaskName,
    isLoading: isCreating || isUpdatingName || isUpdatingDescription || isUpdatingTaskName
  };
};

export const useCrudEvidencesRunAudit = () => {
  const queryClient = useQueryClient();
  const {dataSections, setDataSections, setSaving} = useAuditData();
  const {putErrorCode} = useAlerts();
  const {id: auditId} = useParams();

  const {mutateAsync: create, isLoading: isCreating} = useMutation(AuditorAuditsApiService.manageEvidence,
    {
      onSuccess: (id, {sectionId, questionId, evidences, issue}) => {
        queryClient.invalidateQueries(CACHE_KEY_LIST_AUDITOR_AUDITS);
        queryClient.invalidateQueries(CACHE_KEY_AUDITS);
        queryClient.invalidateQueries([id,CACHE_KEY_AUDITS]);
        queryClient.invalidateQueries(['audit-sections',id]);
        queryClient.invalidateQueries([CACHE_KEY_LIST_AUDITOR_AUDITS_SECTIONS, auditId]);
        setDataSections(updateIssuesAndEvidences({dataSections, sectionId, questionId, issue, evidences }));
      },
      onError: (e) => {
        putErrorCode(e);
      }
    });

  useEffect(() => setSaving(isCreating), [isCreating, setSaving]);

  return {
    create,
    isCreating,
    isLoading: isCreating
  };
};

export const useCrudNote = () => {
  const queryClient = useQueryClient();
  const {dataSections, setDataSections, setSaving} = useAuditData();
  const {putErrorCode} = useAlerts();

  const {mutateAsync: createNote, isLoading: isCreating} = useMutation(AuditorAuditsApiService.manageNote,
    {
      onSuccess: (id, {sectionId, questionId, evidences, issue}) => {
        queryClient.invalidateQueries(CACHE_KEY_LIST_AUDITOR_AUDITS);
        queryClient.invalidateQueries(CACHE_KEY_AUDITS);
        queryClient.invalidateQueries([id,CACHE_KEY_AUDITS]);
        queryClient.invalidateQueries(['audit-sections',id]);

        setDataSections(updateIssuesAndEvidences({sectionId, questionId, issue, evidences, dataSections}));
      },
      onError: (e) => {
        putErrorCode(e);
      }
    });

  useEffect(() => setSaving(isCreating), [isCreating, setSaving]);

  return {
    createNote,
    isCreating,
    isLoading: isCreating
  };
};

export const useCrudImage = () => {
  const queryClient = useQueryClient();
  const {dataSections, setDataSections, setSaving} = useAuditData();
  const {putErrorCode} = useAlerts();

  const {mutateAsync: upload, isLoading: isCreating} = useMutation(AuditorAuditsApiService.manageImages,
    {
      onSuccess: (id, {sectionId, questionId, evidences, issue}) => {
        queryClient.invalidateQueries(CACHE_KEY_LIST_AUDITOR_AUDITS);
        queryClient.invalidateQueries(CACHE_KEY_AUDITS);
        queryClient.invalidateQueries([id,CACHE_KEY_AUDITS]);
        queryClient.invalidateQueries(['audit-sections',id]);

        setDataSections(updateIssuesAndEvidences({sectionId, questionId, issue, evidences, dataSections}));
      },
      onError: (e) => {
        putErrorCode(e);
      }
    });

  useEffect(() => setSaving(isCreating), [isCreating, setSaving]);

  return {
    upload,
    isCreating,
    isLoading: isCreating
  };
};

export const useFinishAudit = () => {
  const queryClient = useQueryClient();

  const {putErrorCode} = useAlerts();
  const {push} = useHistory();

  const {mutateAsync: onFinish, isLoading: isFinished} = useMutation(AuditorAuditsApiService.finishAudit,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(CACHE_KEY_LIST_AUDITOR_AUDITS);
        push('/auditor/audits');
      },
      onError: (e) => {
        putErrorCode(e);
      }
    });

  return {
    onFinish,
    isFinished,
    isLoading: isFinished
  };
};

export const useExecuteAudit = () => {
  const queryClient = useQueryClient();

  const {putErrorCode} = useAlerts();

  const {mutateAsync: onExecute, isLoading} = useMutation(AuditorAuditsApiService.executeAudit,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(CACHE_KEY_LIST_AUDITOR_AUDITS);
      },
      onError: (e) => {
        putErrorCode(e);
      }
    });

  return {
    onExecute,
    isLoading
  };
};

export const useUpdateAudit = () => {
  const queryClient = useQueryClient();

  const {putErrorCode} = useAlerts();

  const {mutateAsync: onUpdate, isLoading} = useMutation(AuditorAuditsApiService.update,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(CACHE_KEY_LIST_AUDITOR_AUDITS);
      },
      onError: (e) => {
        putErrorCode(e);
      }
    });

  return {
    onUpdate,
    isLoading
  };
};
