import React, { createContext, useContext, useMemo } from 'react';
import {useGetSpace} from '@/hooks/useGetSpace';
import {buildCallbacks} from '@/utils/utils';

const SpaceContextContext = createContext();

function SpaceProvider(props) {
  const { data, isLoading, refetch, error } = useGetSpace();
  return (
    <SpaceContextContext.Provider
      value={{
        space: data,
        isLoading,
        refetch,
        error
      }}
      {...props}
    />
  );
}

function useSpace() {
  const context = useContext(SpaceContextContext);
  if (context === undefined) {
    throw new Error('useSpace must be used within a SpaceContextProvider');
  }
  const { space, isLoading, refetch, error } = context;

  const { getAllModule, getModule, getTheme, hasModule } = useMemo(() => {
    return buildCallbacks(space);
  }, [space]);

  return {
    space,
    isLoading,
    refetch,
    error,
    getAllModule,
    getModule,
    getTheme,
    hasModule
  };
}

SpaceProvider.propTypes = {};

export { SpaceProvider, useSpace };
