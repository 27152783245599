import React from 'react';
import {EyeOutlined} from '@ant-design/icons';

export const CACHE_KEY_LIST_AUDITS = 'client-audits';
export const CACHE_KEY_AUDITS = 'audits-one';
export const ROUTE_PATH_AUDITS = '/client-audits';
export const TRANSLATION_PREFIX_AUDITS = 'audits';

export const NUMBER_FORMAT = {
  temperature: ' º',
  currency: ' $',
};

export const options = [
  {
    title: 'audits.options',
    disabled: true,
    style: {cursor: 'default'}
  },
  {
    icon: <EyeOutlined/>,
    title: 'details',
    action: 'details',

  }
];

