import {useCallback} from 'react';
import { useQuery } from 'react-query';
import _Remove from 'lodash/remove';
import SpaceApiService from '@/modules/admin/services/SpaceApiService';
import {ApiClientService} from '@dofleini/security';

export const useSpaceList = () => {
  const space = ApiClientService.getSpace();

  const byId = useCallback((data) => {
    return data?.some(s => s._id === space) ? true : !data?.some(s => s.identifier === space);
  }, [space]);

  const {isLoading, isError, error, data} = useQuery('my-spaces', async () => {
    const {data} = await SpaceApiService.mySpaces();
    await _Remove(data, byId(data) ? {_id: space} : {identifier: space});
    return data;
  });
  return {isLoading, isError, error, data};
};
