import React from 'react';
import { /*BellFilled,*/ ExportOutlined, UserOutlined } from '@ant-design/icons';
import { useHandleClick } from '@/components/UserOptions/hooks';
import { USER_ACTION_TYPE } from '@/components/UserOptions/constants';
import Home6LineIcon from 'remixicon-react/Home6LineIcon';
import SurveyLineIcon from 'remixicon-react/SurveyLineIcon';
import BubbleChartLineIcon from 'remixicon-react/BubbleChartLineIcon';
// import TaskLineIcon from 'remixicon-react/TaskLineIcon';
import FlagLineIcon from 'remixicon-react/FlagLineIcon';
// import FolderInfoLineIcon from 'remixicon-react/FolderInfoLineIcon';
import UserSettingsLineIcon from 'remixicon-react/UserSettingsLineIcon';
import CalendarTodoLineIcon from 'remixicon-react/CalendarTodoLineIcon';
// import FileTextLineIcon from 'remixicon-react/FileTextLineIcon';

/**
 * Navigation define all the routes and link that
 * will be render in the sidebar or navbar(depending of the layout)
 *
 * It can be an
 * {Array} Use array when yo have group sections, like Main, Admin , etc
 * Ex: [{title:'name', menu:[...],}, ....]
 *
 * {Object} Use object when yo don't have section , it is just one,
 * Ex: {menu: [.....]}
 * */
export const navigation = {
  menu: [
    {
      title: 'home',
      exact: 'true',
      link: '/home/auditor',
      permissions: ['AUDIT:READ'],
      isAuditor: true,
      icon: <Home6LineIcon className="antd-icon"/>,
    },
    {
      title: 'home',
      exact: 'true',
      link: '/home/client',
      permissions: ['AUDIT:READ'],
      isClient: true,
      icon: <Home6LineIcon className="antd-icon"/>,
    },
    // {
    //   title: 'registers',
    //   exact: 'true',
    //   link: '/client-registers',
    //   permissions: ['REGISTER:READ'],
    //   isClient: true,
    //   icon: <FileTextLineIcon className="antd-icon"/>
    // },
    {
      title: 'audits',
      exact: 'true',
      link: '/client-audits',
      permissions: ['AUDIT:READ'],
      isClient: true,
      icon: <SurveyLineIcon className="antd-icon"/>// <Briefcase2LineIcon className="antd-icon"/>
    },
    {
      title: 'incidents',
      exact: 'true',
      link: '/incidents',
      permissions: ['AUDIT:READ', 'ISSUE:READ'],
      isClient: true,
      icon: <FlagLineIcon className="antd-icon"/>
    },
    // {
    //   title: 'documentation',
    //   exact: 'true',
    //   link: '/client-documentation',
    //   permissions: ['AUDIT:READ'],
    //   isClient: true,
    //   icon: <FolderInfoLineIcon className="antd-icon"/>
    // },
    {
      title: 'users',
      exact: 'true',
      link: '/users',
      permissions: ['ADMIN', 'USER:MANAGEMENT', 'SPACE:ADMIN', 'SPACE:OWNER'],
      isClient: true,
      icon: <UserSettingsLineIcon className="antd-icon"/>,
      menu: [
        {
          title: 'users',
          exact: 'true',
          link: '/users/users',
          permissions: ['USER:MANAGEMENT', 'SPACE:ADMIN', 'SPACE:OWNER'],
        },
        // {
        //   title: 'rolesAndPermissions',
        //   exact: 'true',
        //   link: '/users/roles-and-permissions',
        //   permissions: ['USER:MANAGEMENT', 'SPACE:ADMIN', 'SPACE:OWNER'],
        // }
      ]
    },
    {
      title: 'analytics',
      exact: 'true',
      link: '/analytics/client',
      permissions: ['ANALYTICS:READ'],
      isClient: true,
      icon: <BubbleChartLineIcon className="antd-icon"/>
    },
    {
      title: 'statistics',
      exact: 'true',
      link: '/analytics/incidences',
      permissions: ['ANALYTICS:READ'],
      isClient: true,
      isIssue: true,
      icon: <BubbleChartLineIcon className="antd-icon"/>
    },
    {
      title: 'visitsCalendar',
      exact: 'true',
      link: '/calendar',
      isAuditor: true,
      permissions: ['AUDIT:READ'],
      icon: <CalendarTodoLineIcon className="antd-icon"/>
    },
    // {
    //   title: 'registers',
    //   exact: 'true',
    //   link: '/registers',
    //   permissions: ['REGISTER:READ'],
    //   isAuditor: true,
    //   icon: <FileTextLineIcon className="antd-icon"/>,
    //   menu: [
    //     {
    //       title: 'management',
    //       exact: 'true',
    //       link: '/registers/management',
    //       permissions: ['REGISTER:READ', 'REGISTER:WRITE'],
    //     },
    //     {
    //       title: 'inspection',
    //       exact: 'true',
    //       link: '/registers/inspection',
    //       permissions: ['REGISTER:READ'],
    //     }
    //   ]
    // },
    {
      title: 'audits',
      exact: 'true',
      link: '/auditor/audits',
      isAuditor: true,
      permissions: ['AUDIT:READ'],
      icon: <SurveyLineIcon className="antd-icon"/>// <Briefcase2LineIcon className="antd-icon"/>
    },
    // {
    //   title: 'documentation',
    //   exact: 'true',
    //   link: '/auditor-documentation',
    //   permissions: ['USER', 'AUDIT:READ'],
    //   isAuditor: true,
    //   icon: <FolderInfoLineIcon className="antd-icon"/>
    // },
    {
      title: 'analytics',
      exact: 'false',
      link: '/analytics/auditor',
      permissions: ['ANALYTICS:READ'],
      isAuditor: true,
      icon: <BubbleChartLineIcon className="antd-icon"/>
    },
  ]
};

export const userOptions = {
  // options: [
  //   {
  //     title: 'notifications',
  //     action: 'NOTIFICATIONS',
  //     icon: <BellFilled/>
  //   }
  // ],
  userActions: {
    viewType: USER_ACTION_TYPE.POPUP,
    showInfo: true,
    options: [
      {
        divider: true
      },
      {
        title: 'profile',
        action: 'PROFILE',
        icon: <UserOutlined/>,
      },
      {
        divider: true
      },
      {
        title: 'logout',
        action: 'LOGOUT',
        icon: <ExportOutlined/>,
      }
    ],
  },
  useHandleClick
};
