/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 9/6/22
 */
import React, {memo, useState} from 'react';
import PropTypes from 'prop-types';
import {Upload, Image} from 'antd';
import classNames from 'classnames';
import styles from './index.less';
import {useCrudImage} from '@/modules/auditorAudits/hooks/useAuditorAudits';
import {useParams} from 'react-router-dom';

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    // eslint-disable-next-line no-undef
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result);

    reader.onerror = (error) => reject(error);
  });

const GlobalImages = ({images, onUpdate, sectionId, questionId, runAudit}) => {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');

  const {id} = useParams();
  const {upload} = useCrudImage();

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };

  const handleChange = async ({ fileList: newFileList }) => {
    onUpdate(newFileList);
    if (runAudit) await upload({auditId: id, sectionId, questionId, noteImages: newFileList});
  };

  return (
    <div className="w-full">
      <Upload
        listType="picture-card"
        fileList={images}
        onPreview={handlePreview}
        onChange={handleChange}
        className={classNames(styles.wrapperButton)}
      >
        {images.length}
      </Upload>

      <Image
        width={200}
        style={{ display: 'none' }}
        src={previewImage}
        preview={{
          visible: previewVisible,
          src: previewImage,
          onVisibleChange: value => {
            setPreviewVisible(value);
          },
        }}
      />
    </div>
  );
};

GlobalImages.propTypes = {
  images: PropTypes.array,
  onUpdate: PropTypes.func,
  sectionId: PropTypes.string,
  questionId: PropTypes.string,
  runAudit: PropTypes.bool,
};

export default  memo(GlobalImages);
