/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 21/3/22
 */
import React, {memo, useState, useCallback, useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import {Avatar, Collapse, Checkbox, Spin, Card} from 'antd';
import classNames from 'classnames';
import {useParams} from 'react-router-dom';
import styles from '../TemplateDetailsFooterActions/index.less';
import FlagLineIcon from 'remixicon-react/FlagLineIcon';
import {useTranslation} from 'react-i18next';
import PicturesWall from '@/modules/templates/components/TemplateDetailsPicturesWall';
import {useCrudIncidentsRunAudit} from '@/modules/auditorAudits/hooks/useAuditorAudits';
import ContentEditableComponent from '@/components/ContentEditable';
import TaskComponent from './Task';
import { LoadingOutlined } from '@ant-design/icons';
import useNetwork from '@/hooks/useNetwork';
import {useAuditData} from '@/modules/auditorAudits/hooks/useAuditData';
import {mappedAuditDataIssue} from '@/utils/mappedAuditData';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const { Panel } = Collapse;

const CheckIssuesFromRunAudit = ({item, runAudit, sectionId, question}) => {

  const {t} = useTranslation(('templates'));
  const {id} = useParams();
  const {dataSections, setDataSections} = useAuditData();

  const [activeKey, setActiveKey] = useState([]);

  const [checkedIssue, setCheckedIssue] = useState(runAudit ? question?.issues?.some(i => {
    if (i?._id === item?._id) {
      return i?.tasks?.some(t => t?.selected) || i?.selected;
    }
    return false;
  }) : false);
  const [checkedTask, setCheckedTask] = useState(runAudit ? question?.issues?.find(i => i?._id === item?._id)?.tasks?.map(t => t?.selected ? t?._id : null) || [] : []);
  const [checked, setChecked] = useState([]);

  const counterSelected = useMemo(() => {
    return question?.issues?.map(i => {
      if (i?._id === item?._id) {
        return i?.tasks?.find(t => t?.selected);
      }
      return false;
    })?.filter(x => x)?.length;
  }, [item?._id, question?.issues]);

  const {create, isLoading: isLoadingIncident, updateName, updateDescription, updateTaskName} = useCrudIncidentsRunAudit();

  const networkState = useNetwork(); // Connection status

  const onChange = useCallback((id) => {
    if (checked?.some(x => x === id)) setChecked(checked?.filter(x => x !== id));
    else setChecked([...checked, id]);
  }, [checked]);

  useEffect(() => {
    if (item?.tasks?.length === 1 && checked?.length === 0)
      onChange(item?.tasks?.[0]?._id);
    // eslint-disable-next-line
    }, []);

  const onSaveName = useCallback(async (data) => {
    if (runAudit) {
      if ('name' in data) {
        await updateName({auditId: id, sectionId, questionId: question?._id, name: data.name, issue: item?._id});
      } else {
        await updateDescription({auditId: id, sectionId, questionId: question?._id, description: data.description, issue: item?._id});
      }
    }
  },  [id, item?._id, question?._id, runAudit, sectionId, updateDescription, updateName]);

  const onSaveTaskName = useCallback(async (task, value) => {

    /*const issues = await question?.issues?.map((issue) => {
      if(issue?._id === item?._id){
        const tasks = issue?.tasks?.map(el => {
          if(el?._id === task?._id || el?._id === task?._id){
            return {...el, name: value};
          }
          return {...el};
        });
        return {...issue, tasks};
      }
      return issue;
    });*/

    if (runAudit) {
      await updateTaskName({auditId: id, questionId: question?._id, sectionId, name: value, task: task?._id, issue: item?._id});
    }
  },  [id, item?._id, question?._id, runAudit, sectionId, updateTaskName]);

  /** Handle check or uncheck issues */
  const onCheckIssue = useCallback(async (evt) => {
    evt.stopPropagation();
    let checked = evt?.target?.checked;

    setCheckedIssue(checked);

    if (checked) setActiveKey([item._id]);
    else setActiveKey([]);

    // just for run audit
    if(runAudit) {
      const actions = checked ? {add: [{issue: item?._id, ...(item?.tasks?.length === 1 && {task : item?.tasks?.[0]?._id})}]} : {remove: [{issue: item?._id}]};

      const valuesForCheck = {
        auditId: id,
        sectionId,
        questionId: question?._id,
        ...actions
      };

      if (item?.tasks?.length === 1) {
        await create(valuesForCheck);
        setCheckedTask(prevState => (checked ? [...prevState, item?.tasks?.[0]?._id] : []));
        setChecked(prevState => (checked ? [...prevState, item?.tasks?.[0]?._id] : []));
      } else {
        await create(valuesForCheck);
        setCheckedTask(prevState => (checked ? [...prevState, item?._id] : []));
        setChecked(prevState => (checked ? [...prevState, item?._id] : []));
        setDataSections(mappedAuditDataIssue(dataSections, sectionId, question?._id, item?._id, {...item, selected: evt?.target?.checked}));
      }

      // Only apply when an incident has a single task marked
      if (actions?.remove?.length && counterSelected >= 0) {
        const actions = {remove: [{issue: item?._id}]};

        const values = {
          auditId: id,
          sectionId,
          questionId: question?._id,
          ...actions
        };
        await create(values);
      }
    }
  },  [counterSelected, create, dataSections, id, item, question?._id, runAudit, sectionId, setDataSections]);

  const onCheckTask = useCallback(async (e, task) => {
    e.stopPropagation();

    let update = [];

    if (!e.target.checked) {
      update = checkedTask?.filter(x => x !== task?._id);
    } else {
      update = [...checkedTask, task?._id];
    }

    setCheckedTask(update);
    setChecked(update);

    if (update?.length > 0) setCheckedIssue(true);

    // just for run audit
    if(runAudit) {
      const actions = e.target.checked ? {add: [{issue: item?._id, task: task?._id}]} : {remove: [{issue: item?._id, task: task?._id}]};

      const values = {
        auditId: id,
        sectionId,
        questionId: question?._id,
        ...actions
      };
      await create(values);
    }
  },  [checkedTask, create, id, item?._id, question?._id, runAudit, sectionId]);

  return (
    <Collapse
      bordered={true}
      key={item._id}
      forceRender
      className={styles.headerCollapse}
      activeKey={activeKey}
      onChange={setActiveKey}
    >
      <Panel
        header={
          <div className="h-full w-full flex items-center justify-start min-h-6">
            <Checkbox
              checked={checkedIssue ? 'checked' : null}
              onClick={onCheckIssue}
              className="mr-3"
              disabled={isLoadingIncident}
            />
            <Avatar icon={<FlagLineIcon size={17} color="#707070" />} className="min-w-8 flex items-center justify-center"/>
            <Spin indicator={antIcon} key={item?._id} spinning={networkState?.online && (isLoadingIncident)}>
              <ContentEditableComponent
                id={item?._id}
                initValue={item?.name}
                onSave={(value) => onSaveName({name: value})}
                className="ml-2 max-w-3\/5"
                isLoading={isLoadingIncident}
              />
            </Spin>
          </div>
        }
        key={item._id}
      >
        <div className={classNames('w-full h-full', item?.deductionPercentage ? 'mb-2' : 'mb-4')}>
          <ContentEditableComponent
            id={`description-${item?._id}`}
            initValue={item?.description}
            onSave={(value) => onSaveName({description: value})}
            placeholder={t('templates.description')}
            isLoading={isLoadingIncident}
          />
        </div>

        {item?.deductionPercentage && <div className="w-full flex items-center justify-start mb-4">
          <div className="font-bold">{t('deductionPercentage')}:</div>
          <div className="ml-2">{item?.deductionPercentage}%</div>
        </div>}

        {checkedIssue && <div className="mt-4">
          <PicturesWall
            runAudit={runAudit}
            sectionId={sectionId}
            question={question}
            incidence={item}
            setCheckedIssue={setCheckedIssue}
            translation={'templates'}
            disabled={isLoadingIncident}
          />
        </div>}

        <Card
          className={'mb-2 rounded'}
          bodyStyle={{padding: '6px', background: '#FAFAFA'}}
        >
          <div className="font-bold my-2">{t('details.tasks')}:</div>
          {item?.tasks?.length > 0 && item?.tasks?.map((task, index) => {
            return (
              <TaskComponent
                key={index}
                item={task}
                onChange={(e) => onCheckTask(e, task)}
                checked={checkedTask?.includes(task?._id) ? 'checked' : null}
                issuesAudit={question?.issuesAudit}
                issueData={item}
                onSaveTaskName={onSaveTaskName}
                isLoading={isLoadingIncident}
              />
            );
          })}
        </Card>
      </Panel>
    </Collapse>
  );
};

CheckIssuesFromRunAudit.propTypes = {
  item: PropTypes.object,
  i: PropTypes.number,
  handleOnClickTask: PropTypes.func,
  selectedTask: PropTypes.number,
  runAudit: PropTypes.bool,
  sectionId: PropTypes.string,
  question: PropTypes.object,
};

export default memo(CheckIssuesFromRunAudit);
