import {EntityApiService, ApiClientService} from '@dofleini/security';
import {InFilter, OperatorFilter, TermFilter} from '@dofleini/query-builder';

class AuditsApiService extends EntityApiService {

  constructor(path) {
    super();
    this.summaryParams = {};
    this.path = path;
  }

  getSummaryParams(params){
    const fromBack = this.summaryParams?.filters?.filters?.[0]?.filterObject ? [new TermFilter(this.summaryParams?.filters?.filters?.[0]?.filterObject)] : [{}];
    const fromUI = params?.filters?.filters?.map(x => {
      if (x.type === 'TERM')
        return new TermFilter({field: x?.field, value: x?.value, objectId: x?.objectId}).toQuery();
      if (x.type === 'IN')
        return new InFilter({field: x?.field, value: x?.value, objectId: x?.objectId}).toQuery();
    });

    const filters = new OperatorFilter({type: 'AND', filters: fromUI?.concat(fromBack)?.map(x => (x))}).toQuery();
    return {...this.summaryParams, ...params, filters};
  }

  setSummaryParams(params){
    this.summaryParams = params;
  }

  summarySearch = (params) => {
    return ApiClientService.post(this.getPath('/search-summary-from-client'), this.getSummaryParams(params));
  }

}

export default new AuditsApiService('/ms-core/api/audits');
