/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 9/1/22
 */
import Dexie from 'dexie';

const useIndexDbDexie = () => {

  //set the database
  const db = new Dexie('bioaudit');
  //create the database store
  db?.version(1).stores({
    audits: '_id, currentAudit, dataIntro, dataSections',
    currentAudit: '_id',
  });

  return {db};
};

export default useIndexDbDexie;
