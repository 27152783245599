import React, {memo, useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Tag} from 'antd';
import EntityMenu from '@/components/EntityMenu';
import {
  STATUS_TASK,
  STATUS_TASK_APPEARANCE
} from '@/modules/tasks/contants/tasks';
import {useUpdate} from '@/modules/tasks/hooks/useTasks';
import styles from '@/modules/tasks/components/TasksDetails/index.less';

export const TaskStatus = ({status, className, ...props}) => {
  const {t} = useTranslation('tasks');
  if (!STATUS_TASK[status])
    return (
      <Tag key={status} className={'w-16 text-center'}>
          -
      </Tag>);

  return (
    <Tag
      className={classNames('rounded flex items-center justify-center', className)}
      style={{ minHeight: '45px', minWidth: '150px' }}
      color={STATUS_TASK_APPEARANCE[status].color}
      key={status}
      {...props}
    >
      {t(`status.${status}`)}
    </Tag>
  );
};

const toDoOption = {
  title: <TaskStatus key="toDoOption" status={STATUS_TASK.toDo}/>,
  action: STATUS_TASK.toDo,
};

const runningOption = {
  title: <TaskStatus key="runningOption" status={STATUS_TASK.running}/>,
  action: STATUS_TASK.running,
};

const completedOption = {
  title: <TaskStatus key="completedOption" status={STATUS_TASK.complete}/>,
  action: STATUS_TASK.complete,
};

const Status = ({id, value}) => {
  const {update} = useUpdate();
  const onChange = useCallback((status) => {
    update({_id: id, status});
  },[id, update]);

  const options = useMemo(() => {
    switch (value) {
      case STATUS_TASK.toDo:
        return [runningOption, completedOption];
      case STATUS_TASK.running:
        return [toDoOption, completedOption];
      case STATUS_TASK.complete:
        return [toDoOption, runningOption];
      default:
        return [
          toDoOption,
          runningOption,
          completedOption,
        ];
    }
  }, [value]);

  const handleClick = useCallback(({action}) => onChange(action), [onChange]);

  return (
    <EntityMenu className={styles.styleTags} options={options} translate={'tasks'} onClick={handleClick} mapOptions={false} placement="bottomLeft">
      <TaskStatus status={value} className="cursor-pointer p-1 w-max"/>
    </EntityMenu>
  );
};

Status.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string
};

TaskStatus.propTypes = {
  status: PropTypes.string,
  className: PropTypes.string,
};

export default memo(Status);
