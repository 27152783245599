/**
 * @author Ricardo Comendador Escalona
 * @version v0.0.1
 * @date  15/11/2021
 */
import React, {memo, useState, useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import styles from './index.less';
import { Card, Spin} from 'antd';
import TemplateDetailsFooterActions from '@/modules/templates/components/TemplateDetailsFooterActions';
import {useTranslation} from 'react-i18next';
import classNames from 'classnames';
import {useCrudRunAudit} from '@/modules/auditorAudits/hooks/useAuditorAudits';
import {useParams} from 'react-router-dom';
import {useGetOneAudits} from '@/modules/audits/hooks/useAudits';
import {useAuditData} from '@/modules/auditorAudits/hooks/useAuditData';
// import useNetwork from '@/hooks/useNetwork';
import { LoadingOutlined } from '@ant-design/icons';
import {useResponsive} from '@/contexts/ResponsiveContext';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const TemplateDetailSelect = ({sectionId, question, isCover, runAudit}) => {

  const {create, update, isLoading: isLoadingQuestion} = useCrudRunAudit();
  const {id} = useParams();
  const {t} = useTranslation('templates');
  const {isMobile} = useResponsive();

  const {data: audit, isLoading} = useGetOneAudits({id});
  const {setCurrentAudit} = useAuditData();

  // const networkState = useNetwork(); // Connection status

  const [selects, setSelects] = useState(runAudit ? question?.answer?.templateAnswer?.select || [] : []);
  const [current, setCurrent] = useState('');

  useEffect(() => {
    if (!isLoading)
      setSelects(question?.answer?.templateAnswer?.select || []);
  }, [isLoading, question?.answer?.templateAnswer?.select]);

  useEffect(() => {
    if (!isLoading)
      setCurrent('');
  }, [isLoading]);

  const onSave = useCallback(async (select) => {
    if (runAudit) {
      const templateAnswer = {
        type: 'select',
        select

      };
      if (!question?.answer?._id) {
        await create({id, section: sectionId, question: question?._id, templateAnswer});
      } else await update({
        answerId: question?.answer?._id,
        id,
        section: sectionId,
        question: question?._id,
        templateAnswer
      });
      setCurrentAudit({audit: audit?.data, establishment: audit?.data?.establishment?.name, service: audit?.data?.service?.name, ...audit?.data});
    }
  }, [audit?.data, create, id, question?._id, question?.answer?._id, runAudit, sectionId, setCurrentAudit, update]);

  const onSelect = useCallback((val) => {
    if (question?.select?.multiselect) {
      if (selects?.some(x => x === val)) {
        const remove = selects?.filter(x => x !== val);
        setSelects(remove);
        if (runAudit)
          onSave(remove).then();
      } else {
        setSelects(prev => ([...prev, val]));
        if (runAudit)
          onSave([...selects, val]).then();
      }
    } else {
      setSelects([val]);
      if (runAudit)
        onSave([val]).then();
    }
  }, [onSave, question?.select?.multiselect, runAudit, selects]);

  // const getTotal = useMemo(() => {
  //   return question?.select?.options?.reduce((sum, value) => (typeof value.score == 'number' ? sum + value.score : sum), 0);
  // }, [question?.select]);

  // const getPercent= useMemo(() => {
  //   return (selected?.score * 100 / getTotal);
  // }, [getTotal, selected?.score]);

  return (
    <Card
      className={styles.templateSelectCard}
      id={'question-view-'+question?._id}
    >
      <div className="flex items-center justify-between">
        {/*<div style={{color: 'rgb(235, 0, 39)'}}>*/}
        {/*  {question?.controlParameter ? '*' : null}*/}
        {/*</div>*/}

        <div className="mb-3">
          {question?.name}
        </div>

        {/*{(question?.select?.scoring && selected?.label?.toLowerCase() !== 'n/a') ? <div>*/}
        {/*  {selected?.score + '/' + getTotal} ({Number(getPercent?.toFixed(2))}%)*/}
        {/*</div> : <div style={{ opacity: 0 }}>0/0</div>}*/}
      </div>

      <div className={classNames('flex items-center justify-center flex-wrap w-full', runAudit || isMobile ? '' : 'flex-col', styles.wrapperSpinQuestion)}>
        {question?.select?.options?.map(item => {
          // if (networkState?.online && isLoadingQuestion && current !== item?._id) {
          //   return (
          //     <Spin indicator={null} key={item?._id} spinning={networkState?.online && isLoadingQuestion && current !== item?._id}>
          //       <div onClick={() => {
          //         // setSelected({...item, id: item?._id});
          //         onSelect(item?._id);
          //         setCurrent(item?._id);
          //       }}
          //       className={styles.responseOption}
          //       style={{ backgroundColor: selects?.includes(item?._id) ? item?.color: 'rgb(243, 246, 251)' }}
          //       >
          //         {t(item?.label)}
          //       </div>
          //     </Spin>
          //   );
          // }

          return (
            <Spin indicator={antIcon} key={item?._id} spinning={isLoadingQuestion && current === item?._id}>
              <div onClick={() => {
              // setSelected({...item, id: item?._id});
                onSelect(item?._id);
                setCurrent(item?._id);
              }}
              className={styles.responseOption}
              style={{ backgroundColor: selects?.includes(item?._id) ? item?.color: 'rgb(243, 246, 251)' }}
              >
                {t(item?.label)}
              </div>
            </Spin>
          );
        })
        }
      </div>

      <TemplateDetailsFooterActions question={question} isCover={isCover} sectionId={sectionId} runAudit={runAudit} />
    </Card>
  );
};

TemplateDetailSelect.propTypes = {
  runAudit: PropTypes.bool,
  sectionId: PropTypes.string,
  question: PropTypes.object,
  isCover: PropTypes.bool,
};
TemplateDetailSelect.defaultProps = {
  question: {},
};

export default memo(TemplateDetailSelect);


/**
 * cellRender
 * */
/**
 * This is not a components,
 * it is a function to render the Detail Audit Card components in a detail drawer
 * @param {String} value this is the value of the dataIndex of the table (we will ignored in this case)
 * @param {Object} entity this is the row instance
 * */
export const renderTemplateDetailSelect = (value, entity) => {
  return (
    <TemplateDetailSelect entity={entity}/>
  );
};
