/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 23/2/22
 */
import React, {memo, useMemo} from 'react';
import { Bar } from '@ant-design/plots';
import {useAuditData} from '@/modules/auditorAudits/hooks/useAuditData';
import {useTranslation} from 'react-i18next';
import styles from './index.less';

const BarChartIssue = () => {

  const {dataSections} = useAuditData();
  const {t} = useTranslation('audits');

  const data = useMemo(() => {
    const dataBar = [];
    dataSections?.forEach((section) => {
      const high = {
        title: section?.title,
        type: t('highRisk'),
        score: section?.highRiskIssuesCount || 0,
      };
      const medium = {
        title: section?.title,
        type: t('mediumRisk'),
        score: section?.mediumIssuesCount || 0,
      };
      const low = {
        title: section?.title,
        type: t('lowRisk'),
        score: section?.lowIssuesCount || 0,
      };
      dataBar.push(high);
      dataBar.push(medium);
      dataBar.push(low);
    });
    return dataBar;
  }, [dataSections, t]);

  const config = {
    data,
    isGroup: true,
    xField: 'score',
    yField: 'title',
    seriesField: 'type',
    marginRatio: 0,
    // tooltip: {
    //   formatter: (datum) => {
    //     return { name: t('scoring'), value: datum.score ? Number(datum.score).toFixed(1) + '%' : '0%'};
    //   },
    // },
    label: {
      position: 'middle',
      offset: 4,
      style: {
        fontWeight: 'bold',
      }
    },
    color: ({ type }) => {
      if (type === t('highRisk')) return '#EF0000';
      if (type === t('mediumRisk')) return '#DB6307';
      return '#dbaa07';
    },
    legend: {
      position: 'bottom',
    },
  };

  return (
    <div className={styles.wrapperChart}>
      <Bar {...config} />
    </div>
  );
};

BarChartIssue.propTypes = {};

export default memo(BarChartIssue);
