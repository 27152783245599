import React, {memo, useCallback, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {Modal, Upload} from 'antd';
import {PlusOutlined, LoadingOutlined, EyeOutlined, DeleteOutlined} from '@ant-design/icons';
import useUploadImage from '@/components/UploadImage/useUploadImage';
import styles from './index.less';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line no-undef
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

const UploadImage = ({onRemove, onChange, value}) => {
  const {props, handleChange, imageUrl, loading} = useUploadImage(value?.url, onChange);
  const {t} = useTranslation('templates');
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const handleCancel = useCallback(() => setPreviewVisible(false),[]);

  const handlePreview = useCallback(async (file) => {
    if (!imageUrl) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(imageUrl);
    setPreviewVisible(true);
    setPreviewTitle(file?.name || file?.url?.substring(file?.url.lastIndexOf('/') + 1));
  }, [imageUrl]);

  const uploadButton = useMemo(() => (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>{t('details.upload')}</div>
    </div>
  ), [loading, t]);

  return (
    <div className={styles.styleUpload}>
      <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        {...props}
        onPreview={handlePreview}
        onChange={handleChange}
      >
        {imageUrl ?
          <div style={{ position: 'relative' }}>
            <img src={imageUrl} alt="avatar" height={100} style={{ width: '100%' }} />
            <span
              onClick={e => e.stopPropagation()}
              className="actionImage"
              style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%,-50%)', color: 'white' }}
            >
              <div className={'inline-flex justify-between text-xl'}>
                <EyeOutlined onClick={() => handlePreview(value)} className="mr-2" size="large"/>
                <DeleteOutlined onClick={onRemove}/>
              </div>
            </span>
          </div>

          : uploadButton}
      </Upload>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </div>
  );
};

export default memo(UploadImage);

UploadImage.defaultProps = {
  value: '',
  btnProps: {},
  btnText: 'explore',
};

UploadImage.propTypes = {
  onRemove: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.string
};

