/**
 * @author Geider Arevalo
 * @version v0.0.1
 * @date  25/01/2022
 */
import React, {memo, useMemo} from 'react';
import PropTypes from 'prop-types';
import styles from './index.less';
import { Card } from 'antd';
import {NUMBER_FORMAT} from '@/modules/audits/contants/audits';
import classNames from 'classnames';
import Incidents from '@/modules/audits/components/WrapperRunAudit/components/ManageAuditContent/components/WrapperFieldType/Incidents';

const Number = ({question, isIncidence, title, idx}) => {
  const value = useMemo(() => question?.answer?.templateAnswer?.number, [question?.answer?.templateAnswer?.number]);

  if(isIncidence){
    return <Card
      key={idx}
      className={classNames(styles.templateNumberCard, idx !==0 && 'mt-2')}
      bodyStyle={{padding: '6px'}}
    >
      <div>
        {title}
      </div>
      <div className={'font-bold'}>{question?.name || question?.label}</div>
      <div className="mt-1">
        {`${value} ${NUMBER_FORMAT?.[question?.text?.answerType] || ''}`}
      </div>
    </Card>;
  }

  return (
    <Card
      className={styles.templateNumberCard}
      bodyStyle={{padding: '6px'}}
      id={'question-view-' + question?._id}
    >
      <div className="mb-3">
        {question?.name}
      </div>
      <div className="custom-form-big mb-3">
        {value ? `${value} ${NUMBER_FORMAT?.[question?.text?.answerType] || ''}` : 'N/A'}
      </div>
      <Incidents data={question?.issuesAudit} question={question} hasIssues={question?.issuesAudit?.length}/>
    </Card>
  );
};

Number.propTypes = {
  question: PropTypes.object,
  title: PropTypes.string,
  idx: PropTypes.number,
  isIncidence: PropTypes.bool,
};
Number.defaultProps = {
  question: {},
};

export default memo(Number);
