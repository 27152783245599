import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {Button, Input, Space} from 'antd';
import {FilterOutlined, PlusOutlined} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';
import {useFilter} from '@/contexts/FilterContext';
import {useResponsive} from '@/contexts/ResponsiveContext';
import {Link} from 'react-router-dom';
import RenderViewGroupAction from '@/components/RenderViewGroupActions';

//filter
import {TableFiltersProvider} from '@/components/TableFilters/contexts/TableFiltersContext';
import FilterArea from '@/components/TableFilters/components/FilterArea';
import FilterDropdown from '@/components/TableFilters/components/FilterDropdown';


const AddButton = ({onAdd, addLabel}) => {
  const {isMobile} = useResponsive();
  return (<Button type="primary" onClick={onAdd} /*icon={<PlusOutlined/>}*/>
    <div className={'hidden md:inline'}>{addLabel}</div>
    {isMobile && <div><PlusOutlined/></div>}
  </Button>
  );
};
const AddButtonConfig = ({useCreatePath, route, onAdd, addLabel}) => {
  if (useCreatePath && route)
    return <Link to={`${route}/create`}>
      <AddButton addLabel={addLabel}/>
    </Link>;
  if (onAdd)
    return <AddButton addLabel={addLabel} onAdd={onAdd}/>;
  return <></>;
};

const TableHeader = ({
  title, canSearch, route, useCreatePath,
  addLabel, onAdd, searchPlaceholder,
  extras, hasTable, canAdd,
  translation, filters,
  actionsViewByRoute,
  actions, showTitle,
  customHeader = null
}) => {
  const {setSearch} = useFilter();
  const {isMobile} = useResponsive();
  const {t} = useTranslation('table');

  return (
    <TableFiltersProvider filters={filters}>
      {customHeader
        ? customHeader
        :
        (<div
          className={'flex flex-col md:flex-row md:items-center'}
          style={showTitle ? {minHeight: '3rem', marginBottom: '1.25rem'} : {height: 0, marginBottom: 0}}>
          {showTitle && <h1 className={'mb-0 whitespace-nowrap table-title'}>{title}</h1>} {/*uppercase*/}
          <FilterArea translation={translation}/>
          <Space size={isMobile ? 'small' : 'middle'}>
            <FilterDropdown translation={translation}>
              <Button icon={<FilterOutlined/>}>{!isMobile && t('filters')}</Button>
            </FilterDropdown>
            {canSearch &&
              <Input.Search
                title={searchPlaceholder || t('searchPlaceholder')}
                className="min-w-48"
                allowClear
                placeholder={searchPlaceholder || t('searchPlaceholder')}
                onSearch={setSearch}
                enterButton/>
            }
            {
              canAdd && <AddButtonConfig onAdd={onAdd} addLabel={addLabel} route={route} useCreatePath={useCreatePath}/>
            }
            {extras}
            {hasTable && actionsViewByRoute && <RenderViewGroupAction isByRoute actions={actions}/>}
            {hasTable && !actionsViewByRoute && <RenderViewGroupAction />}
          </Space>
        </div>)
      }
    </TableFiltersProvider>
  );
};


export default memo(TableHeader);

TableHeader.propTypes = {
  addLabel: PropTypes.string,
  addText: PropTypes.string,
  canSearch: PropTypes.bool,
  extras: PropTypes.node,
  filters: PropTypes.any,
  hasTable: PropTypes.bool,
  onAdd: PropTypes.func,
  route: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  title: PropTypes.string,
  translation: PropTypes.string,
  translationPrefix: PropTypes.string,
  canAdd: PropTypes.bool,
  useCreatePath: PropTypes.bool,
  actionsViewByRoute: PropTypes.bool,
  actions: PropTypes.array,
  showTitle: PropTypes.bool,
  customHeader: PropTypes.any,
};

TableHeader.defaultProps = {
  addText: 'Añadir',
  showTitle: true
};

AddButtonConfig.propTypes = {
  addLabel: PropTypes.string,
  onAdd: PropTypes.func,
  route: PropTypes.string,
  useCreatePath: PropTypes.bool,
};

AddButton.propTypes = {
  addLabel: PropTypes.string,
  onAdd: PropTypes.func
};
