/**
 * @author Ricardo Comendador Escalona
 * @version v0.0.1
 * @date  15/11/2021
 */
import React, {memo, useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import styles from './index.less';
import { Card, Input } from 'antd';
import TemplateDetailsFooterActions from '@/modules/templates/components/TemplateDetailsFooterActions';
import {useCrudRunAudit} from '@/modules/auditorAudits/hooks/useAuditorAudits';
import {useParams} from 'react-router-dom';

const TemplateDetailNumber = ({sectionId, question, isCover, runAudit}) => {

  const {create, update} = useCrudRunAudit();
  const {id} = useParams();

  const [number, setNumber] = useState(runAudit ? question?.answer?.templateAnswer?.number || '' : '');

  const onSave = useCallback(async () => {
    if (runAudit) {
      const templateAnswer = {
        type: 'number',
        number
      };
      if (!question?.answer?._id) {
        await create({id, section: sectionId, question: question?._id, templateAnswer});
      } else await update({
        answerId: question?.answer?._id,
        id,
        section: sectionId,
        question: question?._id,
        templateAnswer
      });
    }
  }, [create, id, number, question?._id, question?.answer?._id, runAudit, sectionId, update]);

  return (
    <Card
      className={styles.templateNumberCard}
      id={'question-view-'+question?._id}
    >
      {/*<div style={{color: 'rgb(235, 0, 39)'}}>*/}
      {/*  {question?.controlParameter ? '*' : null}*/}
      {/*</div>*/}
      <div className="mb-3">
        {question?.name}
      </div>
      <div className="custom-form-big mb-3">

        <Input type="number" value={number} className="mb-3" onChange={e => setNumber(e?.target?.value)} onPressEnter={e => {
          e.preventDefault();
          e.stopPropagation();
          if (runAudit) {
            onSave().then();
          }
        }}
        onBlur={onSave}
        />

      </div>

      <TemplateDetailsFooterActions question={question} isCover={isCover} sectionId={sectionId} runAudit={runAudit} />
    </Card>
  );
};

TemplateDetailNumber.propTypes = {
  runAudit: PropTypes.bool,
  sectionId: PropTypes.string,
  question: PropTypes.object,
  isCover: PropTypes.bool,
};
TemplateDetailNumber.defaultProps = {
  question: {},
};

export default memo(TemplateDetailNumber);


/**
 * cellRender
 * */
/**
 * This is not a components,
 * it is a function to render the Detail Audit Card components in a detail drawer
 * @param {String} value this is the value of the dataIndex of the table (we will ignored in this case)
 * @param {Object} entity this is the row instance
 * */
export const renderTemplateDetailNumber = (value, entity) => {
  return (
    <TemplateDetailNumber entity={entity}/>
  );
};
