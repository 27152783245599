/* eslint-disable no-undef */
import {ApiClientService} from '@dofleini/security';
import {authentication} from '@/settings';
import {WebAuth} from 'auth0-js';

const USER_PROFILE = process.env.REACT_APP_USER_PROFILE || '__user_profile__';

export const setProfile = (profile) => {
  sessionStorage.setItem(USER_PROFILE, profile);
};

export const getProfile = () => {
  sessionStorage.getItem(USER_PROFILE);
};

class Authorization {

  constructor() {
    this.settings = {
      domain: process.env.REACT_APP_AUTH0_DOMAIN,
      clientID: process.env.REACT_APP_AUTH0_CLIENTID,
      redirectUri: `${window.location.origin}/callback`,
      serverApi: process.env.REACT_APP_BACKEND_URL
    };

    this.service = ApiClientService;

    if (this.settings) {
      this.redirectLink =
          this.settings.redirectUri || `${window.location.origin}`;

      this.auth0 = new WebAuth({
        domain: this.settings.domain,
        clientID: this.settings.clientID,
        redirectUri: this.redirectLink,
        audience: `https://${this.settings.domain}/api/v2/`,
        scope:
            'read:current_user openid email profile',
        responseType: 'token id_token'
      });

    }

  }

  signUp = (params) => {
    return this.service.post(`${authentication.path}/api/signup`, params);
  };

  requestAccess = (params) => {
    return this.service.post(`${authentication.path}/api/access-requests`, params);
  };

  processAccess = (key, accept) => {
    return this.service.post(`${authentication.path}/api/process-access`, {key, accept});
  };

  signIn = (params) => {
    return this.service.post(`${authentication.path}/api/signin-admin`, params);
  };


  checkConfirmationAccess = (key) => {
    return this.service.post(`${authentication.path}/api/confirm`, {key})
      .then(r => r.data);
  };

  invitationAccess = (key) => {
    return this.service.post(`${authentication.path}/api/invites/accept-invite`, {key})
      .then(r => r.data);
  };

  resendConfirmationAccess = (data) => {
    return this.service.post(`${authentication.path}/api/confirm/resend`, data)
      .then(r => r.data);
  };

  spaceAccess = (key) => {
    return this.service.post(`${authentication.path}/api/update-token?spaceId=${key}`, {key})
      .then(r => r.data);
  };

  resendConfirmationAccessFromStorage = (identifier) => {
    return this.service.post(`${authentication.path}/api/confirm/resend`, {identifier})
      .then(r => r.data);
  };

  checkConfirmationMessage = (key) => {
    const phone = getProfile().phone;
    return this.service.post(`${authentication.path}/api/confirm-phone`, {key, phone}).then(r => r.data);
  };

  resendPhoneAccess = () => {
    console.log('send message to:', getProfile().phone);
  };

  socialLogin = (connection, nonce = 'login') => {
    return new Promise((resolve, reject) => {
      this.auth0.popup.authorize(
        {
          nonce,
          connection
        },
        (err, authResult) => {
          if (err) reject(err);
          try {
            this.service.post(`${authentication.path}/api/social-signin`, {
              userId: authResult?.idTokenPayload?.sub,
              oauth0Token: authResult?.accessToken
            }).then(res => resolve(res)).catch((e) => reject(e));
          } catch (e) {
            reject(e);
          }
        }
      );
    });
  };
}

export default new Authorization();
