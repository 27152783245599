/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 16/1/22
 */
import React, {memo} from 'react';
import PropTypes from 'prop-types';
import styles from './index.less';
import {Tooltip, Typography} from 'antd';
const { Paragraph } = Typography;

const AuditEstablishmentCell = ({value}) => {

  return (
    <div className="flex items-center justify-start">
      <div className={'row-flex w-full'}>
        <Paragraph
          className={styles.detailTemplateParagraph}
          /* eslint-disable-next-line react/display-name */
          ellipsis={{tooltip: ()=>{
            return (
              <>
                <Tooltip placement="bottom">
                  {value?.name}
                </Tooltip>
              </>
            );
          }}}
        >
          {value?.name}
        </Paragraph>
      </div>
    </div>
  );
};

AuditEstablishmentCell.propTypes = {
  value: PropTypes.object,
};
AuditEstablishmentCell.defaultProps = {
  entity: {},
};

export default memo(AuditEstablishmentCell);


/**
 * cellRender
 * */
/**
 * This is not a components,
 * it is a function to render the AuditEstablishmentCell components in a table cell
 * @param {string} value this is the row instance
 * */
export const renderAuditEstablishment = (value) => {

  return (
    <AuditEstablishmentCell value={value}/>
  );
};


