import React, {useCallback, useMemo} from 'react';
import {Menu} from 'antd';
import PropTypes from 'prop-types';
import {useLocation} from 'react-router-dom';
import {useUser} from '@dofleini/security';
import {useTranslation} from 'react-i18next';

const {Item, SubMenu} = Menu;

export function renderMenu(
  { menu,  title,  link,  action, ...item },
  index,
  props = {},
  pathname = '',
  defaultSelectedKeys = '',
  scrollRef = null,
  isFromSidebar = false,
) {
  const key = link ? `link=>${link}` : `action=>${action || index}`;

  // eslint-disable-next-line react/prop-types
  const disabled = props?.disabled;

  if (item.divider)
    return <Menu.Divider key={`${key}=>divider=>${index}`}/>;

  if (menu)
    return (
      <SubMenu disabled={disabled}
        // key={`${index}-${title}`}
        key={link}
        title={title}
        {...item}
        onTitleClick={({domEvent}) => {
          scrollRef?.scrollTop && scrollRef.scrollTop(scrollRef.getScrollHeight() + domEvent?.target?.clientHeight * menu?.length);
          scrollRef?.handleScroll();
        }}
        className="sub-menu-sidebar"
        popupClassName={isFromSidebar ? 'sub-menu-collapsed-popover popover-left-78' : 'sub-menu-collapsed-popover'}
      >
        {menu.map((_item, _index) => renderMenu(_item, _index, props, pathname, defaultSelectedKeys))}
      </SubMenu>
    );

  return (
    <Item
      disabled={disabled || item?.disabled}
      key={key} {...item} {...props}
      className={
        item?.disabled
          ? 'sidebar-menu-item-disabled'
          : pathname === link || link === defaultSelectedKeys || (pathname?.includes(link))
            ? 'sidebar-menu-item-active'
            : ''
      }
    >
      {title}
    </Item>
  );
}

const NavMenu = ({mode, menu, theme, onClick, menuProps, subMenuProps, selectable, scrollRef, collapsed, isFromSidebar}) => {
  const { user } = useUser();
  const {pathname} = useLocation();
  // eslint-disable-next-line no-unused-vars
  const {t} = useTranslation('navigation');

  const filteredMenu = useMemo(() => {
    if (user?.reportType === 'issues') {
      const index = menu.findIndex((item) => item.isClient && item.title === t('analytics'));
      if (index !== -1) {
        menu.splice(index, 1);
      }
    } else {
      const index = menu.findIndex((item) => item.isIssue && item.title === t('statistics'));
      if (index !== -1) {
        menu.splice(index, 1);
      }
    } /*else {
      const index = menu.findIndex((item) => item.isIssue && item.title === t('statistics'));
      if (index !== -1) {
        menu.splice(index, 1);
      }
    }*/
    if (!menu.some((item) => item.isClient || item.isAuditor)) return menu;
    if (user?.isAuditor)
      return menu.filter((item) => item.isAuditor === true);
    if (user?.isAuditor === false)
      return menu.filter((item) => item.isClient === true);
    return menu;
  }, [menu, t, user?.isAuditor, user?.reportType]);

  const handleClick = useCallback(({item, key, domEvent, ...e}) => {
    if (!item.props?.elementRef?.current?.className?.includes('sidebar-menu-item-disabled')) {
      scrollRef?.scrollTop && scrollRef?.scrollTop(domEvent.screenY);
      const [eventType, value] = key.split('=>');
      e[eventType] = value;
      onClick({item, key, domEvent, ...e});
    }
  }, [onClick, scrollRef]);

  const defaultSelectedKeys = useMemo(() => {
    let selected = '';
    menu?.map(item => item?.menu?.map(subItem => pathname?.includes(subItem?.link) ? selected = subItem.link : null));
    return selected;
  }, [menu, pathname]);

  const defaultOpenKeys = useMemo(() => {
    const currentSubMenu = menu?.find(x => x.menu?.some(i => i?.link === defaultSelectedKeys))?.link;
    return [currentSubMenu];
  }, [defaultSelectedKeys, menu]);

  return (
    <Menu
      mode={mode}
      theme={theme}
      onClick={handleClick}
      selectable={selectable || false}
      {...menuProps}
      className="global-sidebar-menu"
      defaultOpenKeys={collapsed ? [] : defaultOpenKeys}
    >
      {filteredMenu.map((item, index) => renderMenu(item, index, subMenuProps, pathname, defaultSelectedKeys, scrollRef, isFromSidebar))}
    </Menu>
  );
};

export default NavMenu;

NavMenu.propTypes = {
  menu: PropTypes.array,
  mode: PropTypes.string,
  onClick: PropTypes.func,
  theme: PropTypes.string,
  subMenuProps: PropTypes.object,
  menuProps: PropTypes.object,
  selectable: PropTypes.bool,
  scrollRef: PropTypes.object,
  collapsed: PropTypes.bool,
  isFromSidebar: PropTypes.bool,
};

NavMenu.defaultProps = {
  menu: [],
  mode: 'inline',
  subMenuProps: {},
  menuProps: {},
  selectable: true
};

renderMenu.propTypes = {
  link: PropTypes.string,
  menu: PropTypes.array,
  title: PropTypes.string,
  action: PropTypes.string,
};
