import RegisterTracesModule from '@/modules/registerTraces';
import ClientRegistersModule from '@/modules/clientRegisters';
import AuditsModule from '@/modules/audits';
import TasksModule from '@/modules/tasks';
import IncidentsModule from '@/modules/incidents';
import CalendarModule from '@/modules/calendar';
import AuditorAuditsModule from '@/modules/auditorAudits';
import MainModule from '@/modules/main';
import SettingsModule from '@/modules/settings';
import EmployeesModule from '@/modules/employees';
import TemplatesModule from '@/modules/templates';
import UsersModule from '@/modules/users';
import AnalyticsModule from '@/modules/analytics';
import ContractsModule from '@/modules/contracts';
import ClientsModule from '@/modules/clients';
import AdminModule from '@/modules/admin';
import Impersonate from '@/modules/authentication/pages/Impersonate';
import RegistersModule from '@/modules/registers';

export default {
  RegisterTraces: {
    exact: false,
    path: '/register-traces',
    component: RegisterTracesModule
  },
  ClientRegisters: {
    exact: false,
    path: '/client-registers',
    component: ClientRegistersModule
  },
  Registers: {
    exact: false,
    path: '/registers',
    component: RegistersModule
  },
  Audits: {
    exact: false,
    path: '/client-audits',
    component: AuditsModule
  },
  Tasks: {
    exact: false,
    path: '/tasks',
    component: TasksModule
  },
  Incidents: {
    exact: false,
    path: '/incidents',
    component: IncidentsModule
  },
  Calendar: {
    exact: false,
    path: '/calendar',
    component: CalendarModule
  },
  AuditorAudits: {
    exact: false,
    path: '/auditor',
    component: AuditorAuditsModule
  },
  Main: {
    exact: false,
    path: '/home',
    component: MainModule
  },
  // AuditorMain: {
  //   exact: false,
  //   path: '/auditor-home',
  //   components: MainModule
  // },
  Settings: {
    exact: false,
    path: '/settings',
    component: SettingsModule
  },
  Employees: {
    exact: false,
    path: '/employees',
    component: EmployeesModule
  },
  Templates: {
    exact: false,
    path: '/templates',
    component: TemplatesModule
  },
  Users: {
    exact: false,
    path: '/users',
    component: UsersModule
  },
  Analytics: {
    exact: false,
    path: '/analytics',
    component: AnalyticsModule
  },
  Contracts: {
    exact: false,
    path: '/contracts',
    component: ContractsModule
  },
  Clients: {
    exact: false,
    path: '/clients',
    component: ClientsModule
  },
  // Home: {
  //   exact: true,
  //   path: '/',
  //   components: null
  // },
  User: {
    exact: false,
    path: '/admin',
    component: AdminModule
  },
  spaceImpersonate: {
    path: '/impersonate',
    component: Impersonate
  },
};
