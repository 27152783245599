/**
 * @author Ricardo Comendador Escalona
 * @version v0.0.1
 * @date  15/11/2021
 */
import React, {memo, useEffect, useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import styles from './index.less';
import {useTranslation} from 'react-i18next';
import { Card, Input} from 'antd';
import {useMe} from '@/modules/authentication/hooks/useUsers';
import {useCrudRunAudit} from '@/modules/auditorAudits/hooks/useAuditorAudits';
import {useAuditData} from '@/modules/auditorAudits/hooks/useAuditData';
import {useParams} from 'react-router-dom';

const TemplateDetailAuditor = ({question, runAudit}) => {
  const {t} = useTranslation('templates');

  const {data} = useMe();

  const {createMetadataValue} = useCrudRunAudit();
  const {id} = useParams();

  const {currentAudit} = useAuditData();

  const [value, setValue] = useState(data?.fullName);

  useEffect(() => {
    const coverValue = currentAudit?.coverFields?.find(x => x?._id === question?._id)?.value;
    if (coverValue) {
      setValue(coverValue);
    }
  }, [currentAudit?.coverFields, question?._id]);

  const onSave = useCallback(async () => {
    if (runAudit) {
      const coverFields = {
        _id: question?._id,
        value
      };

      await createMetadataValue({auditId: id, coverFields});
    }
  }, [createMetadataValue, id, question?._id, runAudit, value]);

  return (
    <Card
      className={styles.templateAuditorCard}
      id={'question-view-'+question?._id}
    >
      <div className="mb-3">
        {question?.name || question?.label}
      </div>

      <div className="custom-form-big">
        {runAudit
          ? <Input
            placeholder={t('details.nameAuditorPlaceholder')}
            value={value} onChange={e => setValue(e?.target?.value)} onPressEnter={e => {
              e.preventDefault();
              e.stopPropagation();
              if (runAudit) {
                onSave().then();
              }
            }}
            onBlur={onSave}
          />
          : <Input defaultValue={value || data?.fullName} />
        }
      </div>
    </Card>
  );
};

TemplateDetailAuditor.propTypes = {
  runAudit: PropTypes.bool,
  sectionId: PropTypes.string,
  question: PropTypes.object,
  isCover: PropTypes.bool,
  section: PropTypes.object,
};
TemplateDetailAuditor.defaultProps = {
  entity: {},
};

export default memo(TemplateDetailAuditor);


/**
 * cellRender
 * */
/**
 * This is not a components,
 * it is a function to render the Detail Audit Card components in a detail drawer
 * @param {String} value this is the value of the dataIndex of the table (we will ignored in this case)
 * @param {Object} entity this is the row instance
 * */
export const renderTemplateDetailAuditor = (value, entity) => {
  return (
    <TemplateDetailAuditor entity={entity}/>
  );
};
