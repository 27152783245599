/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 22/11/21
 */
import React, {memo, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import classNames from 'classnames';
import {Tag, Tooltip} from 'antd';
import {CaretRightOutlined} from '@ant-design/icons';
import styles from './index.less';
import {useResponsive} from '@/contexts/ResponsiveContext';
import {useUser} from '@dofleini/security';

const SectionTitle = ({section, status, activeKey, isCover, showCount, percent}) => {
  const {t} = useTranslation(['templates', 'audits']);
  const { user } = useUser();
  const {isMobile} =  useResponsive();

  const sectionTitle = useMemo(() => {
    if (section?.title === 'Introduction') {
      return t(section?.title);
    }
    return section?.title;
  }, [section?.title, t]);

  return (
    <div className={classNames(isCover ? styles.sectionHeader : styles.sectionPanelHeader, 'justify-between', isMobile ? 'flex-wrap' : '')}
      style={{ backgroundColor: isCover ? '#4db7f9' : '#454B50', height: !isCover && isMobile ? '150px' : '75px' }}
    >
      <div className="flex items-center">
        <CaretRightOutlined rotate={activeKey?.length ? 90 : 0}/>
        <div className={classNames(styles.title, 'ml-2')}>{sectionTitle}</div>
      </div>
      {isCover && <Tag
        className={'leading-8 rounded text-lg hidden md:flex'}
        size="large"
        style={{ color: '#454B50' }}
      >{t(`audits:status.${status}`)}</Tag>}
      {percent && user?.reportType !== 'issues' && <span className={'landing-7 ml-auto mr-4'}>{`${percent} %`}</span>}
      {showCount && user?.reportType !== 'issues' && <Tag color={showCount?.color}>{showCount?.value}</Tag>}
      {user?.reportType === 'issues' && !isCover && <div className="font-bold flex">
        <Tooltip title={t('IncidentsHighRisk', {ns: 'audits'})}>
          <Tag color={'#EF0000'} className={classNames(styles.issuesTag)}>{section?.highRiskIssuesCount || 0}</Tag>
        </Tooltip>
        <Tooltip title={t('IncidentsMedium', {ns: 'audits'})}>
          <Tag color={'#DB6307'} className={classNames(styles.issuesTag)}>{section?.mediumIssuesCount || 0}</Tag>
        </Tooltip>
        <Tooltip title={t('IncidentsLow', {ns: 'audits'})}>
          <Tag color={'#dbaa07'} className={classNames(styles.issuesTag)}>{section?.lowIssuesCount || 0}</Tag>
        </Tooltip>
      </div>}
    </div>
  );
};

SectionTitle.propTypes = {
  section: PropTypes.object,
  status: PropTypes.string,
  isCover: PropTypes.bool,
  activeKey: PropTypes.array,
  showCount: PropTypes.object,
  percent: PropTypes.number
};

export default memo(SectionTitle);
