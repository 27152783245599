import React, {memo} from 'react';
import classNames from 'classnames';
import {InfoCircleOutlined} from '@ant-design/icons';
import PropTypes from 'prop-types';
import {Tag, Tooltip} from 'antd';
import {USER_STATUS_APPEARANCE} from '@/modules/admin/contants/user.status';
import {useTranslation} from 'react-i18next';

const UserStatus = ({status, full, help, className, icon, ...props}) => {
  const {t} = useTranslation('admin');
  if (!USER_STATUS_APPEARANCE[status])
    return (
      <Tag key={status} className={classNames({'w-full': full}, className, 'w-16 text-center')}>
        -
      </Tag>);

  return (
    <Tag
      color={USER_STATUS_APPEARANCE[status].color} key={status}
      className={classNames({ 'w-full': full }, 'w-24 flex justify-center items-center py-0', className)} {...props}>
      <div className={'flex items-center'}>
        {icon}
        <span className={'flex-grow '}>{t(`user.status.${status}`)}</span>
        {help && <Tooltip title={t(`user.status.${status}_HELP`)} className="ml-2">
          <InfoCircleOutlined/>
        </Tooltip>
        }
      </div>
    </Tag>
  );
};

UserStatus.propTypes = {
  className: PropTypes.string,
  help: PropTypes.bool,
  full: PropTypes.bool,
  status: PropTypes.oneOf(['ACTIVE', 'DELETED', 'LOCK', 'UNVERIFIED']),
  icon: PropTypes.any,
};

UserStatus.defaultProps = {
  status: 'UNVERIFIED'
};

export default memo(UserStatus);

/**
 * cellRender
 * */
/**
 * This is not a components,
 * it is a function to render the Status components in a table cell
 * @param {String} status this is the value of the dataIndex of the table (we will ignored in this case)
 * @param {Object} user this is the row instance
 * */
export const renderUserStatus = (status, user) => {
  return (
    <UserStatus status={status} user={user}/>
  );
};
