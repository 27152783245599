export const CACHE_KEY_LIST_AUDITOR_AUDITS = 'auditorAudits';
export const CACHE_KEY_AUDITOR_AUDITS = 'auditorAudits-one';
export const ROUTE_PATH_AUDITOR_AUDITS = '/auditor/audits';
export const TRANSLATION_PREFIX_AUDITOR_AUDITS = 'auditorAudits';
export const AUDITOR_AUDITS_DIALOG_NAME_SPACE = 'run-audit-preview-dialog';
export const CACHE_KEY_LIST_AUDITOR_AUDITS_SECTIONS = 'audit-sections';

export const STATUS_AUDIT = {
  Draft: 'draft',
  Accepted: 'accepted',
  Running: 'running',
  Finished: 'finished',
};
