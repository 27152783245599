/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 7/1/22
 */
import React, {memo, useCallback, useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import classNames from 'classnames';
import {Button, message} from 'antd';
import {ArrowLeftOutlined, InfoCircleOutlined, LinkOutlined} from '@ant-design/icons';
import {useHistory} from 'react-router-dom';
import styles from './index.less';
import Download from '@/modules/audits/components/WrapperRunAudit/components/Download';
// import ConfigMenu from '@/modules/audits/components/WrapperRunAudit/components/ConfigMenu';
import {useAuditData} from '@/modules/auditorAudits/hooks/useAuditData';
import {FilterProvider} from '@/contexts/FilterContext';
import {CACHE_KEY_LIST_AUDITS} from '@/modules/audits/contants/audits';
import {useSpace} from '@/contexts/SpaceContext';
import {useRealTimeFromNow} from '@/hooks/useRealTimeFromNow';
import {useTemplatesStatus} from '@/modules/templates/hooks/useTemplatesStatus';
import {useUser} from '@dofleini/security';
import {i18n} from '@/settings';
import HeaderSkeleton from '@/modules/auditorAudits/components/WrapperRunAudit/components/ManageAuditHeader/HeaderSkeleton';
import {useResponsive} from '@/contexts/ResponsiveContext';

const shareAuditReport = (auditId) => {
  const el = document.createElement('input');
  el.value = `${process.env.REACT_APP_SPACE_DOMAIN}/share-audit-report/${auditId}`;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  message.success(i18n.t('templates:copyURLToShare'));
};


const ManageAuditHeader = ({isLoading: loading, isPreview}) => {
  const {t} = useTranslation('auditorAudits');
  const {currentAudit} = useAuditData();
  const {space} = useSpace();
  const {push} = useHistory();
  const { user } = useUser();

  const elapsedTime = useRealTimeFromNow(currentAudit);
  const {setIsLoading, isLoading} = useTemplatesStatus();
  const {isMobile, isTable} = useResponsive();

  useEffect(() => setIsLoading && setIsLoading(isLoading), [isLoading, setIsLoading]); // just to update global status

  const goTo = useCallback(() => {
    space?.isRootSpace ? push('/auditor/audits') : push('/client-audits');
  }, [push, space?.isRootSpace]);

  const updatedAt = useMemo(() => {
    if (!isLoading) return elapsedTime;
  }, [elapsedTime, isLoading]);

  if (loading) return <HeaderSkeleton />;

  return (
    <div className={styles.manageAuditHeader}>
      <div className={styles.title}>
        <div onClick={goTo} className={classNames('flex items-center justify-center cursor-pointer', styles.hoverGoBack)}>
          {user && <ArrowLeftOutlined style={{fontSize: '1.3rem'}} className="arrow-go-back"/>}

          <div className="flex items-start justify-center cursor-pointer mr-2 flex-col" style={{minWidth: '216px'}}>
            <div className="ml-4 template-section-title">{currentAudit?.service?.name}</div>
            <div className="flex items-center justify-start flex-wrap">
              <div className="flex items-center justify-center ml-4 text-primary">
                <div className="w-full">{currentAudit?.establishment?.name}</div>
              </div>

              <div className="flex items-center justify-start ml-2 flex-wrap">
                <span className="hidden md:flex">&gt;</span>
                <InfoCircleOutlined className="ml-2" style={{fontSize: '1.1rem', color: '#B2B2B2'}}/>
                <div className="flex items-center justify-start flex-wrap">
                  <span className="ml-2" style={{color: '#B2B2B2'}}>{t('lastUpdated')}</span>
                  <span className="ml-2">{updatedAt}</span>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>


      <div className={styles.actions}>
        <Download isMobileOrTable={isMobile || isTable} isPreview={isPreview}/>
        {!isPreview &&
            <>
              <Button
                type="primary"
                icon={<LinkOutlined/>}
                className={'rounded ml-3 ant-btn-lg button-mobile'}
                onClick={() => shareAuditReport(currentAudit?._id)}
              />
              <Button
                type="primary"
                icon={<LinkOutlined/>}
                className={'rounded ml-3 ant-btn-lg button-desktop'}
                onClick={() => shareAuditReport(currentAudit?._id)}
              >
                {t('share')}
              </Button>
            </>
        }
      </div>
    </div>
  );
};

ManageAuditHeader.propTypes = {
  currentAudit: PropTypes.string,
  isLoading: PropTypes.bool,
  isPreview: PropTypes.bool,
};

const WrapperAudit = ({...props}) => {
  return <FilterProvider id={CACHE_KEY_LIST_AUDITS}>
    <ManageAuditHeader {...props} />
  </FilterProvider>;
};

export default memo(WrapperAudit);

