/**
 * @author Angel Labrada
 * @version v0.0.1
 * @date  25/01/2022
 */
import React, {memo, useMemo} from 'react';
import PropTypes from 'prop-types';
import styles from './index.less';
import { Card} from 'antd';
import ViewEditor from '@/components/Editor/viewEditor';
import Incidents from '../../Incidents';
import {useTranslation} from 'react-i18next';

const RichText = ({question, entity}) => {
  const {t} = useTranslation('templates');
  const data = useMemo(() => entity?.coverFields?.find(f => f?._id === question?._id), [entity?.coverFields, question?._id]);

  return (
    <Card
      className={styles.templateTextCard}
      bodyStyle={{padding: '6px'}}
      id={'question-view-' + question?._id}
    >
      <div className="mb-3">
        {question?.name || t(question?.label)}
      </div>
      <div className="custom-form-big">
        {!data || data === '' ? 'N/A' : <ViewEditor value={data?.value} />}
      </div>
      <Incidents data={question?.issuesAudit} question={question} hasIssues={question?.issuesAudit?.length}/>
    </Card>
  );
};

RichText.propTypes = {
  question: PropTypes.object,
  entity: PropTypes.object,
  title: PropTypes.string,
  idx: PropTypes.number,
  isIncidence: PropTypes.bool,
};

RichText.defaultProps = {
  question: {},
};

export default memo(RichText);
