import React, {memo} from 'react';
import Logo from '@/components/Logo';
import {useResponsive} from '@/contexts/ResponsiveContext';

const Loading = () => {
  const {isMobile, isTablet} = useResponsive();
  return (<Logo mini={isMobile || isTablet} customClass="h-24 max-h-24"/>);
};

export default memo(Loading);
