import React, { Fragment, memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import UserAvatar from '@/components/UserAvatar';
import { useGetOneUser } from '@/modules/admin/hooks/useUser';

const RenderUser = ({ user, className, textClassName, showAvatar = true, textHeader, ...rest }) => {
  return (
    <div className={classNames(className, 'flex flex-row items-center')}>
      {showAvatar && <UserAvatar user={user} {...rest}/>}
      <div>
        {textHeader && <span className={classNames(textClassName, 'ml-2 opacity-75 block')}>{textHeader}</span>}
        <span className={classNames(textClassName, 'ml-2 block')}>{user?.fullName}</span>
      </div>
    </div>
  );
};

const UserFromServer = ({ id, ...rest }) => {
  const { isLoading, data } = useGetOneUser({ id });
  if (!data || isLoading) return <Fragment/>;

  return <RenderUser user={data.data} {...rest}/>;
};

const User = ({ user, ...rest }) => {
  if (!user) return <Fragment/>;
  if (typeof user === 'string') return <UserFromServer id={user} {...rest}/>;
  return <RenderUser user={user} {...rest}/>;
};

export default memo(User);

UserFromServer.propTypes = {
  id: PropTypes.string
};

RenderUser.propTypes = {
  className: PropTypes.string,
  showAvatar: PropTypes.bool,
  textClassName: PropTypes.string,
  user: PropTypes.object,
  textHeader: PropTypes.string
};

User.propTypes = {
  className: PropTypes.string,
  textClassName: PropTypes.string,
  user: PropTypes.object,
  textHeader: PropTypes.string
};

export const renderUser = (user, textHeader) => {
  return (<User user={user} textHeader={textHeader}/>);
};
