/**
 * @author Ricardo Comendador Escalona
 * @version v0.0.1
 * @date  15/11/2021
 */
import React, {memo, useState, useEffect, useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import styles from './../TemplateDetailsAuditor/index.less';
import { Card, DatePicker } from 'antd';
import moment from 'moment';
import TemplateDetailsFooterActions from '@/modules/templates/components/TemplateDetailsFooterActions';
import {useCrudRunAudit} from '@/modules/auditorAudits/hooks/useAuditorAudits';
import {useAuditData} from '@/modules/auditorAudits/hooks/useAuditData';
import {useParams} from 'react-router-dom';

const TemplateDetailAuditionDate = ({question, runAudit, isCover}) => {

  const {createMetadataValue} = useCrudRunAudit();
  const {id} = useParams();

  const {currentAudit} = useAuditData();

  const [value, setValue] = useState(moment());

  useEffect(() => {
    if (currentAudit?.coverFields?.find(x => x?._id === question?._id)?.value) {
      setValue(currentAudit?.coverFields?.find(x => x?._id === question?._id)?.value ? moment(currentAudit?.coverFields?.find(x => x?._id === question?._id)?.value) : currentAudit?.coverFields?.find(x => x?._id === question?._id)?.value);
    }
  }, [currentAudit?.coverFields, question?._id]);

  const defaultDateValue = useMemo(() => {
    if (moment().isAfter(moment(currentAudit?.date?.[1])) || moment().isBefore(moment(currentAudit?.date?.[0]))) {
      return moment(currentAudit?.date?.[1]);
    }
    if (moment().isBetween(moment(currentAudit?.date?.[0]), moment(currentAudit?.date?.[1]), 'day', '[]')) {
      return moment();
    }
    return moment();
  }, [currentAudit?.date]);

  const disabledDate = useCallback((current) => {
    return ((current && (current > (moment(currentAudit?.date?.[1])))) || (current && (current < (moment(currentAudit?.date?.[0])))));
  }, [currentAudit?.date]);

  const onSave = useCallback(async (value) => {
    if (runAudit) {
      const coverFields = {
        _id: question?._id,
        value
      };

      await createMetadataValue({auditId: id, coverFields});
    }
  }, [createMetadataValue, id, question?._id, runAudit]);

  return (
    <Card
      className={styles.templateAuditorCard}
    >
      <div className="mb-3">
        {question?.name || question?.label}
      </div>
      <div className="custom-form-big">
        {runAudit
          ? <DatePicker
            defaultPickerValue={defaultDateValue || moment()}
            value={defaultDateValue || value}
            disabledDate={disabledDate}
            onChange={val => {
              setValue(val);

              if (runAudit) {
                onSave(val).then();
              }
            }}
            format="DD-MM-YYYY"
          />
          : <DatePicker defaultValue={defaultDateValue || moment()} format="DD-MM-YYYY" disabledDate={disabledDate} />
        }
      </div>

      <TemplateDetailsFooterActions question={question} isCover={isCover} />
    </Card>
  );
};

TemplateDetailAuditionDate.propTypes = {
  question: PropTypes.object,
  isCover: PropTypes.bool,
  runAudit: PropTypes.bool,
};
TemplateDetailAuditionDate.defaultProps = {
  question: {},
};

export default memo(TemplateDetailAuditionDate);


/**
 * cellRender
 * */
/**
 * This is not a components,
 * it is a function to render the Detail Audit Card components in a detail drawer
 * @param {String} value this is the value of the dataIndex of the table (we will ignored in this case)
 * @param {Object} entity this is the row instance
 * */
export const renderTemplateDetailAuditionDate = (value, entity) => {
  return (
    <TemplateDetailAuditionDate entity={entity}/>
  );
};
