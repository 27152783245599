/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 15/2/22
 */
import React, {createContext, useContext, useState} from 'react';

const ShareAuditFromCalendarContext = createContext({
  audit: '',
  setAudit: val => val,
});

const useShareAuditFromCalendar = () => {
  const context = useContext(ShareAuditFromCalendarContext);
  if (context === undefined) {
    throw new Error('useShareAuditFromCalendar must be used within a ShareAuditFromCalendarProvider');
  }

  return context;
};

const ShareAuditFromCalendarProvider = ({ ...props }) => {

  const [audit, setAudit] = useState('');

  return (
    <ShareAuditFromCalendarContext.Provider
      value={{
        audit, setAudit
      }}
      {...props}
    />
  );
};

export { useShareAuditFromCalendar, ShareAuditFromCalendarProvider };

