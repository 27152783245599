import React, {memo} from 'react';
import PageLoader from '@/components/PageLoader';
import {useImpersonateFlow} from '../../hooks/useImpersonateHook';

const Impersonate = () => {
  useImpersonateFlow();
  return (
    <PageLoader/>
  );
};

export default memo(Impersonate);
