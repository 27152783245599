/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 23/2/22
 */
import React, {memo, useMemo} from 'react';
import { Bar } from '@ant-design/plots';
import {useAuditData} from '@/modules/auditorAudits/hooks/useAuditData';
import {useTranslation} from 'react-i18next';
import styles from './index.less';

const BarChart = () => {

  const {dataSections} = useAuditData();
  const {t} = useTranslation('audits');

  const data = useMemo(() => {
    return dataSections?.map((section) => {
      const percent = section?.finalScore * 100 / section?.totalScore;
      return {
        title: section?.title,
        type: t('currentVisit'),
        score: percent
      };
    }
    );
  }, [dataSections, t]);

  const config = {
    data,
    xField: 'score',
    yField: 'title',
    seriesField: 'type',
    tooltip: {
      formatter: (datum) => {
        return { name: t('scoring'), value: datum.score ? Number(datum.score).toFixed(1) + '%' : '0%'};
      },
    },
    label: {
      position: 'right',
      offset: 4,
      formatter: (datum) => {
        if (datum.score === 100 || datum.score > 100) return null;
        return datum.score ? Number(datum.score).toFixed(1) + '%' : '0%';
      },
    },
    color: ({ type }) => {
      return type === t('visit') ? '#C0504D' : '#4db7f9';
    },
    legend: {
      position: 'bottom',
    },
  };

  return (
    <div className={styles.wrapperChart}>
      <Bar {...config} />
    </div>
  );
};

BarChart.propTypes = {};

export default memo(BarChart);
