/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 23/2/22
 */
import React, {memo, useMemo} from 'react';
import {useAuditData} from '@/modules/auditorAudits/hooks/useAuditData';
import { Column } from '@ant-design/plots';
import { each, groupBy } from '@antv/util';
import {useTranslation} from 'react-i18next';
import styles from './index.less';

const BarChartScoringIssue = () => {

  const {currentAudit} = useAuditData();
  const {t} = useTranslation('audits');

  const data = useMemo(() => {
    return [{
      type: t('incidences'),
      value: currentAudit?.totalIssuesCount,
    },
    {
      type: t('resolved'),
      value: currentAudit?.totalIssuesResolved
    }];
  }, [currentAudit?.totalIssuesCount, currentAudit?.totalIssuesResolved, t]);

  const paletteSemanticRed = '#209A28';
  const brandColor = '#4db7f9';
  const annotations = [];
  each(groupBy(data, 'type'), (values, k) => {
    annotations.push({
      type: 'text',
      position: [k, values?.[k]],
      content: `${values?.[k]}%`,
      style: {
        textAlign: 'center',
        fontSize: 12,
        // fill: 'rgba(0,0,0,0.85)',
      },
      offsetY: -10,
    });
  });
  const config = {
    data,
    xField: 'type',
    yField: 'value',
    seriesField: '',
    padding: [20, 0],
    maxBarWidth: 5,
    color: ({ type }) => {
      if (type === t('resolved')) {
        return paletteSemanticRed;
      }

      return brandColor;
    },
    label: {
      // content: (originData) => {
      //   const val = parseFloat(originData.value);
      //
      //   if (val < 0.05) {
      //     return (val * 100).toFixed(1) + '%';
      //   }
      // },
      style: {
        fontWeight: 'bold',
      },
      offset: 10,
    },
    annotations,
  };

  return (
    <div className={styles.wrapperChart}>
      <Column {...config} />
    </div>
  );
};

BarChartScoringIssue.propTypes = {};

export default memo(BarChartScoringIssue);
