/**
 * @author Geider Arevalo
 * @since v0.0.1
 * @date 22/1/22
 */
import React, {memo, useCallback, useState} from 'react';
// import {useTranslation} from 'react-i18next';
import {Dropdown, Menu, Button} from 'antd';
import {CaretDownOutlined, DownloadOutlined} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';
import {useAuditData} from '@/modules/auditorAudits/hooks/useAuditData';
import {useDownloadPDF} from '@/modules/audits/hooks/useAudits';
import PropTypes from 'prop-types';

const Download = ({isPreview}) => {
  const {t} = useTranslation('audits');
  const [isActive, setIsActive] = useState(false);
  const {currentAudit} = useAuditData();
  const {onDownload, isLoading} = useDownloadPDF();

  const onDownloadPDF = useCallback(async () => {
    await onDownload(currentAudit, isPreview);
    setIsActive(false);
  }, [currentAudit, isPreview, onDownload]);

  const menu = (
    <Menu>
      <Menu.Item className={'min-w-48'} onClick={onDownloadPDF}>
          PDF
      </Menu.Item>
      {/*<Menu.Item onClick={onDownload}>*/}
      {/*    Word*/}
      {/*</Menu.Item>*/}
    </Menu>
  );

  return (
    <>
      <Button
        icon={<DownloadOutlined />}
        className={'rounded ml-3 ant-btn-lg button-mobile'}
        onClick={onDownloadPDF}
      />

      <Dropdown.Button
        trigger="click"
        overlay={menu}
        placement="bottomRight"
        icon={<CaretDownOutlined rotate={isActive ? 180 : 0} />}
        arrow
        visible={isActive}
        onVisibleChange={setIsActive}
        className={'rounded btn-pdf-download button-desktop'}
        buttonsRender={([leftButton, rightButton]) => [
          <div key="download-pdf" className={'inline-flex items-center text-black'} onClick={() => setIsActive(!isActive)}>
            {leftButton}
          </div>,
          React.cloneElement(rightButton, { loading: isLoading }),
        ]}
      >
        <DownloadOutlined className={'mr-2'}/>
        {t('download')}
      </Dropdown.Button>
    </>
  );
};

Download.propTypes = {
  isPreview: PropTypes.bool,
};

export default memo(Download);
